import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LoginBox from './Login/LoginBox';
import HomeView from './Home/HomeView';
import UserRouter from './../Routers/UserRouter';
import TaskRouter from './../Routers/TaskRouter';
import StoreRouter from './../Routers/StoreRouter';
import SettingsRouter from './../Routers/SettingsRouter';
import ManagerRouter from './../Routers/ManagerRouter';
import AdminRouter from './../Routers/AdminRouter';
import UserAccount from './User/UserAccount';
import TaskListContainer from '../Containers/TaskListContainer';
import NewPlan from './User/NewPlan';
import ClusterManagerRouter from '../Routers/ClusterManagerRouter';
import PlanningList from './Planning/PlanningList';
import AdminDashboard from './Dashboard/AdminDashboard';
import LinkRouter from '../Routers/LinkRouter';
import ManholeRouter from '../Routers/ManholeRouter';
import ManagerDashboard from './Dashboard/ManagerDashboard';
import PatrolTaskContainer from '../Containers/PatrolTaskContainer';
import OdfTaskContainer from "../Containers/OdfTaskContainer";
import SiteAccessContainer from "../Containers/SiteAccessContainer";
import PositionsListContainer from '../Containers/PositionsListContainer'; 
//import SplitePaneContainer from '../Containers/SplitePaneContainer';
import Map from './Map/Map';
import PathMap from './Map/PathMap';
import page from './Gridpositions/page';
import GridContainer from '../Containers/GridContainer';
import MaitenanceContainer from '../Containers/MaitenanceContainer';


//import StaticContainer from '../Containers/StaticContainer';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}
class AppRouter extends Component {

	render() {
		return(
			<div>
				<Switch>
					<Route exact path='/login' component={LoginBox} />
		      	  	<Route exact path='/' component={HomeView} />


					<Route path='/manager' component={ManagerRouter} />
					
					<Route path='/clustermanager' component={ClusterManagerRouter} />
					
		      	  	<Route path='/dashboard' component={ManagerDashboard} />

					<Route path='/settings' component={SettingsRouter} />
					<Route path='/users' component={UserRouter} />

					<Route path='/account' component={UserAccount} />
					<Route path='/plan' component={NewPlan} />
					{/* <Route path='/register' component={Register} /> */}

					<Route path='/agent/tasks' component={TaskListContainer} />

					<Route path='/task' component={TaskRouter} />

					<Route path='/patrol' component={PatrolTaskContainer} />
					<Route path='/siteaccess' component={SiteAccessContainer} />
					{/* <Route path='/odf' component={OdfTaskContainer} />
 */}
					<Route path='/sites' component={StoreRouter} />

					<Route path='/planning' component={PlanningList} />

					<Route path='/map' component={Map} />
					{/* <Route path='/static' component={StaticContainer} /> */}
					
					<Route path='/locations' component={GridContainer} />
					{/* <Route path='/locations' component={MaitenanceContainer} /> */}
					<Route path='/positions' component={PositionsListContainer} />
					{/* <Route path='/split' component={SplitePaneContainer} /> */}
					 { <Route path='/path' component={PathMap} /> }
					<Route path='/payments' component={AdminRouter} />
					<Route path='/bill' component={AdminRouter} />


					<Route path='/link' component={LinkRouter} />
					<Route path='/manhole' component={ManholeRouter} />

				</Switch>
			</div>

			
		)
	}
}


export default withRouter(connect(mapStateToProps)(AppRouter));