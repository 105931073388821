import React, { Component } from "react";
import Select from 'react-select';
import { MapContainer, MapConsumer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { Icon } from 'leaflet';
import api from "../../Utils/Api";
import { connect } from "react-redux";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";

import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { Fragment } from "react";
import Routing from "./RoutingMachine";


const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: (user) => dispatch(setAuthAction(user)),
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    //setFlash: (f) => dispatch(setFlash(f)),
	  //closeFlash: (f) => dispatch(closeFlash(f))
  };
};

const manholeIcon = new Icon({
  iconUrl : "/img/manhole.svg",
  iconSize : [15, 15]
});

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

class Map extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      allManholes: [],
      manholes: [],
		  manholesLoading: true,
      links: []
    };
    
    this.props.isLoading();
  }

  componentDidMount() {
    //this.loadLinks();
    api.get('/api/manhole/mapfetch')
    .then(response => {
        this.setState({ manholes: response.data.manholes, allManholes: response.data.manholes})
        //console.log(response.data.manholes)
    }).then(() => {this.props.doneLoading();
      console.log(this.state.manholes.map(manhole => {
        if(manhole.latitude && manhole.longitude) {
          return L.latLng(manhole.latitude , manhole.longitude)
        }
        // return [0,0]
      }),);});
    
    this.loadLinks();
  }

  /*loadManholes = (link) => {
    api.get('/api/manhole/fetch/')
    .then(response => {
        if(response.data.success)
            this.setState({ manholes: response.data.manholes.map(item => ({label: item.manhole_type+" "+item.manhole_name, value: item.manhole_id}) )})
    })
  }*/

  loadLinks = () => {
		api.get('/api/link/fetch')
		.then((response) => {
			if(response.data.success) {

				//console.log(response.data.links);
        let nlinks = []; 
        response.data.links.map(link => {
          nlinks.push({label: link.link_name, value: link.link_id})
        })
        //let that=this;
        // console.log(nlinks);
        this.setState({
          links: nlinks
        })

			} else {
				if(response.data.errcode === 101) {
					this.props.setAuth(false);
				}
			}

			//that.props.doneLoading();

		});
	}

  updateFilter(event) {
		
		const value = event.target ? event.target.value : event.value;

		console.log(value)

		this.setState({
			filter: value,
      manholes: value ? (this.state.allManholes.filter(manhole => manhole.link_id == value)) : this.state.allManholes
		}, () => {
      //this.state.manholes.filter(manhole => manhole.link_id == value)
      console.log(this.state.manholes)
		}); 
	}

  saveMap = map => {
    this.map = map;
    // this.setState({
    //   isMapInit: true
    // });
  };

  render() {
      // let manholes = this.state.manholes;
    return (

      <React.Fragment>
         {!this.props.auth ? <Redirect to="/login" /> : ""}
        <div className={"ui-rect np"}>
          <div className={"row form"}>
            <div className={"form-group col-md-3"}>
              <label>Link</label>
              <Select
                classNamePrefix={"custom-select"}
                options={[{label: "ALL", value:false}, ...this.state.links]}
                //defaultValue={{label: "ALL", value:false}}
                placeholder="Link"
                onChange={e => this.updateFilter(e)}
                styles={selectStyles}
              />
            </div>
          </div>
        </div>

        <div className={"ui-rect np"}>
          <div className={"leaflet-container"}>

            <MapContainer center={[36.22, 2.42]} zoom={9} scrollWheelZoom={false} map={this.saveMap}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
                {this.state.manholes.map(manhole => {
                    if(manhole.latitude && manhole.longitude){
                        return <Marker key={manhole.manhole_id} position={[manhole.latitude, manhole.longitude]} icon={manholeIcon}>
                            {<Popup>
                              {manhole.manhole_type + " " + manhole.manhole_id} <br />
                              {(manhole.bridge_start.length > 0) ? <div> Start of : {manhole.bridge_start.length} bridge(s)</div> : null }
                              {(manhole.bridge_end.length > 0) ? <div> End of : {manhole.bridge_end.length} bridge(s)</div> : null } 
                              
                            </Popup>}
                            {<Tooltip>
                              {manhole.manhole_type + " " + manhole.manhole_id} <br />
                              {(manhole.bridge_start.length > 0) ? <div> Start of : {manhole.bridge_start.length} bridge(s)</div> : null }
                              {(manhole.bridge_end.length > 0) ? <div> End of : {manhole.bridge_end.length}  bridge(s)</div> : null }
                            </Tooltip>}
                        </Marker>
                    }
                })}
                {/* <MapConsumer>
                  {(map) => {
                    L.Routing.control({
                      waypoints: this.state.manholes.map(manhole => {
                          if(manhole.latitude && manhole.longitude) {
                            // console.log(L.latLng(manhole.latitude , manhole.longitude));
                            return L.latLng(manhole.latitude , manhole.longitude)
                          }
                          // return [0,0]
                        }),
                      // [
                      //   L.latLng(57.74, 11.94),
                      //   L.latLng(57.6792, 11.949)
                      // ],
                    }).addTo(map);
                    return null
                  }}
                </MapConsumer> */}
                {/* {L.Routing.control({
                  waypoints: [
                    L.latLng(57.74, 11.94),
                    L.latLng(57.6792, 11.949)
                  ],
                }).addTo(this.map)} */}
                {/* <Routing map={this.map} /> */}
            </MapContainer>
          </div>
        </div>
      </React.Fragment>

      
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
