import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import AgentList from './../Components/Admin/AgentList';
import AgentNew from './../Components/Admin/AgentNew';
import AgentContainer from '../Containers/AgentContainer';
import ManagerDashboard from '../Components/Dashboard/ManagerDashboard';
import AgentMap from '../Components/Admin/AgentMap';
import FillCredit from '../Components/Plan/FillCredit';
import PayCCP from '../Components/Payment/PayCCP';
import ManagerList from '../Components/ClusterManager/ManagerList';
import ManagerNew from '../Components/ClusterManager/ManagerNew';
import ClusterManagerDashboard from '../Components/Dashboard/ClusterManagerDashboard';
import CManagerList from '../Components/Admin/CManagerList';
import CManagerNew from '../Components/Admin/CManagerNew';



function mapStateToProps(state) {
  return { user: state.user, auth: state.auth };
};


class ClusterManagerRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}

						<Route exact path='/clustermanager/list' component={CManagerList} />
						<Route exact path='/clustermanager/new' component={CManagerNew} />

						<Route exact path='/clustermanager/manager/new' component={ManagerNew} />
						<Route exact path='/clustermanager/manager/list' component={ManagerList} />
						<Route exact path='/clustermanager/dashboard' component={ManagerDashboard} />
						
						<Route exact path='/manager/agents' component={AgentList} />
						<Route exact path='/manager/agents/new' component={AgentNew} />
						<Route exact path='/manager/agents/map' component={AgentMap} />
						<Route path='/manager/agents/:userid' component={AgentContainer} />
							
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(ClusterManagerRouter)