import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";
import SocketContext from '../../Utils/socket-context';


class ManagerMenuConsumer extends Component {
	constructor(props) {
		super(props);

		

		this.state = {
			menuOpen: false
		}


		this.menuRef = React.createRef();
		this.blur = this.blur.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);

	}

	setReadNotifications() {

	}

	blur() {
		this.setState({menuOpen: false});
	}


	toggleMenu() {
		this.setState({menuOpen: !this.state.menuOpen});
		
	}


	componentDidUpdate(prev) {
		const that = this;

		if(this.props.socket && !prev.socket) {
			this.props.socket.on('test', function (data) {
				
				if(data.type && data.type === 'user_update')
					console.log('update user state')
			});
		}

	}



	render() {
		const menuOpen = this.state.menuOpen;
		
		return (
			<div>
				<span className={"msc"}>
				<ul className="header-menu">				
					<li><NavLink to="/locations">Locations </NavLink></li>
				</ul>
				</span>

				<ul className={"user-profile"} >
					<li>
					 

					<Trans i18nKey="signed_in_as" /> <span onClick={this.toggleMenu} className={"user-profile-username"}> {this.props.user.username}</span>
					<span className={"user-profile-notification"}>
					{this.props.notifications.filter(x => !x.read).length}

					<ul className={"notification-list"} onMouseEnter={this.props.setReadNotifications}>

						{this.props.notifications.length > 0 ? this.props.notifications.map(x => (

							<li key={x.id} className={x.read ? "" : "active"}>
							<a href="/">
								<span className={"notification-text"}>{x.content}</span>
								<span className={"notification-time"}>{x.datetime}</span>
							</a>
							</li>

						)) : <li className={'notification-text'}>Aucune notification</li> }

						
						

						
					</ul>
					</span>
					{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
					{/* <span onClick={this.toggleMenu} className={"user-profile-avatar"}>
					<img alt="Profile picture" src={this.props.user.picture ? "/img/"+this.props.user.picture : "/logo.jpg"} />
					</span> */}


					<ul  className={menuOpen === true ? "user-profile-menu open" : "user-profile-menu"} ref={this.menuRef} onBlur={this.blur}>
						<li><Link to="/account"><Trans i18nKey="my_account" /></Link></li>
						<li><Link to="/account/security"><Trans i18nKey="change_my_password" /></Link></li>

						<li><a href="javascript:void(0)"  onClick={e => this.props.logOut(false)} ><Trans i18nKey="logout" /></a></li>
						<li className={"sep"}></li>
						<li><Link to="/"><Trans i18nKey="help" /></Link></li>

					</ul>
					</li>
					<li><a href="#"></a></li>
				</ul>

				<div className={"clear"}></div>
			</div>
		)
	}
}

const LocalisationTopMenu = props => (
	<SocketContext.Consumer>
		{socket => <ManagerMenuConsumer {...props} socket={socket} />}
	</SocketContext.Consumer>
)

export default connect(null, null, null, { pure: false})(LocalisationTopMenu)
