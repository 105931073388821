import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
// import { closeFlash, setFlash, setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';


import Select from 'react-select';

import * as actions from './../../Redux/actions/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faMinus, faTrash, faPlus, faInfo, faHourglass, faHourglassStart, faPause, faPen, faPlay } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import SocketContext from '../../Utils/socket-context';

import { Trans } from "react-i18next";
import AgentsPerformance from './AgentsPerformance';
import Pagination from '../../Utils/Pagination';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(actions.setAuthAction(user)),
		isLoading: e => dispatch(actions.startLoading(e)),
		doneLoading: () => dispatch(actions.doneLoading()),
		setFlash: (f) => dispatch(actions.setFlash(f)),
		closeFlash: (f) => dispatch(actions.closeFlash(f))
	}
}

const moment = require("moment");


class AgentList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			filters: {
				status: '',
				name: ''
			},
			tableIsLoading: true,
			limit: 10,
			pages: 1,
			current: 1,
			showOverlay: false,
			selectedUser: 0,
			lastUpdate: 0,
			showOverlayRenew: false,
			renewDuration: 1,
			userOverlay: false,
			user: {}
		}

		this.props.isLoading();

		this.loadAgents = this.loadAgents.bind(this);
		this.loadAgents = _.debounce(this.loadAgents, 300)
		this.selectPage = this.selectPage.bind(this);
		this.userDeleteBtn = this.userDeleteBtn.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.toggleOverlayRenew = this.toggleOverlayRenew.bind(this);
		this.deleteUser = this.deleteUser.bind(this);

		this.agentInterval = null;
		this.updateFilter = this.updateFilter.bind(this);

		this.changeLimit = this.changeLimit.bind(this);

		this.submitRenewal = this.submitRenewal.bind(this);
	}

	changeDuration(e) {
		this.setState({
			renewDuration: e.value
		})
	}
	submitRenewal(e) {
		e.preventDefault();
		const that = this;

		const data = {
			userid: this.state.selectedUser,
			duration: this.state.renewDuration
		}

		if (!data.duration || parseInt(data.duration) === 0)
			return;

		api.post('/api/user/' + this.state.selectedUser + '/renew', data)
			.then(function (response) {
				if (response.data)
					console.log(response.data)
			})

	}
	changeLimit(e) {

		this.props.isLoading();


		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadAgents())



	}

	selectPage(p) {
		this.props.isLoading();
		this.setState({ current: p });
		this.loadAgents(p);
	}

	componentDidUpdate(prev) {
		const that = this;

		if (this.props.socket && !prev.socket) {
			this.props.socket.on('test', function (data) {

				if (data.type && data.type === 'user_status')
					that.loadAgents();
			});
		}



	}

	getTimestamp() {
		var now = new Date();
		return parseInt(now.getTime() / 1000)
	}

	componentDidMount() {

		// if(this.props.socket) {
		// 	this.props.socket.on('test', function (data) {
		// 		console.log('event: ', data)
		// 		if(data.type && data.type === 'user_status')
		// 			that.loadAgents(); 
		// 	});
		// }



		// const that = this;

		this.loadAgents();

		// this.agentInterval = setInterval(function() {

		// 	if(that.getTimestamp() - that.state.lastUpdate > 15) {

		// 		that.loadAgents();
		// 	}
		// }, 1500)

	}
	componentWillUnmount() {
		clearInterval(this.agentInterval)

		if (this.props.socket) {
			this.props.socket.off('test')
		}
	}

	toggleOverlay(e) {
		this.setState({ showOverlay: !this.state.showOverlay });
	}
	toggleOverlayRenew(e, user) {

		this.setState({
			showOverlayRenew: !this.state.showOverlayRenew,
			selectedUser: user ? user : this.state.selectedUser
		});
	}


	toggleUserOverlay(user, e) {

		if (this.state.userOverlay) {
			this.setState({ userOverlay: false })
		} else {
			e && e.preventDefault();
			this.setState({ selectedUser: user, userOverlay: true });
		}
	}


	loadAgents(p = this.state.current) {
		const that = this;


		this.setState({
			lastUpdate: this.getTimestamp()
		}, function () {

			const crits = "name=" + that.state.filters.name
				+ "&status=" + that.state.filters.status
				+ "&limit=" + that.state.limit

			api.get('/api/agent/fetch?page=' + p + '&' + crits)
				.then(function (response) {
					if (response.data.success) {
						console.log(response.data.users)
						that.setState(response.data);
					} else {
						//that.props.setAuth(response.data);
					}

					that.props.doneLoading();
				});
		});


	}

	userDeleteBtn(id) {

		this.setState({
			selectedUser: id
		})


		this.toggleOverlay();

	}

	deleteUser(e) {

		let that = this;
		const data = {
			agentid: this.state.selectedUser
		}

		that.toggleOverlay();

		that.props.isLoading();



		api.post('/api/agent/delete', data)
			.then(function (response) {



				if (response.data.success) {
					//that.loadAgents();
					that.props.setFlash({
						active: true,
						message: "User has been succesfully deleted.",
						classes: "success",
						style: {}
					})
				} else {
					that.props.doneLoading();
					that.props.setFlash({
						active: true,
						message: "Failed to delete user : " + response.data.error,
						classes: "warning",
						style: {}
					})
				}

				that.loadAgents();

				that.props.doneLoading();


			});

	}

	updateFilter(e, name) {

		let that = this;

		const value = e.target ? e.target.value : e.value;


		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		}, function () {
			that.props.isLoading();
			that.loadAgents(1);
		});
	}

	toggleUser = (userid) => {

		const data = {
			agentid: userid
		}

		this.props.isLoading({
			message: "Mise à jour en cours.",
			animated: true
		});

		api.post('/api/agent/status', data)
			.then(response => {
				if (response.data.success) {
					//that.loadAgents();
					this.props.setFlash({
						active: true,
						message: "User has been succesfully updated.",
						classes: "success",
						style: {}
					})
				} else {

					this.props.setFlash({
						active: true,
						message: "Failed to update user : " + response.data.error,
						classes: "warning",
						style: {}
					})
				}

				this.loadAgents();

				this.props.doneLoading();
			})


	}

	submitEditUser = (e) => {
		e.preventDefault();
		let that = this;

		const form = new FormData(e.target);

		let data = {
			userId: this.state.selectedUser.userid,
			agentid: this.state.selectedUser.agentid,
		};

		for (var [key, value] of form.entries())
			data[key] = value

		console.log(data)


		api.post('/api/agent/edit/' + this.state.selectedUser.userid, data)
			.then(response => {
				if (response.data.success) {
					this.props.setFlash({
						active: true,
						message: 'Utilisateur modifié avec succés',
						classes: "success",
						style: {}
					})
					this.loadAgents();
				}

			})
			.then(r => this.toggleUserOverlay())


	}

	render() {
		const toggleOverlay = this.toggleOverlay;
		const showOverlay = this.state.showOverlay;
		const data = this.state;
		return (

			<div>
				<div className={this.state.userOverlay ? "ui-overlay open" : "ui-overlay"}>
					<div className={"ui-overlay-content newmanhole-overlay status-overlay-text col-md-4 offset-md-4"}>
						<button data-package-id="0" onClick={e => { this.toggleUserOverlay(e); }} className={"ui-overlay-close cbtn danger"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
						<h3 className={"mb-3"}>Modifier les informations</h3>


						<form onSubmit={this.submitEditUser}>

							<div className="form-group mt-3">
								<label className={"font-exp"}>Nom</label>
								<input className={"form-control"} defaultValue={this.state.selectedUser.lastName} type={"text"} name="nom" />
							</div>

							<div className="form-group mt-3">
								<label className={"font-exp"}>Prénom</label>
								<input className={"form-control"} defaultValue={this.state.selectedUser.firstName} type={"text"} name="prenom" />
							</div>

							<div className={"form-group  mt-3"}>
								<label><Trans i18nKey="user_phonenumber" /></label>
								<input required type="text" className={"form-control"} defaultValue={this.state.selectedUser.phone} name="phone" onChange={this.handleInput} />
							</div>

							<div className="form-group mt-3">
								<label>Nouveau mot de passe</label>
								<input type="password" className={"form-control"} name="newPassword" onChange={this.handleInput} />
							</div>



							<div className="form-group mt-3">
								<input type="submit" value="Sauvegarder" className={"cbtn"} />
							</div>
						</form>

					</div>
				</div>


				{!this.props.auth ? <Redirect to="/login" /> : ""}

				<div className={showOverlay ? "ui-overlay open" : "ui-overlay"}>
					<div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
						<button onClick={e => { toggleOverlay(e); }} className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>

						Are you sure you want to delete this user?<br />
						This action is irreversible
						<div className={"delete-buttons"}>
							<button onClick={e => { toggleOverlay(e); }} className={"btn btn-info"}>No</button><button onClick={this.deleteUser} className={"btn btn-danger ml-2"}>Delete</button>
						</div>
					</div>
				</div>




				<div className={"container-header"}>
					Techniciens
					{this.props.user.role !== 'agent' && (
						<Link to={"/manager/agents/new"}>
							<button className={"cbtn float-right ml-1"}>Nouveau Technicien</button>
						</Link>
					)}

					{/* <Link to={"/manager/agents/map"}  className={"btn btn-primary float-right"}><Trans i18nKey="button_map_view" /></Link> */}
				</div>

				<div className={"clear"}></div>



				<div className={"row form ui-rect"}>



					<div className={"form-group col-md-3"}>
						<label><Trans i18nKey="label_name" /></label>
						<input onChange={e => this.updateFilter(e, 'name')} value={this.state.filters.name} name="name" type="text" className={"form-control"} />
					</div>


				</div>

				<div className={"row ui-rect my-4"}>
					<div className={"col-md-6 items-found"}>



					</div>

					<div className={"col-md-6 per-page-wrap"}>
						<div className={"per-page-content"}>
							<p>Résultat par page</p>
							<Select
								classNamePrefix={"custom-select"}
								onChange={this.changeLimit}
								className={"per-page nm"}
								options={[
									{ label: "10", value: 10 },
									{ label: "15", value: 15 },
									{ label: "30", value: 30 }]}
								value={{ label: this.state.limit, value: this.state.limit }}
							/>
						</div>
						<Pagination
							data={this.state}
							handleClick={this.selectPage}
						/>

					</div>

				</div>





				<div className={"ui-rect"}>



					<div className="col-md-12 ctable">


						<div className={"d-none d-sm-block"}>
							<div className={"row head-titles ctable-row"}>
								<div className={"col-md-2"}>
									Technicien
								</div>
								<div className={"col-md-2"}>
									<Trans i18nKey="user_username" />
								</div>



								<div className={"col-md-2"}>
									<Trans i18nKey="user_phone" />
								</div>

								<div className={"col-md-2"}>
									Manholes planned
								</div>

								<div className={"col-md-2"}>
									Manholes done
								</div>

								<div className={"col-md-2"}>

								</div>
							</div>

						</div>


						{
							this.state.users.map((user, j) => (


								<div className={"row"} key={j}>

									<div className={"col-md-12"}>

										<div className={"row ctable-row"}>
											<div className={"col-md-2"}>




												<div className={"agent-list-profile"}>


													<div className={"col-profile-ct"}>

														<span className={"agent-list-photo task-status status-id-2"}>
															{user.username.substring(0, 2)}
															<span
																title={user.status_last ? moment.unix(user.status_last - 120).fromNow().toString() : ""}
																className={"user-status user-status-" + user.status_id}>{user.status}</span>
														</span>
														<span className={"agent-list-username"}>
															<span>{user.fullname}</span>


															{user.status_id === 5 && (
																<span className={"status-id-5"}>Online</span>
															)}

															{user.status_id === 1 && user.status_last > 0 && (
																<span className={"status-id-1"}><abbr title={moment.unix(user.status_last - 120).format('YYYY-MM-DD HH:mm').toString()}>{moment.unix(user.status_last - 120).fromNow().toString()}</abbr></span>
															)}
														</span>

													</div>

													{window.innerWidth < 570 && (
														<div className={"agent-profile-action"}>
															<Link to={'/manager/agents/' + user.agentid}><span className={"btn btn-primary btn-sm"}><Trans i18nKey="label_details" /></span></Link>
															{/* <span
									data-user-id={user.userid}
									onClick={e => this.userDeleteBtn(user.userid)}
									className={"cp btn btn-danger btn-sm ml-1"}><Trans i18nKey="label_delete" /></span> */}
														</div>
													)}


												</div>

											</div>

											<div className={"col-md-2"}>
												{user.username}
											</div>


											<div className={"col-md-2"}>
												{user.phone}
											</div>


											<div className={"col-md-2"}>
												{user.planned_manholes}
											</div>

											<div className={"col-md-2"}>
												{user.manholes}
											</div>




											{window.innerWidth >= 570 && (

												<div className={"col-md-2 task-details-col sm-hide"}>

													<button onClick={e => this.toggleUserOverlay(user, e)} className={"task-action-btn"}> <FontAwesomeIcon icon={faPen} /> Modifier </button>

													<button onClick={e => this.toggleUser(user.agentid)} className="task-action-btn danger" >
														{user.status === "disabled" ? (<><FontAwesomeIcon icon={faPlay} /> Activer</>) : (<><FontAwesomeIcon icon={faPause} /> Désactiver</>)}

													</button>

													<button onClick={e => this.userDeleteBtn(user.agentid)} className="task-action-btn danger" ><FontAwesomeIcon icon={faTrash} /> Supprimer</button>
													<Link className={"task-action-btn"} to={'/manager/agents/' + user.agentid}><FontAwesomeIcon icon={faInfo} /> <Trans i18nKey="label_details" /></Link>

												</div>

											)}



										</div>

									</div>
								</div>
							))
						}

						{this.state.users.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucun utilisateur trouvé</div>)
						}




					</div>

				</div>



				{/* <AgentsPerformance
				agents={this.state.users}
			/> */}



			</div>
		)
	}
}



const AgentListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <AgentList {...props} socket={socket} />}
	</SocketContext.Consumer>
)



export default connect(mapStateToProps, mapDispatchToProps)(AgentListConsumer)