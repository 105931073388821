 import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import {AntPath, antPath} from 'leaflet-ant-path';
//import * as L from "leaflet";
//import 'leaflet/dist/leaflet.css';

//{ AntPath, antPath }

const PathMap = ({data, ...props}) => {
//function PathMap() {
 
// Similar to componentDidMount and componentDidUpdate:
var arrs=data;
console.log("arrs",arrs);
/* var arrs = [
  [36.227000, 2.4252968],
  [36.22880, 2.425297],
  [36.22955,2.423081],
  [36.23004, 2.421313],
  [36.23039, 2.421160],
  [36.23712, 2.417406],
  [36.23737, 2.417216],
  [36.237000, 2.4160868]  ]; */
useEffect(() => {
    
    let current_lat = 36.227000;
    let current_long = 2.4252968;
    let current_zoom = 16;
    let center_lat = current_lat;
    let center_long = current_long;
    let center_zoom = current_zoom;

    // The <div id="map"> must be added to the dom before calling L.map('map')
     var map = L.map('map', {
      center: [center_lat, center_long],
        zoom: center_zoom
        });
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                       attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                     }).addTo(map);
       
                
        var antPath = L.polyline.antPath;
        var path = antPath(arrs, {
        "paused": false,   
        "reverse": false,
        "delay": 3000,
        "dashArray": [10, 20],
        "weight": 5,
        "opacity": 0.5,
        "color": "#0000FF",
        "pulseColor": "#FFFFFF"
        });
        //path.addTo(map);
        map.addLayer(path);
        map.fitBounds(path.getBounds());
        L.marker(arrs[0]).addTo(map);
        L.marker(arrs[arrs.length-1]).addTo(map);

},[]); 

return (              
      <div id="map">
      </div>

);}

export default PathMap; 
