import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Select from 'react-select';

import * as actions from '../../Redux/actions/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import SocketContext from '../../Utils/socket-context';

import {  Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(actions.setAuthAction(user)),
		isLoading: e => dispatch(actions.startLoading(e)),
		doneLoading: () => dispatch(actions.doneLoading()),
		setFlash: (f) => dispatch(actions.setFlash(f)),
    	closeFlash: (f) => dispatch(actions.closeFlash(f))
	}
}

const moment = require("moment");


class CManagerList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			filters: {
				status: '',
				name: ''
			},
			tableIsLoading: true,
			limit: 10,
			pages: 1,
			current: 1,
			showOverlay: false,
			selectedUser: 0,
			lastUpdate: 0,
			showOverlayRenew: false,
			renewDuration: 1

		}

		this.props.isLoading();

		this.loadAgents = this.loadAgents.bind(this);
		this.loadAgents =_.debounce(this.loadAgents, 300)
		this.selectPage = this.selectPage.bind(this);
		this.userDeleteBtn = this.userDeleteBtn.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.toggleOverlayRenew = this.toggleOverlayRenew.bind(this);
		this.deleteUser = this.deleteUser.bind(this);

		this.agentInterval = null;
		this.updateFilter = this.updateFilter.bind(this);
		
		this.changeLimit = this.changeLimit.bind(this);

		this.submitRenewal = this.submitRenewal.bind(this);
	}

	changeDuration(e) {
		this.setState({
			renewDuration: e.value
		})
	}
	submitRenewal(e) {
		e.preventDefault();
		const that = this;

		const data = {
			userid: this.state.selectedUser,
			duration: this.state.renewDuration
		}
		
		if(!data.duration || parseInt(data.duration) === 0)
			return;

		api.post('/api/user/'+this.state.selectedUser+'/renew', data)
		.then(function(response) {
			if(response.data)
				console.log(response.data)
		})
		
	}
	changeLimit(e) {

		this.props.isLoading();

		
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadAgents())

		

	}

	selectPage(p) {
		this.props.isLoading();
		this.setState({current: p});
		this.loadAgents(p);
	}

	getTimestamp() {
		var now = new Date();
		return parseInt(now.getTime()/1000)
	}

	componentDidMount() {

		const that = this;

		this.loadAgents();

		this.agentInterval = setInterval(function() {
			
			if(that.getTimestamp() - that.state.lastUpdate > 15) {
				
				that.loadAgents();
			}
		}, 1500)

	}
	componentWillUnmount() {
		clearInterval(this.agentInterval)

		if(this.props.socket) {
			this.props.socket.off('test')
		}
	}

	toggleOverlay(e) {
		this.setState({showOverlay: !this.state.showOverlay});
	}
	toggleOverlayRenew(e, user) {

		this.setState({
			showOverlayRenew: !this.state.showOverlayRenew,
			selectedUser: user ? user : this.state.selectedUser
		});
	}


	loadAgents(p = this.state.current) {
		const that = this;
		

		this.setState({
			lastUpdate: this.getTimestamp()
		}, function() {

			const crits = "name="+that.state.filters.name
						  +"&status="+that.state.filters.status
						  +"&limit="+that.state.limit

			api.get('/api/clustermanager/fetch?'+crits)
			.then(function(response) {
				if(response.data.success) {
					
					that.setState(response.data);
				} else {
					//that.props.setAuth(response.data);
				}

				that.props.doneLoading();
			});
		});

		
	}
 
	userDeleteBtn(id) {
		
		this.setState({
			selectedUser: id
		})
		

		this.toggleOverlay();

	}

	deleteUser(e) {

		let that = this;
		const data = { 
			user: this.props.user
		}

		that.toggleOverlay();

		that.props.isLoading();

		
		api.post('/api/user/delete/'+this.state.selectedUser, data)
		.then(function(response) {
			
			

			if(response.data.success) {
				//that.loadAgents();
				that.props.setFlash({
					active: true,
					message: "User has been succesfully deleted.",
					classes: "success",
					style: {}
				})
			} else {
				that.props.doneLoading();
				that.props.setFlash({
					active: true,
					message: "Failed to delete user : "+response.data.error,
					classes: "warning",
					style: {}
				})
			}
		});
		
	}

	updateFilter(e, name) {

		let that = this;

		const value = e.target ? e.target.value : e.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			that.props.isLoading();
			that.loadAgents(1);
		});
	}

	render() {
		const toggleOverlay = this.toggleOverlay;
		const toggleOverlayRenew = this.toggleOverlayRenew;
		const showOverlay = this.state.showOverlay;
		const showOverlayRenew = this.state.showOverlayRenew
		const data = this.state;
		return(
			<div>


			{!this.props.auth ? <Redirect to="/login" /> :  ""}

			<div className={showOverlay ? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
					<button onClick={e => { toggleOverlay(e); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					Are you sure you want to delete this user?<br />
					This action is irreversible
					<div className={"delete-buttons"}>
					<button  onClick={e => { toggleOverlay(e); } } className={"btn btn-info"}>No</button><button onClick={this.deleteUser} className={"btn btn-danger ml-2"}>Delete</button>
					</div>
				</div>
			</div>




			<div className={"container-header"}>
				Cluster Managers

				<Link to={"/clustermanager/new"}  className={"btn btn-primary float-right ml-1"}>Ajouter un nouveau cluster manager</Link>
				{/* <Link to={"/manager/agents/map"}  className={"btn btn-primary float-right"}><Trans i18nKey="button_map_view" /></Link> */}
			</div>
				
			<div className={"clear"}></div>



			<div className={"row form"}>
				
				

				

				<div className={"form-group col-md-3"}>
					<label><Trans i18nKey="label_name" /></label>
					<input onChange={e => this.updateFilter(e, 'name')} value={this.state.filters.name} name="name" type="text" className={"form-control"} />
				</div>
				

			</div>

			<div className={"row"}>


			
			<div className={"per-page-wrap"}>
			Résultat par page 
				<Select
				onChange={this.changeLimit}
				className={"per-page"}
					options={[
						{label: "10", value: 10},
						{label: "15", value: 15},
						{label: "30", value: 30}]}
					value={{label: this.state.limit, value: this.state.limit}}
				/>
			</div>

			<div className={"col-md-12"}>


				<ul className={"pagination"}>
					<li className={"label"}>Pages</li>
					{[...Array(data.pages)].map((v, i) =>
						(<li key={i} onClick={e => this.selectPage(i+1)} className={parseInt(data.current) === i+1 ? 'active' : ""}><a href="javascript:void(0)">{i+1}</a></li>))}

				</ul>

				<div className={"clear mb-6"}></div>
			</div>


			</div>




			<div className="col-md-12 ctable">
			
			
				<div className={"d-none d-sm-block"}>
					<div className={"row head-titles ctable-row"}>
						<div className={"col-md-2"}>
							<Trans i18nKey="label_agent" />
						</div>
						<div className={"col-md-2"}>
							<Trans i18nKey="user_username" />
						</div>
						<div className={"col-md-3"}>
							<Trans i18nKey="user_email" />
						</div>
						<div className={"col-md-2"}>
							<Trans i18nKey="label_creation_date" />
						</div>
						<div className={"col-md-1"}>
							<Trans i18nKey="user_phone" />
						</div>

						<div className={"col-md-2"}>
							
						</div>
					</div>

				</div>


				{
					this.state.users.map((user, j) => (


				<div className={"row"} key={j}>

					<div className={"col-md-12"}>

					<div className={"row ctable-row"}>
						<div className={"col-md-2"}>




							<div className={"agent-list-profile"}>

								
								<div className={"col-profile-ct"}>

								<span className={"agent-list-photo task-status status-id-3"}>
								CM
								<span 
								className={"user-status user-status-"+user.status_id}>{user.status}</span>
								</span>
								<span className={"agent-list-username"}>
								<span>{user.fullname}</span>
								
									 <span className={"status-id-1"}><abbr> {user.title} </abbr></span>
							
								</span>

								</div>

								{window.innerWidth < 570 && (
									<div className={"agent-profile-action"}>
									<Link to={'/manager/agents/'+user.userid}><span className={"btn btn-primary btn-sm"}><Trans i18nKey="label_details" /></span></Link>
									<span
									data-user-id={user.userid}
									onClick={e => this.userDeleteBtn(user.userid)}
									className={"cp btn btn-danger btn-sm ml-1"}><Trans i18nKey="label_delete" /></span>
									</div>
								)}
								

							</div>

						</div>
						
						<div className={"col-md-2"}>
							{user.username}
						</div>

						<div className={"col-md-3"}>
							{user.email}
						</div>
						<div className={"col-md-2"}>
						
							{ user.created_at ? moment.unix(user.created_at).format('YYYY-MM-DD HH:mm').toString() : ""}
						

						</div>

						<div className={"col-md-1"}>
							{user.phone}
						</div>

						

							
						{/* {window.innerWidth >= 570 && (
							<div className={"col-md-2 sm-hide"}>
								<Link to={'/manager/agents/'+user.userid}><span className={"btn btn-primary btn-sm"}><Trans i18nKey="label_details" /></span></Link>
								<span
								data-user-id={user.userid}
								onClick={e => this.userDeleteBtn(user.userid)}
								className={"cp btn btn-danger btn-sm ml-1"}><Trans i18nKey="label_delete" /></span>
							</div>
                        )} */}
                        
                        <div className={"col-md-2"}>
							
						</div>

							
						
						
					</div>

					</div>
				</div>
				))
				}

				{this.state.users.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucun Cluster manager trouvé</div>)
				}




			</div>


			
			</div>
		)
	}
}



const CManagerListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <CManagerList {...props} socket={socket} />}
	</SocketContext.Consumer>
  )
	

export default connect(mapStateToProps, mapDispatchToProps)(CManagerListConsumer)