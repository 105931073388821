import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setAuthAction, doneLoading, startLoading, setOverlay } from './../Redux/actions/index';
import AddressNew from '../Components/Addresses/AddressNew';
import api from '../Utils/Api';



const mapStateToProps = state => {
	return {
		user: state.user,
		loading: state.loading
	}
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    setOverlay: (x) => dispatch(setOverlay(x)),
    isLoading: (e) => dispatch(startLoading(e))
  };
}


const axios = require('axios');

class AddressNewContainer extends Component {

	constructor(props) {
		super(props);

		//	this.props.isLoading({message: "Loading", animate:true});

		
		this.state = {
			agents: [],
			types: [],
			
		}

		
		this.handlers = {
			submitTask: this.submitTask.bind(this),
			pinToggle: this.pinToggle.bind(this)
		}
		this.refs = {
			taskForm: React.createRef()
		}
		
		

	}

	pinToggle() {
		
	}

	submitTask(e, ref) {
		e.preventDefault();
		let that = this;

		this.props.isLoading({
			message:"Adding site",
			animate:true
		});

		const formdata = new FormData(ref);

		
		let data = {};

		for(var [key, value] of formdata.entries()) 
			data[key] = value
		
		data['user'] = this.props.user;


		api.post('/api/sites/new', (data))
		.then(function(response) {

			if(response.data.success) {

				//window.ga('send', 'event', 'task', 'add'); // Google Analytics

				that.props.history.push('/sites');

				that.props.setOverlay({
					message: 'Site successfully added !',
					label: "success",
					button: {
						type: "close",
						label: "Dismiss",
						link: "javascript:void(0)"
					}
				});
			} else {
				if(response.data.errcode === 101) {
					that.props.setAuth(response.data)
				}
			}

			that.props.doneLoading();


			
		}).catch(err => that.props.doneLoading());
		
	}

	addItem(e) {


	}

	addParam(e) {

	}

	updateInput(e, path, pair) {
		
	
	}



	formSubmit(e) {

		e.preventDefault();


		let that = this;


		that.props.isLoading({
			message:"Saving new site", 
			animate:true
		});

		

		let sendData = {
			user: that.props.user
		};
		

		api.post('/api/sites/new', sendData)
		.then(function(response) {

			that.props.doneLoading();
			
			//console.log(response);
			if(response.data.success) {
				that.props.history.push('/sites');
				that.props.doneLoading();
				
			} else {
				if(response.data.errcode === 101) {
					that.props.setAuth(response.data)
				}
			}

			

		}).catch(function(err) {

			that.props.doneLoading();

		})
		

	}

	locationChange(e, i) {

	}
	
	componentDidMount() {
		
		

		let that = this;

		api.get('/api/task/form?token='+this.props.user.token)
		.then(function(response) {
			that.setState({agents: response.data.agents, types: response.data.types, status: response.data.status})
		});



		
		
	}


	render() {
		

		return(

			<AddressNew
				data={this.state}
				handlers={this.handlers}
				refs={this.refs}
			/>
		)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(AddressNewContainer)