import React from "react"
import {connect} from "react-redux"
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import { Link } from 'react-router-dom';

import api from '../../Utils/Api';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}


const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}


class AgentOverview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			status: null,
			fullname: null,
			tasks: null,
			lastonline: null,
			stores: null,
			visits: null,
			account_username: "",
			account_firstname: "",
			account_lastname: "",
			account_email: "",
			account_phone: "",
			last_task: {},
			total_tasks_month: 0,
			data: {}
		}


		props.isLoading();

	}

	componentDidMount() {

		const that = this;

		api.get('/api/agent/overview/'+this.props.match.params.userid)
		.then(function(response) {
			if(response.data.success) 
				that.setState({...response.data})

			
		}).then(r => that.props.doneLoading()); 
	}

    render() {
        return (
		<>
		<div className={"row"}>
            <div className={"col-md-5"}>
				{this.props.user.userid === this.state.account_id && (
					<a href={"/account"}>Modifier mes informations</a>
				)}
			
				
  				<h4>{this.state.account_username} <span className={"account-firstname"}>{this.state.account_firstname + " "+this.state.account_lastname.toUpperCase()}</span> </h4>
				<h5><span className={"account-firstname"}>{this.state.account_email}</span></h5>
				<h5><span className={"account-firstname"}>{this.state.account_phone}</span></h5>

  				
  			</div>
			  
			
		</div>

		<div className={"row mt-4"}>
			<div className={"col-md-12"}>
				<h5>Manhole Checks</h5>

				<div className={"row mt-1"}>
					<div className={"col-md-2"}>Total Checks effectuées</div>
					<div className={"col-md-6"}>{this.state.total_tasks} <a className={"ml-2 btn-sm btn-primary"} href={"/task/agent/"+this.props.match.params.userid}>Voir tout</a> </div>
				</div>

				<div className={"row mt-1"}>
					<div className={"col-md-2"}>Checks effectuées ce mois</div>
					<div className={"col-md-6"}>{this.state.total_tasks_month} </div>
				</div>
				
				<div className={"row mt-1"}>
					<div className={"col-md-2"}>Dernier Check effectuées</div>
					<div className={"col-md-6"}><a href={"/task/"+this.state.last_task.id}>{this.state.last_task.created_at}</a></div>
				</div>

				<div className={"row mt-1"}>
					<div className={"col-md-2"}></div>
					<div className={"col-md-6"}><a href={"/task/"+this.state.last_task.link_name}>{this.state.last_task.link_name}</a></div>
				</div>

				
			</div>	
        </div>
		</>
		)

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentOverview)