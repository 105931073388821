import React, { Component, useState } from 'react';
import _ from 'lodash';
import { Link, Redirect, NavLink, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import TaskList from '../Components/Task/TaskList'
import {setAuthAction, startLoading, doneLoading} from '../Redux/actions/index';
import { Trans } from "react-i18next";
import Select from 'react-select';
import SocketContext from '../Utils/socket-context';
import api from '../Utils/Api';
import Pagination from "../Utils/Pagination";
import { faCheckSquare, faArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PositionsList from '../Components/Task/PositionsList';
import NewPatrolTask from '../Components/Task/components/NewPatrolTask';
import NewPatrolVehicule from '../Components/Task/components/NewPatrolVehicule';
import PropTypes from "prop-types";
import {MapContainer, MapConsumer, TileLayer, Marker, Popup, Tooltip , Polyline,useMapEvents, Circle,
  CircleMarker} from "react-leaflet";
import { Icon, Map } from 'leaflet';

import PositionsTable from '../Components/Task/PositionsTable';
import PositionsHistorique from '../Components/Task/PositionsHistorique';
import Pane from '../Components/Task/Pane';
import PathMap from '../Components/Map/PathMap';
import L from "leaflet";
import "leaflet-polylinedecorator";
import ReactLeafletKml from 'react-leaflet-kml';
import LeafletReactTrackPlayer from "leaflet-react-track-player";
import demo from "./demo";
//import 'leaflet-arrowheads';
//import { PolylineDecorator } from '../Utils/PolylineDecorator';
//import PolylineDecorator from '../Utils/PolylineDecorator';

import { OBJtoXML } from '../Utils/Helpers';

// import  MarkerClusterGroup  from 'leaflet'; 
import MarkerClusterGroup from 'react-leaflet-markercluster';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user, 
		taskList: state.taskList
    
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e))
	}
}

// const positionIcon = new Icon({
//     iconUrl : "/img/green_pin.jpg",
//     iconSize : [35, 35]
//   }); 
const carIcon1 = new Icon({
    iconUrl : "/img/google-maps-car.jpg",
    iconSize : [40, 40]
  }); 
const carIcon = new Icon({
  iconUrl : "/img/trck-car.png",
  iconSize : [40, 40]
}); 

const arrow = [
  {
    offset: "100%",
    repeat: 0,
    symbol: L.Symbol.arrowHead({
      pixelSize: 15,
      polygon: false,
      pathOptions: { stroke: true }
    })
  }
];

var arrs = [['36.227000', '2.4252968']]

// var arrs = [
//     [36.227000, 2.4252968],
//     [36.22880, 2.425297],
//     [36.22955,2.423081],
//     [36.23004, 2.421313],
//     [36.23039, 2.421160],
//     [36.23712, 2.417406],
//     [36.23737, 2.417216],
//     [36.237000, 2.4160868]  ];
//Change location marker
/* function LocationMarker() {
    const [position, setPosition] = useState(null);
    let arrs = [
        [36.227000, 2.4252968],
        [36.22880, 2.425297],
        [36.22955,2.423081],
        [36.23004, 2.421313],
        [36.23039, 2.421160],
        [36.23712, 2.417406],
        [36.23737, 2.417216],
        [36.237000, 2.4160868]  ];

    var antPath = L.polyline.antPath;
        var path = antPath(arrs, {
        "paused": false,   
        "reverse": false,
        "delay": 3000,
        "dashArray": [10, 20],
        "weight": 5,
        "opacity": 0.5,
        "color": "#0000FF",
        "pulseColor": "#FFFFFF"
        });    
    const map = useMapEvents({
        click() {
            map.locate()
        },
        locationfound(e) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
        },
    })

    return position === null ? null : (
        <Marker position={position} removable editable>
            <Popup>You are here</Popup>
        </Marker>
    )
} */

class GridContainer extends Component {
	
	constructor(props) {
		super(props);
		
		this.props.isLoading({
			message:"Loading ",
			animate:true
		});

		this.state = {
			openPatrolView: false,
			planning_ready: false,
			taskid: null,
			managers: [],
			tasks: [],
      total: 0,
      assets: [],
			agents: [],
			status: [],
			types: [],
			rows: [],
			current: 1,
			pages: 1,
			limit: 10,
      data: null,
			wilayas: [],
			linkTaskData: null,
			manholeListLoading: true,
			activeRow: null,
			selectMode: false,
			selectedTasks: [],
      precise : false,
      lattitude : 36.22,
      longtitude : 2.42,
      OneAsset: [],
      agreement: false ,
      check:null,
      date:null,
      pressed : false,
      zoom :9,
      vehicleSelected: null, 
      path:  [],
      arrow: [], 
      kml: null, 
      type: "distance",
      demo: demo
		}

		this.handlers = {
			selectPage: this.selectPage.bind(this),
			loadTasks: this.loadTasks.bind(this),
			updateFilter: this.updateFilter.bind(this),
			selectTask: this.selectTask,
			unselectTask: this.unselectTask,
			selectAll: this.selectAll,
      handler: this.handler.bind(this),
      handleMap :  this.handleMap.bind(this),
		}
		
		this.loadTasks = this.loadTasks.bind(this);
		this.toggleHidden = this.toggleHidden.bind(this);
		//this.updateFilter = _.debounce(this.updateFilter, 200);
        this.handleMap = this.handleMap.bind(this);
		this.changeLimit = this.changeLimit.bind(this);
        //this.changeCenterHandler = this.changeCenterHandler.bind(this);

    this.loadTasks(1)
    
       
	}
   /*  PaneSet(k , a , r) {
        this.setState({
            key:k,
            asset :a,
            pressed : r
        },()=> console.log("asset",this.state.asset)) }*/
   
     changeCenterHandler = (d) => {
        this.setState({
            lattitude:d.at(0),
            longtitude:d.at(1),   
        })
         
         
        //setZoom(18);
      
     }

 selectAsset = (show = true , device_id) => {
    api.get('/api/position/fetch?asset='+device_id).then(res => {
                if(res.data.success) {
                  
                    this.setState({
                        lattitude:res.data.positions[0].at(0),
                        longtitude:res.data.positions[0].at(1),
                        data: res.data.positions,
                        check: res.data.positions.length,
                        date: res.data.positions[0].at(2)
                       
                    }, () =>console.log("historique",this.state.data))
                }
            })   
  } 

  handler(asset) {
      // console.log('hello here')
      this.setState({
        precise : true,
        lattitude: asset.iot_position.lat,
        longtitude: asset.iot_position.lng,
        OneAsset :asset,
        zoom: 13
        // } ,() => this.state.handler(this.state.data))
      } ) 
      
  }
    


     handleMap(d) {
         console.log("handle_d");
        this.setState({
        
          lattitude: d.at(0),
          longtitude: d.at(1)
         
        // } ,() => this.state.handler(this.state.data))
      } ) }
     
    
    handleChange = e => { this.setState({ agreement: e.target.checked,
                                            precise : false,
                                            lattitude: 36.22, 
                                            longtitude: 2.42,
                                            zoom :9
                                                                       });
      console.log(`checked: ${this.state.agreement}`);
      };
    

	changeLimit(e) {
   
		this.props.isLoading();
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadTasks())


	}



	updateFilter = (event, name) => {

		let that = this;
		const value = event.target ? event.target.value : event.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, () => {
			console.log(name, event)
			that.props.isLoading();
			this.loadTasks(1);
            
		}); 
			}


    //// Load cars api :
    loadTasks = (p = this.state.current) => {
        let nstate = this.state;
        let that = this;
    
        //let crits = buildFilters(this.state.filters);
        
        console.log('there')
        api.get(`/api/iot/cars/fetch?page=${p}&limit=${this.state.limit}`).then(function(response) {
    
          if (response.data.success) {
    
            response.data.assets.map(() => {
              nstate.rows.push({ hidden: true });
            });
    
            that.setState({
              assets: response.data.assets,
              pages: response.data.pages,
              rows: nstate.rows,
              total: response.data.total
            }); 
            that.props.doneLoading();
            console.log('there')
           // that.addMark()
          } else {
            if (response.data.errcode === 101) {
              that.props.setAuth(false);
            }
          }
    
          that.props.doneLoading();
        });
       // that.props.doneLoading();
        

      }
	


	handleInput(e) {
	
	}

	toggleHidden(e, task) {
		
		e.persist();

		console.log(task);

		// return;


		const i = e.target.getAttribute('data-row-index');
		console.log(this.state.activeRow)

		this.setState({ 
			linkTaskData : {}, 
			manholeListLoading: true,
			manholeCheck: null,
			activeRow: this.state.activeRow === i ? null : i
		});

		
	}

	componentDidMount() {


		let that = this;

	}

	


	
	importClick = () => {
		//e.preventDefault();

		this.toggleOverlay();

		if(this.state.planning_ready) {
			this.fileFormRef.submit();
		} else {
			document.querySelector("#linksfile").click();
		}


	}

	handelFileChange = (e) => {
		if(e.target.value) {
			this.setState({ planning_ready: true })
		} else {
			this.setState({ planning_ready: false })
		}
		console.log(e.target)
	}

	resetFileForm = () => {
		this.setState({ planning_ready: false });
		this.fileFormRef.reset();
	}


	

	selectPage = (p) => {
    
		this.props.isLoading();

		this.setState({current: p});

		this.loadTasks(p);
	}

	exportPlanningTemplate = () => {
		alert('test')
	}


	toggleSelectMode = () => {
		this.setState({ selectMode: !this.state.selectMode })
	}

	selectTask = (taskid) => {
		const {selectedTasks} = this.state;

		selectedTasks.push(taskid);

		this.setState({ selectedTasks });
	}

	unselectTask = (taskid) => {
		let { selectedTasks } = this.state;

		const idx = selectedTasks.indexOf(taskid);

		selectedTasks.splice(idx, 1);
		this.setState({ selectedTasks });
	}

	selectAll = () => {
		const {selectedTasks} = this.state;

		// filter existing tasks
		selectedTasks.push(...this.state.tasks.map(task => selectedTasks.indexOf(task.taskid) > -1 ? false : task.taskid).filter(Boolean));
		
		this.setState({ selectedTasks });
	}


	selectVehicle = (vehicle, kmlText=null) => {
    if(vehicle.length > 0){
      const parser = new DOMParser();
      console.log("file "+ kmlText)
      let kml = parser.parseFromString(kmlText, "text/xml");
      this.setState({
        kml:null
      })

      console.log('see kml parsed => '+ kml)

      this.setState({
        //path : newAr,
        vehicleSelected: vehicle,
        kml: kml
      })
     
    }else{
      console.log('there 2')
      this.setState({
        path : [],
        vehicleSelected: null,
        kml: null
      })
    }
    
  }
  // addMark = () => {
  //   var map = L.map('mapID')
  //   var marker = L.marker([36.1225202, 2.4774173] , {
  //     icon: positionIcon
  //   })
  //   marker.addTo(map);
  // }

	render() {
		


		return(
			
			<React.Fragment>
			
			{!this.props.auth ? <Redirect to="/login" /> :  ""}

			<div className={"container-header"}>
				Patrolling cars positions
			</div>
        <div className={"row"}>
       
            <br></br>
            <div className={"col-md-4"}>
                    <div className={"row"}>
                    <div className={"ui-rect ns"}  style={{width: "98%" ,  overflow:"overlay"}}>
                      {/*  <div className={"ui-rect ns"}  style={{width: "96%" , overflow:"overlay"}}> */}
                            <div style={{height: "300px"}}>
                        {/*    Pane1  right  */}
                            <PositionsTable
                              data={this.state}
                              rows={this.state.rows}
                              toggleHidden={this.toggleHidden}
                              handlers={this.handlers}
                              agents={this.state.agents}    
                            />
                            </div>
                        </div>
                    </div>
                    <br></br>

                    <div className={"row"}>
                        <div className={"ui-rect ns"}  style={{width: "98%"}}>
                        <div style={{height: "300px"}}>
                        {/* Pane2  right  */}
                    {this.state.precise && this.state.OneAsset &&
                      <div>
                        <div className={'row'}> 
                          <div className={'col-md-6'}>
                            Véhicule : <b>{this.state.OneAsset.site ? this.state.OneAsset.site : "-" } </b>
                          </div>
                          <div className={'col-md-6'}>
                            Type : <b>{this.state.OneAsset.asset_type ? this.state.OneAsset.asset_type : "-"} </b>
                          </div>
                        </div>
                        <div className={'row'}> 
                          <div className={'col-md-4'}>
                            Chaffeur actuel : - </div> 
                        </div>
                          <Pane 
                            key={this.state.OneAsset.key}
                            asset={this.state.OneAsset}
                            handlers={this.handlers}
                            deleteTask={this.deleteTask}
                            returnTask={this.returnTask}
                            //reloadCars={this.getOptions}
                            reload={this.handlers.loadTasks}
                            refresh={this.handlers.handler}
                            handleMap={this.handlers.handleMap}
                            user ={this.props.user}
                            selectVehicule = {this.selectVehicle}
                          /> 
                      </div>
                } {!this.state.precise && <div className={"col-md-12 text-center my-3"}>Aucun vehicule selectionné</div>}
                      
                            </div>
                        </div>
                    </div>
            </div>

            <div className={"col-md-8"}>
                <div className={"ui-rect nt"} style={{width: "100%"}}>
                    <div style={{}}>
                      <label className={"float-left"}>
                          <input
                            type="checkbox"
                            checked={this.state.agreement} 
                            onChange={this.handleChange}
                          />
                          &nbsp; ALL 
                      </label>
          
                      <div className={"leaflet-container"} id='mapID'>
                
                        <MapContainer key={JSON.stringify(this.state.lattitude, this.state.longtitude)}
                              center={[this.state.lattitude, this.state.longtitude]} zoom={this.state.zoom} scrollWheelZoom={true}  >
                             {/* there  */}
                                {/* {!this.state.precise && this.state.assets.map(asset => {
                                          
                                            if(asset.iot_position.lat && asset.iot_position.lng){
                                                return <Marker key={asset.site_id} position={[ asset.iot_position.lat, asset.iot_position.lng]} icon={carIcon} >
                                                    {<Popup>
                                                      {asset.site_id + " " + asset.site} <br />
                                                      <b className={"text-danger"}>{(asset.iot_alarm_type)}</b> <br />
                                                      {asset.iot_time}
                                                      
                                                    </Popup>}
                                                    {<Tooltip>
                                                      {asset.site} <br />
                                                      <b className={"text-danger"}>{(asset.iot_alarm_type)}</b> <br />
                                                    
                                                    </Tooltip>}
                                                </Marker>

                                                } 
                                  })} */}
                                  {/* <Marker  position={[31.1225202, 3.4774173]} icon={carIcon1} ></Marker> */}


                                {this.state.precise && this.state.assets.slice(0,1).map((asset , i) => {  
                                        if(this.state.lattitude && this.state.longtitude){
                                            return <Marker key={ this.state.OneAsset.site_id} position={[ this.state.lattitude,  this.state.longtitude]} >
                                                {<Popup>
                                                  {this.state.OneAsset.site_id + " " + asset.site} <br />
                                                  <b className={"text-danger"}>{(this.state.OneAsset.iot_alarm_type)}</b> <br />
                                                  {this.state.OneAsset.iot_time}
                                                  
                                                </Popup>}
                                                {<Tooltip>
                                                  {this.state.OneAsset.site} <br />
                                                  <b className={"text-danger"}>{(this.state.OneAsset.iot_alarm_type)}</b> <br />
                                                
                                                </Tooltip>}
                                            </Marker>
                                            
                                            } else {

                                            }
                                  })}
                                 {this.state.kml && <ReactLeafletKml kml={this.state.kml} />}
                                 {/* {this.state.precise && this.state.path.length > 0 &&
                                            <Polyline positions={this.state.path}/> 

                                  }
                                  {this.state.precise && this.state.path.length > 0 && this.state.path.map((path, index) => (
                                            <Marker  position={path} icon={L.divIcon({
                                                        html: index,
                                                        className: "number-icon",
                                                        iconSize : [35, 35],
                                                        })} >
                                            </Marker>))} */}


                                       
                                          
                                <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"  />

{/* <MarkerClusterGroup>
                              <Marker  position={[32.1225202, 3.4774173]} icon={carIcon1} ></Marker> 
                              <Marker  position={[31.1225202, 3.4774173]} icon={carIcon1} ></Marker> 
                              </MarkerClusterGroup> */}
                         <MarkerClusterGroup>
                         {!this.state.precise && this.state.assets.map(asset => {
                                          
                                          if(asset.iot_position.lat && asset.iot_position.lng){
                                              return <Marker key={asset.site_id} position={[ asset.iot_position.lat, asset.iot_position.lng]} icon={carIcon} >
                                                  {<Popup>
                                                    {asset.site_id + " " + asset.site} <br />
                                                    <b className={"text-danger"}>{(asset.iot_alarm_type)}</b> <br />
                                                    {asset.iot_time}
                                                    
                                                  </Popup>}
                                                  {<Tooltip>
                                                    {asset.site} <br />
                                                    <b className={"text-danger"}>{(asset.iot_alarm_type)}</b> <br />
                                                  
                                                  </Tooltip>}
                                              </Marker>

                                              } 
                                })}
                        </MarkerClusterGroup>
                                            
                        </MapContainer>


                        {/* {this.state.precise && this.state.path.length > 0 &&
                                              <PathMap 
                                                data={this.state.path}
                                              ></PathMap>
                                  } */}
    
                      </div>
            


                    </div>
              
                </div>
              </div>
        </div>
    



{/* 
		
				<div className={"row ui-rect my-4"}>
                
					<div className={"col-md-6 items-found"}>
                    <p>
                    <b>{this.state.total}</b> Vehicules trouvés
                    </p>					
					</div>
				<div>

                    
                </div>
					<div className={"col-md-6 per-page-wrap"}>
						
                        <div className={"per-page-content"}>
							<p>Résultat par page</p> 
							<Select
								classNamePrefix={"custom-select"}
								onChange={this.changeLimit}
								className={"per-page nm"}
								options={[
									{label: "10", value: 10},
									{label: "15", value: 15},
									{label: "30", value: 30}]}
								value={{label: this.state.limit, value: this.state.limit}}
							/>
						</div>
						<Pagination
							data={this.state}
							handleClick={this.selectPage} 
						/>						
					</div>
				</div>
		
				
				
				<div className={"ui-rect np"}>
                    <PositionsList
                        data={this.state}
                        rows={this.state.rows}
                        toggleHidden={this.toggleHidden}
                        handlers={this.handlers}
                        agents={this.state.agents}
                    />
				</div>
 */}
			
			</React.Fragment>)


	}
}

 
export default connect(
    mapStateToProps,
    mapDispatchToProps)(GridContainer);

