import React, { useState, useRef, useEffect, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faMapMarker, faLock, faSpinner, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import api from '../../Utils/Api';

import Constants from '../../Utils/Constants';
import ApiService from '../../Utils/ApiService';
//import { useSelector } from 'react-redux';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
//import api from "../../Utils/Api";


const PositionRow = ({asset, key, deleteasset, returnasset, closeasset, reload,reloadCars, ...props}) => {

    // const i = key;

    //const {session} = useSelector(state => ({session: state.session}));

    const [activeTest, selectTest] = useState("clockin")
    const [showDetail, setShowDetail] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [jsondata, setJsonData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const exportForm = useRef(null);
    const detailInput = useRef(null);
    const [centerLat, setLat] = useState(null);
    const [centerLng, setLng] = useState(null);
    const [check, setVar] = useState(null);
    const [date, setDate] =useState(null);
    const [lat, setLatitude] = useState(null);
    const [lng, setLangtitude] = useState(null);
    const [zoom, setZoom] = useState(null);
    const [q, setQ] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    //date picker
    const [timerID, setTimerID]= useState(null);


    const handleChange = event => {
        setSearchTerm(event.target.value);
        console.log('results',searchTerm)
      };
    
 
    useEffect(() => {
        const results = data.filter((d) =>
          d[2].toLowerCase().includes(searchTerm)
        );
        console.log("results json",results);
        setSearchResults(results);
        setJsonData(results)
  }, [searchTerm]);

   
       
//to use search filter i convirted data to Json data so i can go through dates and filter
//on the second hand , used it to always keep the data after filter 
    function toJson(data) {
        console.log("toJson",data);
        var jsonArg = new Object();
        var pluginArrayArg = new Array();       
        data.map((d, i) => (
        jsonArg.lat = d[0],
        jsonArg.lng = d[1],
        jsonArg.date =  d[2],
        pluginArrayArg.push(jsonArg)
        ));
        //console.log("jsonArray",pluginArrayArg)
        setJsonData(pluginArrayArg)
        console.log("toJson 1",pluginArrayArg)
    }

   /*  function search(data){
        setJsonData(data)
        return setData(jsondata.filter(
            (d)=>
            d.date.toLowerCase().indexOf(q) > -1
        ));
        console.log("Q",q)
        
    } */

    //const timerID = setInterval( () => selectAsset(),3000);
    const selectAsset = (show = true) => {

        if(!showDetail) {
            setLoading(true);
            api.get('/api/position/fetch?asset='+asset.iot_device_id)
           // ApiService.fetchAssetPosition(asset.iot_device_id)
            .then(res => {
                if(res.data.success) {
                   // toJson(res.data.positions);
                    setLat(res.data.positions[0].at(0));
                    setLng(res.data.positions[0].at(1));
                    setDate(res.data.positions[0].at(2));
                    setVar(res.data.positions.length);
                  
                    console.log("morning Row",res.data.positions);
                      //console.log("index",res.data.positions.length)
                     // console.log(" all positions",res.data.positions);
                    //console.log("time",res.data.positions[0].at(2));
                   // console.log("position 1",res.data.positions[res.data.positions.length-1].at(1));
                    setZoom(16);
                    setDataLoaded(true);
                    setData(res.data.positions);
                    setJsonData(res.data.positions);
                
                }
                setLoading(false);
            })
        }            

        setShowDetail(!showDetail)
    }

    function refreshPage() {
        window.location.reload(false);
      }

    const stopAlarm = () => {

        setLoading(true);

        ApiService.stopAlarm(asset.iot_device_id)
        .then(res => {
            if(res.data.success) {
                reload();
            }
            setLoading(false);
        })
    }


    /* const exportPM = (anomalies) => {
        setLoading(true);
		if(anomalies) {
			exportForm.current.action = Constants.apiEndPoints.EXPORT_asset_ANOMALIES(asset.asset_id);
		} else {
			exportForm.current.action = Constants.apiEndPoints.EXPORT_asset(asset.asset_id);
		}

        if(!dataLoaded)
            ApiService.fetchassetDetail(asset.asset_id)
            .then(res => {
                if(res.data.success && res.data.total > 0) {
                    
                    setDataLoaded(true);
                    setData(res.data.assets[0]);
                    detailInput.current.value = JSON.stringify(res.data.assets[0]);
                    exportForm.current.submit();
                    setLoading(false);
                }
                setLoading(false);

            })
        else {
            detailInput.current.value = JSON.stringify(data);
            exportForm.current.submit();
            setLoading(false);
        }
    } */
    
    const useForceUpdate = () => useState()[1];

    const forceUpdate = useForceUpdate();

   
    const changeCenter = (d,i,j) => {
        //still not working for some reason
        //console.log("print d", d.slice(0,2));
        console.log("hello 1", j);
        setLoading(true);
        console.log("hello before", centerLat, centerLng, zoom);
        setLat(d.at(0));
        setLng(d.at(1));
        setZoom(16);
        setLoading(false);
        forceUpdate();
        setVar(i);
        setDate(j);
        
        console.log("hello after", centerLat, centerLng, zoom);
    }

    const changeCenterHandler = changeCenter.bind(this);
    //const timerID = changeCenter.bind(this);
 //this.timerID = setInterval( () => this.loadDevices(),3000);
    //const stepsDone = Object.keys(asset.asset_tests).filter((el) => el !== "clockin" && asset.asset_tests[el].data && asset.asset_tests[el].data.done);

    /*function mapComponent({ handleClick }) {
        return (
            <div className={"col-md-9"} onClick={handleClick}>
                <ul>
                    <div id="mapid">
                        <MapContainer 
                            //center={[31.645231944730778, 2.817366469861445]} 
                            center={[centerLat, centerLng]} 
                            zoom={16} 
                            scrollWheelZoom={false} 
                            style={{
                                height:"600px",
                                width: "1000px",
                                margin: 0,
                                padding: 0
                            }}
                        >
                            {data.map((d, i) => (
                                <Marker position={d.slice(0, 2)}>
                                    <Popup> Position {i+1} : {d[2]}</Popup>
                                </Marker>
                            ))}
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </MapContainer>
                    </div>
                </ul>
            </div>
        );
      }
      
    const MemoizedSubComponent = React.memo(mapComponent);
      
    const onHandleClick = useCallback(() => {
        // this will return the same function
        // instance between re-renders
        //setLat(d.at(0));
        //setLng(d.at(1));
    });*/

    //console.log("hello data 0 2", data);
    
    //console.log("hello 222", data[data.length-1]);
    //console.log("check", centerLat, centerLng, zoom);
    console.log("check",startDate,endDate);

    return (<React.Fragment>
        {/* <form method="post" ref={exportForm} target={"_blank"} >
            <input type="hidden" name={"pm"} ref={detailInput} />
            <input type="hidden" name={"token"} value={session.user.token} />
        </form> */}
        <div className={"row "}>
        <div className={"col-md-12"}>

        <div className={"row ctable-row"}>

                <div className={"col-md-2"}>
                    {
                        asset.site
                        ?
                        <a href={"/flm/sites/detail/"+asset.site_id} style={{color: "inherit"}} className={"site-link"}>
                            {asset.site}
                        </a>
                        :
                        "-"
                    }
                </div>

                <div className={"col-md-2"}>
                    {asset.asset_type ? asset.asset_type : "-"}
                </div>

                <div className={"col-md-2"}>
                    {date ? date: asset.iot_time }
                </div>

                <div className={"col-md-2"}>
                    {asset.iot_position && asset.iot_position.lat && (
                        <a href={"https://www.google.com/maps/place/"+asset.iot_position.lat+","+asset.iot_position.lng+"/@"+asset.iot_position.lat+","+asset.iot_position.lng+",15z"} target='_blank' className="task-action-btn" >
                            <FontAwesomeIcon icon={faMapMarker}/>Localisation
                        </a>
                    )}
                </div>

                <div className={"col-md-2"}>
                    {asset.site_id}
                </div>


                <div className={"col-md-1 task-details-col"}>
                    <button onClick={() => selectAsset(!showDetail)} className="task-action-btn-B" ><FontAwesomeIcon icon={isLoading ? faSpinner : faChevronDown} spin={isLoading}  /> Details</button>
                </div>

                

        </div>

        {showDetail && dataLoaded && data && (

        <div className={"animateRow row"}>

                <div className={"pm-wrapper col-md-12 row no-gutters"}>
                    <div className={"col-md-9"}>
                        <ul>
                            <div id="mapid">
                                <MapContainer 
                                    key={JSON.stringify([centerLat, centerLng])}
                                    //center={[31.645231944730778, 2.817366469861445]} 
                                    center={[centerLat, centerLng]} 
                                    zoom={zoom} 
                                    scrollWheelZoom={true} 
                                    style={{
                                        height:"600px",
                                        width: "1158px",
                                        margin: 0,
                                        padding: 0
                                    }}>

                                    {/* {
                                        check === true ?
                                        <Marker position={d.slice(0, 2)}>
                                            <Popup> Position {i+1} : {d[2]}</Popup>
                                        </Marker>
                                        :null
                                    } */}
                                    
                                      
                                    {data.map((d, i) => (
                                       
                                        <Marker position={[centerLat, centerLng]}>
                                            <Popup> Position {check} : {date}</Popup>
                                        </Marker>
                                    ))   
                                    }

                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </MapContainer>
                            </div>
                        </ul>
                    </div>
                    
                    <div className={"col-md-3 pm-sidebar-B"}>
                        <ul>
                     
                        <div>
                         <li>
                            <input  type="text" placeholder="Search"   value={searchTerm} onChange={handleChange}   />
                        </li>
                        </div>
                        {jsondata.map((d, i) => (
                            <div>
                                <li>
                                    <div className={"active"}  onClick={() => {changeCenterHandler(d,jsondata.length - i,d[2])}}>
                                      Position  {jsondata.length - i} :
                                    </div>
                                    {' ' + d[2]}
                                </li> 
                            </div>
                        ))}
                       
                        </ul>
                    </div>
                </div>
        </div>
        
        )}

    </div>
    </div>
    </React.Fragment>

    )
}
export default PositionRow;
