import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Select from 'react-select';

import "react-table/react-table.css";
import { connect } from 'react-redux';
import {setAuthAction, startLoading, doneLoading, closeFlash, setFlash} from '../../Redux/actions/index';
import {  Trans } from "react-i18next";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTimes, faPlus, faPen, faChevronCircleDown, faChevronRight, faCalendar, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import Pagination from '../../Utils/Pagination';


const moment = require("moment");



const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user, 
		taskList: state.taskList
	}
}

const mapDispatchToProps = (dispatch) => 
	({
		setAuth: user => dispatch(setAuthAction(user)),
		doneLoading: () => dispatch(doneLoading()),
		isLoading: (p) => dispatch(startLoading(p)),
		setFlash: (f) => dispatch(setFlash(f)),
		closeFlash: (f) => dispatch(closeFlash(f))
	})




class LinkPlan extends Component {

	

	
	constructor(props) {
		super(props);
		this.state = {
			newManholeOverlay: false,
			rows: [],
			showOverlay: false,
			siteid: "",
			agentid: "",
			date: "",
			newManhole: {
				type: {},
				name: ""
			},
			selectedLink: {},
			manholesListOverlay: false,
			manholesList: [],
			manholesLoading: true,
			bridgesListOverlay: false,
			SiteAccessListOverlay:false,
			bridgesList: [],
			bridgesLoading: true,
			SiteAccessLoading:true,
			planOverlay: false,
			linkOverlay: false,
			agents: []
		}

		this.toggleOverlay = this.toggleOverlay.bind(this);

	}



	handleEscape = (e) => {
		if(e.keyCode === 27) {
			this.setState({showOverlay: false, newManholeOverlay: false, manholesListOverlay: false, bridgesListOverlay: false,SiteAccessListOverlay:false, bridgeOverlay: false});
		}
	}

	toggleOverlay(e) {
		this.setState({showOverlay: !this.state.showOverlay});
	}
	
	toggleManholeOverlay(link, e) {

		if(this.state.newManholeOverlay) {
			this.setState({ newManholeOverlay: false })
		} else {
			e.preventDefault();
			this.setState({ selectedLink: link, newManholeOverlay: true });
		}
	}


	toggleLinkOverlay(link, e) {

		if(this.state.linkOverlay) {
			this.setState({ linkOverlay: false })
		} else {
			e && e.preventDefault();
			this.setState({ selectedLink: link, linkOverlay: true });
		}
	}

	

	componentDidMount() {
		document.addEventListener('keyup', this.handleEscape);


		api.get('/api/agent/fetch?limit=-1&status=enabled')
		.then(response => {
			this.setState({
				agents: response.data.users.map(user => ({label: user.username, value: user.agentid }))
			})
			console.log(response)
		})
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.handleEscape);
	}

	
	formSubmit = (e) => {

		e.preventDefault();

		if(!this.state.date || !this.state.agentid) {
			return false;	
		}

		this.props.isLoading();
		const that = this;
		
		const data = {
            planning_date: this.state.date,
            selected_site: this.state.siteid,
            agent: this.state.agentid
		}

		
		
		api.post('/api/planning/assign', data)
		.then(function(response) {
			if(response.data.success) {
				that.props.setFlash({
					active: true,
					message: "Site attribué avec succés.",
					classes: "success",
					style: {}
				})
				that.props.handlers.loadLinks();
				that.setState({ showOverlay: false })
			} else {
				that.props.setFlash({
					active: true,
					message: "Une erreur est survenue, veuillez contacter l'administrateur.",
					classes: "warning",
					style: {}
				})
			}

			that.props.doneLoading();

		})
		.catch(err => {
			that.props.setFlash({
				active: true,
				message: "Une erreur est survenue, veuillez contacter l'administrateur.",
				classes: "warning",
				style: {}
			})
			that.props.doneLoading();

		})

	}


	submitNewManhole = (e) => {
		e.preventDefault();


		console.log(this.fileInput);

		//return;



		if(!this.state.newManhole.type.value || !this.state.newManhole.name || !this.state.selectedLink.link_id ) {
			console.log(this.state.newManholeOverlay);
			return false;
		}

		this.props.isLoading();
		this.setState({ newManholeOverlay: false });

		const that = this;
		
		const data = {
            type: this.state.newManhole.type.value,
            name: this.state.newManhole.name,
            link_id: this.state.selectedLink.link_id
		}

		
		
		api.post('/api/manhole/new', data)
		.then(function(response) {
			if(response.data.success) {
				that.props.setFlash({
					active: true,
					message: "Manhole ajouté avec succés.",
					classes: "success",
					style: {}
				})
				that.props.handlers.loadLinks();
				
			} else {
				that.props.setFlash({
					active: true,
					message: "Une erreur est survenue, veuillez contacter l'administrateur.",
					classes: "warning",
					style: {}
				})
			}

			that.props.doneLoading();

		})
		.catch(err => {
			that.props.setFlash({
				active: true,
				message: "Une erreur est survenue, veuillez contacter l'administrateur.",
				classes: "warning",
				style: {}
			})
			that.props.doneLoading();

		})

	}

	
	selectManholeType = (event) => {
		if(!this.state.newManhole.name || ['K2C', 'L3T', 'BMH'].indexOf(this.state.newManhole.name) > -1)
			this.setState({ newManhole : { name: event.label, type: event } })
		else
			this.setState({ newManhole : { ...this.state.newManhole, type: event } })
	}

	updateManholeName = (event) => {
		
		this.setState({
			newManhole: {
				...this.state.newManhole,
				name: event.target.value
			}
		})
	}

	loadManholes = (link_id) => {

		api.get('/api/manhole/fetch/'+link_id)
		.then(response => {
			this.setState({
				manholesList: response.data.manholes,
				manholesLoading: false
			});
		})
		
	}

	loadBridges = (link_id) => {

		api.get('/api/bridge/fetch/'+link_id)
		.then(response => {
			this.setState({
				bridgesList: response.data.bridges,
				bridgesLoading: false,
			});
		})
		
	}
 

	toggleManholesList = (link) => {
		console.log(link)

		if(typeof link !== "undefined") {
			this.setState({ manholesLoading: true })
			this.loadManholes(link.link_id)
			this.setState({ manholesListOverlay: true })
		} else {
			this.setState({ manholesListOverlay: false})
		}
		//this.setState({ manholesListOverlay: !this.state.manholesListOverlay})
	}
	
	toggleBridgesList = (link) => {
		if(typeof link !== "undefined") {
			this.setState({ bridgesLoading: true })
			this.loadBridges(link.link_id)
			this.setState({ bridgesListOverlay: true })
		} else {
			this.setState({ bridgesListOverlay: false})
		}
		//this.setState({ manholesListOverlay: !this.state.manholesListOverlay})
	}
	 


	submitFile = (e) => {

		e.preventDefault();

		if (this.fileInput.files.length === 0) {
			e.preventDefault();
		} else {
			console.log(this.state.selectedLink.link_id)
			e.target.submit();
			return;
			
		}
		
		
	}

	toggleBridgeOverlay = (link, e) => {
		if(this.state.bridgeOverlay) {
			this.setState({ bridgeOverlay: false })
		} else {
			e && e.preventDefault();
			this.loadManholes(link.link_id)
			this.setState({ selectedLink: link, bridgeOverlay: true });
		}
	}

	togglePlanOverlay = (link, e) => {
		console.log('toggle plan overlay')
		if(this.state.planOverlay) {
			this.setState({ planOverlay: false })
		} else {
			e.preventDefault();
			//this.loadManholes(link.link_id)
			this.setState({ selectedLink: link, planOverlay: true });
		}
	}


	submitNewBridge = (e) => {
		e.preventDefault();

		this.setState({
			bridgeOverlay: false	
		})
		
		this.props.isLoading({
			message: "Adding new Cross-Bridge",
			animated: true
		});

		const form = new FormData(e.target);

		let data = {
			linkid: this.state.selectedLink.link_id
		};

		for(var [key, value] of form.entries()) 
			data[key] = value

		api.post('/api/bridge/new', data)
		.then(response => {

			if(response.data.success) {
				this.props.setFlash({
					active: true,
					message: "Cross-Bridge ajouté avec succés.",
					classes: "success",
					style: {}
				})
				this.props.handlers.loadLinks();
				
			} else {
				this.props.setFlash({
					active: true,
					message: "Une erreur est survenue, veuillez contacter l'administrateur.",
					classes: "warning",
					style: {}
				})
			}

			this.props.doneLoading();

		})
		.catch(err => {
			this.props.setFlash({
				active: true,
				message: "Une erreur est survenue, veuillez contacter l'administrateur.",
				classes: "warning",
				style: {}
			})
			this.props.doneLoading();
		})

	}
	
	submitNewPlan = (e) => {
		e.preventDefault();

		const form = new FormData(e.target);

		this.props.isLoading({
			message: "Chargement",
			animated: true
		});


		let data = {
			linkid: this.state.selectedLink.link_id
		};

		for(var [key, value] of form.entries()) 
			data[key] = value

		api.post('/api/task/new', data)
		.then(response => {
			if(response.data.success) {
				this.setState({
					planOverlay: false
				})

				this.props.handlers.loadLinks();
			}
			
			this.props.doneLoading();
			
		
		})
	}

	submitEditManhole = (e) => {
		e.preventDefault();


		const form = new FormData(e.target);

		let data = {
			linkid: this.state.selectedLink.link_id
		};

		for(var [key, value] of form.entries()) 
			data[key] = value



		api.post('/api/link/edit', data)
		.then(response => {
			if(response.data.success)
				this.props.handlers.loadLinks();
			
		})
		.then(r => this.toggleLinkOverlay())


	}
	

	render() {
		//const { t, i18n } = useTranslation();
		
		const data = this.props.data;
		const toggleHidden = this.props.toggleHidden;
		const showOverlay = this.state.showOverlay


		return(

			<React.Fragment>	


			<div className={this.state.linkOverlay? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content newmanhole-overlay status-overlay-text col-md-4 offset-md-4"}>
					<button data-package-id="0" onClick={e => { this.toggleLinkOverlay(e); } } className={"ui-overlay-close cbtn danger"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>
					<h3 className={"mb-3"}>Modifier les informations</h3>


					<form onSubmit={this.submitEditManhole}>

					<div className="form-group mt-3">
					<label className={"font-exp"}>Link name</label>
					<input className={"form-control"} defaultValue={this.state.selectedLink.link_name} type={"text"} name="name" />
					</div>
					
					<div className="form-group mt-3">
					<label className={"font-exp"}>Section/Région</label>
					<Select
					
					options={[
						{ label: "Centre", value: "middle" },
						{ label: "Est", value: "East" },
						{ label: "Ouest", value: "west" },
						{ label: "Sud", value: "south" }
						
					]}
					cacheOptions
					defaultOptions 
					placeholder="Region"
					className={"d-block mt-1"}
					name={"section"}
					/>
					</div>

					<div className="form-group mt-3">
					<label className={"font-exp"}>Wilaya</label>
					
					<Select
					
					options={this.props.data.wilayas}
					cacheOptions
					defaultOptions 
					placeholder="Wilaya"
					className={"d-block mt-1"}
					name={"wilaya"}
					/>
	
					{this.state.selectedLink.task_plan ? (
						<>
							<div className="form-group mt-3">
							<label className={"font-exp"}>Agent</label>
							<Select
							
							options={[
								{ label: "agent", value: "agent" }
							]}

							cacheOptions
							defaultOptions 
							placeholder="Agent"
							className={"d-block mt-1"}
							name={"section"}
							/>
							</div>
						</>
					) : (<></>)}

					<input type="submit" value="Sauvegarder" className={"cbtn"} />
					</div>
					</form>

				</div>
			</div>

			<ManholesList
				data={this.state.manholesList}
				loaded={this.state.manholesLoading}
				open={this.state.manholesListOverlay}
				toggleFunc={this.toggleManholesList}
			/>


			<BridgesList 
				data={this.state.bridgesList}
				loaded={this.state.bridgesLoading}
				open={this.state.bridgesListOverlay}
				toggleFunc={this.toggleBridgesList}
			/>

		 
			



			<div className={this.state.newManholeOverlay? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content newmanhole-overlay status-overlay-text col-md-4 offset-md-4"}>
					<button data-package-id="0" onClick={e => { this.toggleManholeOverlay(e); } } className={"ui-overlay-close cbtn danger"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>
					<h3 className={"mb-3"}>Ajouter un nouveau Manhole</h3>

					<h5>Importer un fichier <a target={"_blank"} href="https://app.netfer.tictechs.tech/template_manholes.xlsx">(voir template)</a></h5>
					<form action="https://app.netfer.tictechs.tech/api/manhole/batch" method="post" onSubmit={this.submitFile} encType={"multipart/form-data"} >
					<input accept={".xls,.xlsx"} ref={ref => this.fileInput = ref} id="planning_file" name="manhole_file" className={"form-control"} type="file" />
					<input type="hidden" name="token" ref={ref => this.tokenInput} value={this.props.user.token} />
					<input type="hidden" name="linkid" ref={ref => this.linkInput} value={this.state.selectedLink.link_id} />
					<input type="submit" value={"Envoyer"} className={"cbtn"} />
					</form>

					<h5 className={"my-3"}>Ou</h5>
					<h5>Remplissez le formulaire</h5>

					<form onSubmit={this.submitNewManhole}>
					<div className="form-group mt-3">
					<label className={"font-exp"}>Manhole Type</label>
					<Select
					
					options={[
						{ label: "L3T", value: "l3t" },
						{ label: "K2C", value: "k2c" },
						{ label: "BMH", value: "bmh" }
					]}
					cacheOptions
					defaultOptions 
					placeholder="Type"
					className={"d-block mt-1"}
					onChange={e => this.selectManholeType(e)}
					/>
					</div>

					<div className="form-group mt-3">
					<label className={"font-exp"}>Nom/Code du Manhole</label>
					
					<input value={this.state.newManhole.name} onChange={e => this.updateManholeName(e)} type="text" className={"form-control"} />

					<input type="submit" value="Ajouter" className={"cbtn"} />
					</div>
					</form>

				</div>
			</div>



			<div className={this.state.bridgeOverlay? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content newmanhole-overlay status-overlay-text col-md-4 offset-md-4"}>
					<button data-package-id="0" onClick={e => { this.toggleBridgeOverlay(e); } } className={"ui-overlay-close cbtn danger"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>
					<h3 className={"mb-3"}>Ajouter un nouveau Cross-Bridge</h3>

					
					<h5>Remplissez le formulaire ci-dessous</h5>

					<form onSubmit={this.submitNewBridge}>
					<div className="form-group mt-3">
						<label className={"font-exp"}>Place name</label>
						<input name={"place_name"} type="text" className={"form-control"} />
					</div>

					<div className="form-group mt-3">
						<label className={"font-exp"}>From manhole</label>
						<Select
						name={"from_manhole"}
						options={this.state.manholesList.map(item => ({label: item.manhole_name ? `${item.manhole_name} (${item.manhole_type})` : item.manhole_type+" #"+item.manhole_id, value: item.manhole_id}))}
						isLoading={this.state.manholesLoading}
						cacheOptions
						defaultOptions 
						placeholder="From"
						className={"d-block mt-1"}
						/>
					</div>

					<div className="form-group mt-3">
						<label className={"font-exp"}>To manhole</label>
						<Select
						name={"to_manhole"}
						options={this.state.manholesList.map(item => ({label: item.manhole_name ? `${item.manhole_name} (${item.manhole_type})` : item.manhole_type+" #"+item.manhole_id, value: item.manhole_id}))}
						isLoading={this.state.manholesLoading}
						cacheOptions
						defaultOptions 
						placeholder="To"
						className={"d-block mt-1"}
						/>
					</div>

					<button className={"cbtn"} type="submit">Ajouter</button>

					
					</form>

				</div>
			</div>


			<div className={this.state.planOverlay? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content newmanhole-overlay status-overlay-text col-md-4 offset-md-4"}>
					<button data-package-id="0" onClick={e => { this.togglePlanOverlay(e); } } className={"ui-overlay-close cbtn danger"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>
				
					<h5>Planifier une pm</h5>

					<form onSubmit={this.submitNewPlan}>
					<div className="form-group mt-3">
						<label className={"font-exp"}>CR</label>
						<input name={"task_cr"} type="text" className={"form-control"} required />
					</div>

					<div className="form-group mt-3">
						<label className={"font-exp"}>Date</label>
						<input name={"date"} type="date" className={"form-control"} required />
					</div>

					<div className="form-group mt-3">
						<label className={"font-exp"}>Technicien</label>
						<Select
						classNamePrefix={"custom-select"}
						name={"agentid"}
						options={this.state.agents}
						cacheOptions
						defaultOptions 
						placeholder="Technicien"
						className={"d-block mt-1"}
						/>
					</div>

					

					<button className={"cbtn"} type="submit">Ajouter</button>

					
					</form>

				</div>
			</div>



			
			

			<div className={"row"}>

				<div className={"col-md-12"}>
					<div className={"row"}>
						<div className={"col-md-6"}>
						
							<p>
								<b>{data.total}</b> links trouvés
							</p>
						</div>
						
						

					</div>


				</div>


			</div>

			

			<div className="col-md-12 ctable mb-3">
			
			
			<div className={"d-none d-sm-block"}>
				
				<div className={"row head-titles ctable-row"}>

					<div className={"col-md-1"}>
						Link ID
					</div>

					
					<div className={"col-md-2"}>
						Link
					</div>


					<div className={"col-md-2"}>
						Wilaya
					</div>
					
					<div className={"col-md-2"}>
						Manholes & bridges & site_access
					</div>

					{/* <div className={"col-md-2"}>
						<Trans i18nKey="label_address" />
					</div> */}

					

					<div className={"col-md-2"}>
						Plan
					</div>


					<div className={"col-md-3 table-detail-col"}>
						<Trans i18nKey="label_details" />
					</div>

				</div>
			</div>

			{data.links.map((site, i) => 
				
			(<React.Fragment key={i}>
				
				<div className={"row "}>
				<div className={"col-md-12"}>

				<div className={"row ctable-row"}>
					
					
						<div className={"col-md-1"}>
							 #{site.link_id}
						</div>

						<div className={"col-md-2"}>
							{site.link_name}
						</div>


						<div className={"col-md-2"}>
							{site.link_wilaya ? site.link_wilaya.toUpperCase() : ""}
						</div>
						
						<div className={"col-md-2 task-details-col"} style={{justifyContent: "space-around"}}>
							
							<button onClick={e => this.toggleManholesList(site)} className={"task-action-btn"}>

								<span>{parseInt(site.link_manholes) | 0} manholes</span>
							</button>
						
							
							<button onClick={e => this.toggleBridgesList(site)} className={"task-action-btn"}>
								<span>{parseInt(site.link_bridges) | 0} bridges</span>
							</button>

						 

						</div>

						<div className={"col-md-2 link-plan-col"}>

							
								<span className={"date"}><a href={"/task/"+site.link_plan.cr}>{site.link_plan.date}</a></span>
								<span className={"cr-code"}><a href={"/task/"+site.link_plan.cr}>{site.link_plan.cr}</a></span>
							
							
						</div>
						

 						<div className={"col-md-3  task-details-col"}>
							{/* {site.site_next_pm_agent ? (<>{site.site_next_pm_agent} <a onClick={e => this.reassignSite(site, e)} href="#" className={"btn btn-sm btn-primary ml-2 "}>Réattribuer</a></>) : 
							<a onClick={e => this.reassignSite(site, e)} href="#" className={"btn btn-sm btn-primary float-right"}>
								Attribuer à un agent
							</a>} */}


							<button onClick={e => this.togglePlanOverlay(site, e)} className={"task-action-btn"}>
								<FontAwesomeIcon icon={faCalendar} />
								Planifier
							</button>
							
							
                            
							
						</div>

						

						{/* <div className={"col-md-1"}>
							<div>

							<button  onClick={toggleHidden} data-row-index={i}  className={"btn btn-block btn-sm btn-link"}>Detail site </button>
							</div>
						</div> */}

					
				</div>

				
				
			</div>
			</div>


			</React.Fragment>

			))}
			{data.links.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucun link trouvé</div>)}





			<div className={showOverlay? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
					<button data-package-id="0" onClick={e => { this.toggleOverlay(e); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					<h5>Attribuer le site à un agent</h5>
					<form onSubmit={this.formSubmit}>
					<div className="form-group mt-3">
					<label className={"font-exp"}>PM Plan</label>
					<input required value={this.state.date} onChange={t => this.setState({ date: t.target.value })} type="date" className={"form-control"} />
					</div>

					<div className="form-group mt-3">
					<label className={"font-exp"}>Select agent</label>
					<Select
					
					value={{'value': this.state.agentid, label: this.state.agent_username}}
					options={data.agents}
					cacheOptions
					defaultOptions 
					onChange={e => this.setState({agentid: e.value, agent_username: e.label})}
					placeholder="Technician"
					className={"d-block mt-1"}
					/>

					<input type="submit" value="Update" className={"btn btn-primary btn-sm mt-2"} />
					</div>
					</form>
					<div>
						<span className={"font-exp"}>Agent will receive a notification.</span>
					</div>
					
				</div>
			</div>


			</div>

			

			</React.Fragment>
		)
	}
}

class BridgeRow extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			editable: false,
			deleted: false,
			data: {},
			deleteActive: false
		}
	}

	componentDidMount() {
		this.setState({
			data: this.props.data
		})
	}

	toggleEditableRow = () => {
		this.setState({ editable: !this.state.editable })
	}

	deleteBridge = () => {

		const data = {
			id: this.state.data.bridge_id
		}

		api.post('/api/bridge/delete', data)
		.then(res => {
			this.setState({deleted: true})
			this.toggleDeleteBridge();
		})
	}

	toggleDeleteBridge = () => {
		this.setState({
			deleteActive: !this.state.deleteActive
		})
	}

	render() {
		if(!this.state.deleted)
		return (
			<div className={"table-row"}>

				<div className={"delete-confirm "+(this.state.deleteActive ? "open" : "")}>
					Voulez-vous vraiment supprimer ?
					<span>
						<button onClick={e => this.setState({deleteActive: false})} className={"btn neutral"}>Annuler</button>
						<button onClick={this.deleteBridge} className={"btn anti-danger"}>Supprimer</button>
					</span>
				</div>
				
				<div>{this.props.data.bridge_id}</div>
				<div>{`${this.props.data.bridge_from} (${this.props.data.bridge_from_type})`}</div>
				<div>{`${this.props.data.bridge_to} (${this.props.data.bridge_to_type})`}</div>
				<div>{`${this.props.data.bridge_place}`}</div>


				<div className={"manhole-list-btns"}>
				</div>
				
			</div>

		) 
		else return (<></>);
	}
}

class ManholeRow extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			deleted: false,
			editable: false,
			data:{},
			deleteActive: false
		}


	}

	componentDidMount() {
		this.setState({
			data: this.props.data
		})
	}

	toggleEditableRow = () => {
		this.setState({ editable: !this.state.editable })
	}
	
	editManhole = (e) => {
		e.preventDefault();
		const data = {
			id: this.state.data.manhole_id,
			name: this.nameRef.value,
			type: this.typeRef.state.value.value
		};

		api.post('/api/manhole/edit', data)
		.then(res => {
			//console.log(res);
				this.setState({
					data: {
						manhole_name: data.name,
						manhole_type: data.type,
						manhole_id: data.id
					}
				});

				this.toggleEditableRow();
			
		})

		
	}

	deleteManhole = () => {

		const data = {
			id: this.state.data.manhole_id
		}

		api.post('/api/manhole/delete', data)
		.then(res => {
			this.setState({deleted: true})
			this.toggleDeleteManhole();
		})
	}

	toggleDeleteManhole = () => {
		this.setState({
			deleteActive: !this.state.deleteActive
		})
	}

	render() {

		if(!this.state.deleted)
		return (
			<div className={"table-row"}>

			
				
				<div>{this.state.data.manhole_id}</div>
				<div>{this.state.data.manhole_type}</div>
				<div>{this.state.data.manhole_name}</div>
				<div className={"manhole-list-btns"}>
                    
				</div>
				
			</div>
		) 
		else {
			return <></>
		}
	}

}
class SiteAccessRow extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			editable: false,
			deleted: false,
			data: {},
			deleteActive: false
		}
	}

	componentDidMount() {
		this.setState({
			data: this.props.data
		})
	}

	toggleEditableRow = () => {
		this.setState({ editable: !this.state.editable })
	}

	deleteBridge = () => {

		const data = {
			id: this.state.data.bridge_id
		}

		api.post('/api/siteaccess/delete', data)
		.then(res => {
			this.setState({deleted: true})
			this.toggleDeleteBridge();
		})
	}

	toggleDeleteBridge = () => {
		this.setState({
			deleteActive: !this.state.deleteActive
		})
	}

	render() {
		if(!this.state.deleted)
		return (
			<div className={"table-row"}>

				<div className={"delete-confirm "+(this.state.deleteActive ? "open" : "")}>
					Voulez-vous vraiment supprimer ?
					<span>
						<button onClick={e => this.setState({deleteActive: false})} className={"btn neutral"}>Annuler</button>
						<button onClick={this.deleteBridge} className={"btn anti-danger"}>Supprimer</button>
					</span>
				</div>
				
				<div>{this.props.data.bridge_id}</div>
				<div>{`${this.props.data.link_id}`}</div>
				<div>{`${this.props.data.link_name}`}</div>
				<div>{`${this.props.data.bridge_place}`}</div>


				<div className={"manhole-list-btns"}>
					<button onClick={this.toggleDeleteBridge} className={"task-action-btn danger"}>Supprimer </button>
				</div>
				
			</div>

		) 
		else return (<></>);
	}
}

const ManholesList = ({open, toggleFunc, submit, loaded, data, ...props}) => {



	return (
		<div className={open? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content manholes-list-overlay status-overlay-text col-md-6 offset-md-3"+ (!loaded && " loaded")}>
					<button onClick={e => toggleFunc() } className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					<h5>List Manholes</h5>
					<div className={"clearfix mb-5"}></div>
						{loaded ? "Chargement des manholes..." : (
							<>
							<div className={"table-responsive"}>
								<div className={"table-row"}>
									<div>ID</div>
									<div>Type</div>
									<div>Name</div>
									<div></div>
								</div>
											
							</div>
							{data.length > 0 ? data.map(item => <ManholeRow data={item} />)
							: 
								<tr>
									<td colSpan={4}>Aucun manhole trouvé</td>
								</tr>
							}
										
						</>
						)}

				</div>
			</div>
	)
}


const BridgesList = ({open, toggleFunc, submit, loaded, data, ...props}) => (
			<div className={open? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content manholes-list-overlay status-overlay-text col-md-6 offset-md-3"+ (!loaded && " loaded")}>
					<button data-package-id="0" onClick={e => { toggleFunc(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					<h5>Cross-Bridge list</h5>
					<div className={"clearfix mb-5"}></div>
						{loaded ? "Chargement des Cross-Bridge..." : (


							<div className={"table-responsive"}>
									<div className={"table-row"}>
										<div>ID</div>
										<div>Between</div>
										<div>And</div>
										<div>Place name</div>
										<div></div>
									</div>
										{data.length > 0 ? data.map(item => (<BridgeRow data={item} />))
										: 
										<tr>
											<td colSpan={4}>No Cross-Bridge Found</td>
										</tr>
										}
										
							</div>
						)}

				</div>
			</div>
)
 

export default connect(mapStateToProps, mapDispatchToProps)(LinkPlan)