import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import LinkListContainer from '../Containers/LinkListContainer';
import LinkNew from '../Components/Link/LinkNew';



function mapStateToProps(state) {
  return { auth: state.auth };
};


class LinkRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
						<Route exact path='/link' component={LinkListContainer} />
                        <Route exact path='/link/new' component={LinkNew} />
                        
						<Route exact path='/task/:taskid/' component={LinkListContainer} />
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(LinkRouter)