
let initialState;


	
const { user, auth } = (localStorage.user_session) ? JSON.parse(localStorage.user_session) : {user: {}, auth: false};


initialState = {
  auth: auth,
  user: user,
  notifications: [],
  notificationsHash: "",
  taskList: [],
  agentsList: [],
  flashMessage: {
  	active: false,
  	message: "",
  	style: {},
  	classes: ""
  },
  loading: false,
  loadingMessage: {
				message:"Loading",
				animate:false
			},
  overlay: {
  	enabled: false,
  	message: "Testing",
  	label: false,
  	button: {
  		type: "button",
  		label: "Test button label",
  		link: "http://google.com"
  	}
  }

};





const userReducer = function(state = initialState, action) {

	if (action.type === 'DONE_LOADING') {

		let newState = {
			...state,
			loading: false,
			loadingMessage: {
				message: "Loading",
				animate: false
			}
		}

		return newState;

	}

	if (action.type === 'IS_LOADING') {

		let newState = {
			...state,
			loading: true,
			loadingMessage: {
				message: action.payload ? action.payload.message : state.loadingMessage.message,
				animate: action.payload ? action.payload.animate : state.loadingMessage.animate
			}
		}

		return newState;
	}


	if (action.type === 'SET_AUTH') {

		const userSession = {
				userid: action.payload.userid,
				username: action.payload.username,
				role: action.payload.role,
				plan_type: action.payload.plan_type,
				plan_credit: action.payload.plan_credit,
				plan_used_agents: action.payload.plan_used_agents,
				token: action.payload.token,
				picture: action.payload.picture
			}

		let newState = {
			...state,
			auth: action.payload.success,
			user: userSession
			
		}
		localStorage.setItem("user_session", JSON.stringify({auth:action.payload.success, user:userSession}));
		
		if(!action.payload.stay)
			window.location.href = '/login';
		
			return newState;


		
	}


	if (action.type === 'SET_OVERLAY') {

		if(action.payload) {
			console.log(action.payload);
			let newState = {
				...state,
				overlay: {
					enabled: true,
					message: action.payload.message,
					button: action.payload.button,
					label: action.payload.label ? action.payload.button : false

				}
			}

			return newState;
		} else {
			let newState = {
				...state,
				overlay: {
					enabled: false
				}
			}
			return newState;
		}
		

		
	}


	if (action.type === 'UPDATE_NOTIFICATIONS') {
		let newState = {
			...state,
			notifications: action.payload.notifications,
			notificationsHash: action.payload.hash

		}

		return newState;
	}


	if (action.type === 'READ_NOTIFICATIONS') {
		let newState = {
			...state,
			notifications: state.notifications.map(x => ({...x, read: true})),
			
		}

		return newState;
	}


	if (action.type === 'SET_FLASH') {
		let newState = {
			...state,
			flashMessage: action.payload
			
		}

		return newState;
	}


	if (action.type === 'CLOSE_FLASH') {
		let newState = {
			...state,
			flashMessage: {
				...state.flashMessage,
				active: false
			}
			
		}

		return newState;
	}




	if (action.type === 'UPDATE_DATA') {
		let newState = {
			...state,
			agentsList: action.payload.agents
			
		}

		return newState;
	}

	

	

  return state;
};



export default userReducer;