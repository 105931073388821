import axios from "axios"
import store from "./../Redux/store/index"

const state = store.getState();
//le lien avec le back-end
// let remote = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : "http://localhost/NETFER/back/public";//'http://localhost/netfer-backend/public/';
// let remote = 'http://netfer.mt/netfer-back/public/';

 let remote = 'https://app.netfer.tictechs.tech';
//let remote = process.env.REACT_APP_REMOTE_URL;

if (state.auth && state.user.token)
    axios.defaults.headers.common['X-STS-Token'] = state.user.token;

const api = axios.create({ baseURL: remote });

export default api
