import React, { Component } from 'react';
import {  NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

class CManagerSideMenu extends Component {



	

	render() {

		if(this.props.auth)
		return (
			
			<ul className={"sideMenu-list"}>
				<li>
					
					<NavLink  to={"/manager/list"}>Managers</NavLink>
					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/manager/list"}>Managers</NavLink></li>
						<li><NavLink exact to={"/manager/new"}>Nouveau manager</NavLink></li>
					</ul>
				</li>
				

				<li>
					<NavLink to={"/sites"}><Trans i18nKey="label_sites" /></NavLink> 

					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/sites"}><Trans i18nKey="label_sites_list" /></NavLink></li>
						<li><NavLink strict exact to={"/sites/new"}><Trans i18nKey="label_new_site" /></NavLink></li>
                        <li><NavLink strict exact to={"/planning"}>Planning</NavLink></li>
					</ul>

				</li>

				<li>
					<NavLink to={"/task"}><Trans i18nKey="label_pm" /></NavLink> 

					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/task"}><Trans i18nKey="label_pm_list" /> </NavLink></li>
					</ul>

				</li>


				<li>
					<NavLink to={"/account"}><Trans i18nKey="menu_account" /></NavLink> 

					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/account"}><Trans i18nKey="menu_personal" /></NavLink></li>
						{/* <li><NavLink strict exact to={"/account/privacy"}><Trans i18nKey="menu_notifications" /></NavLink></li> */}
						<li><NavLink strict exact to={"/account/security"}><Trans i18nKey="menu_security" /></NavLink></li>
					</ul>

				</li>

			</ul>

		)
	}
}



export default connect(mapStateToProps, null, null, { pure: false})(CManagerSideMenu)
/*
<div className="list-group">
					<Link className="list-group-item list-group-item-action" to={"/items"}>Items</Link>
					<Link className="list-group-item list-group-item-action" to={"/packages"}>Packages</Link>
					<Link className="list-group-item list-group-item-action" to={"/roles"}>Roles</Link>
					<Link className="list-group-item list-group-item-action" to={"/security"}>Security</Link>
					<Link className="list-group-item list-group-item-action" to={"/config"}>Configuration</Link>

				</div>
				*/