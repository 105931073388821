import React, { useState, useRef, useEffect, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faMapMarker, faLock, faSpinner, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import api from '../../Utils/Api';

import Constants from '../../Utils/Constants';
import ApiService from '../../Utils/ApiService';
//import { useSelector } from 'react-redux';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
//import api from "../../Utils/Api";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { buildUriParams } from "../../Utils/Helpers";


const Pane = ({key,asset, deleteasset, returnasset, closeasset, reload,reloadCars,refresh,handleMap,user, selectVehicule, ...props}) => {

    // const i = key;

    //const {session} = useSelector(state => ({session: state.session}));

    const [activeTest, selectTest] = useState("clockin")
    const [showDetail, setShowDetail] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [jsondata, setJsonData] = useState([]);
    const [dataStatic, setDataStatic] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const exportForm = useRef(null);
    const detailInput = useRef(null);
    const [centerLat, setLat] = useState(null);
    const [centerLng, setLng] = useState(null);
    const [check, setVar] = useState(null);
    const [date, setDate] =useState(null);
    const [lat, setLatitude] = useState(null);
    const [lng, setLangtitude] = useState(null);
    const [zoom, setZoom] = useState(null);
    const [q, setQ] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [kml, setKML] = useState(null);
    const [filters, setFilters] = useState({});

    //date picker
    const [timerID, setTimerID]= useState(null);
    //const [filterDate, setFilter] = useState([]);
    
    const remote =  process.env.REACT_APP_BACKEND_URL


    const handleChange = event => {
        setSearchTerm(event.target.value);
      };
    useEffect(() => {
            selectAsset();
    }, []); 
 
    useEffect(() => {
        const results = data.filter((d) =>
          d[2].toLowerCase().includes(searchTerm)
        );
        setSearchResults(results);
        setJsonData(results)
    }, [searchTerm]);

    useEffect(() => {
        if(startDate){
            selectAsset()
            //update(); 
        }
    }, [startDate]); 

    useEffect(() => {
        if(endDate){
            selectAsset()
            //update(); 
        }
    }, [endDate]); 

    // useEffect(() => {
    //     if(kml){
    //         update(); 
    //     }
    // }, [kml]); 

    // useEffect(() => {
    //     if(jsondata){
    //         update();
    //     }
    // }, [jsondata]); 
 
   
  

//to use search filter i convirted data to Json data so i can go through dates and filter
//on the second hand , used it to always keep the data after filter 
    function toJson(data) {
        var jsonArg = new Object();
        var pluginArrayArg = new Array();       
        data.map((d, i) => (
        jsonArg.lat = d[0],
        jsonArg.lng = d[1],
        jsonArg.date =  d[2],
        pluginArrayArg.push(jsonArg)
        ));
        //console.log("jsonArray",pluginArrayArg)
        setJsonData(pluginArrayArg)
    }

   /*  function search(data){
        setJsonData(data)
        return setData(jsondata.filter(
            (d)=>
            d.date.toLowerCase().indexOf(q) > -1
        ));
        console.log("Q",q)
        
    } */

    //const timerID = setInterval( () => selectAsset(),3000);
    
   
  
  
    const selectAsset = (show = true) => {
        let params = ''
        if(startDate){
            params += '&startdate='+startDate
        }
        if(endDate){
            params += '&enddate='+endDate
        }
      //  if(!showDetail) {
          // setLoading(true);
            api.get('/api/position/fetch?asset=' + asset.iot_device_id + params)
           // ApiService.fetchAssetPosition(asset.iot_device_id)
           
           
            .then(res => {
                if(res.data.success) {
                    console.log('this fetch')
                   // toJson(res.data.positions);
                    if(res.data.positions.length > 0){
                        setLat(res.data.positions[0].at(0));
                        setLng(res.data.positions[0].at(1));
                        setDate(res.data.positions[0].at(2));
                       
                    }
                    setVar(res.data.positions.length);
                        setZoom(16);
                        setDataLoaded(true);
                        setData(res.data.positions);
                        setJsonData(res.data.positions);
                        setDataStatic(res.data.positions)

                        setKML(res.data.kml)

                        if(startDate || endDate){
                            selectVehicule(res.data.positions, res.data.kml)
                        }

                    //selectVehicule(res.data.positions)
                
                }
              //  setLoading(false);
            })
       // }            

        //setShowDetail(!showDetail)
    }

    /* function refreshPage() {
        window.location.reload(false);
      } */

    const stopAlarm = () => {

        setLoading(true);

        ApiService.stopAlarm(asset.iot_device_id)
        .then(res => {
            if(res.data.success) {
                reload();
            }
            setLoading(false);
        })
    }
    
    const useForceUpdate = () => useState()[1];

    const forceUpdate = useForceUpdate();

   
    const changeCenter = (d,i,j) => {
        //still not working for some reason
        //console.log("print d", d.slice(0,2));
        setLoading(true);
        setLat(d.at(0));
        setLng(d.at(1));
        setZoom(16);
        setLoading(false);
        forceUpdate();
        setVar(i);
        setDate(j);
        
    }

    const changeCenterHandler = changeCenter.bind(this);
    //const timerID = changeCenter.bind(this);
 //this.timerID = setInterval( () => this.loadDevices(),3000);
    //const stepsDone = Object.keys(asset.asset_tests).filter((el) => el !== "clockin" && asset.asset_tests[el].data && asset.asset_tests[el].data.done);

    /*function mapComponent({ handleClick }) {
        return (
            <div className={"col-md-9"} onClick={handleClick}>
                <ul>
                    <div id="mapid">
                        <MapContainer 
                            //center={[31.645231944730778, 2.817366469861445]} 
                            center={[centerLat, centerLng]} 
                            zoom={16} 
                            scrollWheelZoom={false} 
                            style={{
                                height:"600px",
                                width: "1000px",
                                margin: 0,
                                padding: 0
                            }}
                        >
                            {data.map((d, i) => (
                                <Marker position={d.slice(0, 2)}>
                                    <Popup> Position {i+1} : {d[2]}</Popup>
                                </Marker>
                            ))}
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </MapContainer>
                    </div>
                </ul>
            </div>
        );
      }
      
    const MemoizedSubComponent = React.memo(mapComponent);
      
    const onHandleClick = useCallback(() => {
        // this will return the same function
        // instance between re-renders
        //setLat(d.at(0));
        //setLng(d.at(1));
    });*/

    //console.log("hello data 0 2", data);
    
    //console.log("hello 222", data[data.length-1]);
    //console.log("check", centerLat, centerLng, zoom);
    const exportXLSX = (event) => {
            const params = buildUriParams(filters);
            

            const action = remote+'/api/position/fetch?export=true&asset='+asset.iot_device_id+params+'&usertoken='+user.token;

            window.location = action;
    }
    const exportKML = (event) => {

        const params = buildUriParams(filters);
        const action = remote+'/api/position/fetch?exportKML=true&asset='+asset.iot_device_id+params+'&usertoken='+user.token;

        window.location = action;
    }
    const formatDate = date => (date ? new Date(date) : undefined);

    const moment = require("moment");

    const updateFilter = (event, type, isDate = false) => {
        let value = "";
        
    
        if (isDate) {
          value = event;
        } else {
          value = event.target ? event.target.value : event.value;
        }

        setFilters({...filters,
            [type]: value})
        
        if(type === 'startdate'){
            //setStartDate(new Date(value)) 
            setStartDate(value)
        }else{
            setEndDate(value)
            //setEndDate( new Date(value))
        }
 
 
    };

    const update = () => {
            // const results = dataStatic.filter(item => {
            //     if(startDate & endDate){
            //         return new Date(item[2]) >=  startDate &&  new Date(item[2]) <=  endDate;
            //     }else if(startDate > 0){
            //         return new Date(item[2]) >=  startDate
            //     }else{
            //         return new Date(item[2]) <= endDate
            //     }
                
            // });
            // setJsonData(results)
            // selectVehicule(results, kml)
            selectVehicule(jsondata, kml)
    }



    return (<React.Fragment>
   
                
                {jsondata &&
                    <div >    
                            <br />
                            <b><h7> historique des positions: </h7></b> <br />
                        
                        <div className='row'>
                                <input  type="text" placeholder="Search"   value={searchTerm} onChange={handleChange}  className='col-md-2 ml-1' /> 
                                <ReactDatePicker
                                    dateFormat={"yyyy-MM-dd"}
                                    placeholderText={"Date début"}
                                    timeFormat={"HH:mm"}
                                    showTimeSelect={false}
                                    //className={"form-control"}
                                    selected={formatDate(startDate)}
                                    onChange={e =>
                                        updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "startdate", true)
                                    }
                                    selectsStart
                                    startDate={formatDate(startDate)}
                                    endDate={formatDate(endDate)}
                                    wrapperClassName={"col-md-3"}
                                    className='col-md-12'
                                    />
                            
                        
                                <ReactDatePicker
                                    timeFormat={"HH:mm"}
                                    dateFormat={"yyyy-MM-dd"}
                                    placeholderText={"Date fin"}
                                    showTimeSelect={false}
                                   // className={"form-control"}
                                    selected={formatDate(endDate)}
                                    onChange={e => updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "enddate", true)}
                                    selectsEnd
                                    startDate={formatDate(startDate)}
                                    endDate={formatDate(endDate)}
                                    minDate={formatDate(startDate)}
                                    wrapperClassName={"col-md-3"}
                                    className='col-md-12'
                                />
                                <div className='col-md-1'>
                                    <button onClick={e => exportXLSX(e)}  className={"btn btn-success  btn-sm"}>CSV </button>
                                </div>

                                <div className='col-md-1'>
                                    <button onClick={e => exportKML(e)}  className={"btn btn-primary  btn-sm"}>KML </button>
                                </div>
                        </div>
                        
                        {/* <div className='row'>
                            <div className='col-md-6'>
                                <input  type="text" placeholder="Search"   value={searchTerm} onChange={handleChange}   /> 
                            </div>
                            
                            
                        </div>
                         */}
                        <div className={"positions_bar"}>
                            <ul> 
                                
                                
                                {jsondata.map((d, i) => (
                                    <div>
                                        <li>
                                            <div onClick={() => {handleMap(d);}}>
                                            <a href='#' className={'curser'} > <b>Position  {jsondata.length - i} : </b></a> {' ' + d[2]} <b> { '   ' + d[3]} km</b>
                                            </div>         
                                        </li> 
                                    </div>
                                ))}
                                </ul>
                        </div>
                </div>}
        

    </React.Fragment>
    )
}
export default Pane;
