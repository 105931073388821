import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

class GuestSideMenu extends Component {

	constructor(props) {
		super(props);


	}

	componentDidMount() {
		
	}

	render() {

		
		return (
			
			<ul className={"sideMenu-list"}>
				<li>
					
					<NavLink  to={"/account"}><Trans i18nKey="label_account" /></NavLink>
					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/login"}><Trans i18nKey="label_signin" /></NavLink></li>
						{/* <li><NavLink exact to={"/register"}><Trans i18nKey="label_joinus" /></NavLink></li> */}
					</ul>
				</li>
				
				
				
			</ul>

		)
	}
}



export default connect(mapStateToProps, null, null, { pure: false})(GuestSideMenu)
/*
<div className="list-group">
					<Link className="list-group-item list-group-item-action" to={"/items"}>Items</Link>
					<Link className="list-group-item list-group-item-action" to={"/packages"}>Packages</Link>
					<Link className="list-group-item list-group-item-action" to={"/roles"}>Roles</Link>
					<Link className="list-group-item list-group-item-action" to={"/security"}>Security</Link>
					<Link className="list-group-item list-group-item-action" to={"/config"}>Configuration</Link>

				</div>
				*/