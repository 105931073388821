import React from "react";

const jQuery = require("jquery");


class Tooltip extends React.Component {


    componentDidMount() {
        console.log(this.props.children)
        console.log(jQuery)
        const El = <div id={"tooltip-c-1"}>{this.props.children}</div>;
        jQuery("body").append(El)
        console.log(El);
    }

    render() {


        

        const fullText = this.props.children;
        let excerpt;
        if(fullText.length > 15) {

            excerpt = fullText.slice(0, 15)+'...';

        } else {
            excerpt = fullText;
        }

        return (<div className={"c-tooltip-wrapper"}>
            <div className={"c-tooltip"} >
            <p>{fullText}</p>
            </div>
            {
            excerpt
        }</div>)
    }
}

export default Tooltip