import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import { startLoading, doneLoading } from '../../Redux/actions/index';
import * as helpers from '../../Utils/Helpers';
import api from '../../Utils/Api';
import { Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading())
	}
}

class AgentNew extends Component {


	constructor(props) {
		super(props);
		this.state = {
			validEmail: true,
			validUsername: true,
			info: {
				subscribe: 0,
				userid: 0,
				username: "",
				password: "",
				firstName: "",
				lastName: "",
				email: "",
				phone: ""
				
			}
		}


		this.handleInput = this.handleInput.bind(this);
		this.formSubmit = this.formSubmit.bind(this);
		this.checkUsername = _.debounce(this.checkUsername.bind(this), 400);
		this.checkEmail = _.debounce(this.checkEmail.bind(this), 400);
		this.changeSub = this.changeSub.bind(this);
	}



	checkUsername() {
		const that = this;
		api.post('/api/user/availability', { username: this.state.info.username})
		.then(function(response) {
			if(response.data.success) {
				that.setState({validUsername: true})
			} else {
				that.setState({validUsername: false})
			}
		})
	}

	checkEmail() {
		const that = this;
		const email = this.state.info.email
		if(!helpers.validateEmail(email))
			return that.setState({validEmail: false})


		api.post('/api/user/availability', {email: email})
		.then(function(response) {
			if(response.data.success) {
				that.setState({validEmail: true})
			} else {
				that.setState({validEmail: false})
			}
		})
	}

	changeSub(e) {
		this.setState({
			info: {
				...this.state.info,
				subscribe: e.value
			}
		})
	}	

	handleInput(e) {


		let s = this.state;
		const event = Object.assign({}, e);
		const that = this;

		s.info[event.target.name] = event.target.value;
		this.setState({...s}, function() {
			if(event.target.name === 'username') {
				that.checkUsername()
			} else if(event.target.name === 'email') {
				that.checkEmail()
			}
		});

		
	}

	
	formSubmit(e) {
		e.preventDefault();

		this.props.isLoading();

		let that = this;

		
		const data = {
			user: this.props.user,
			form: this.state.info
			
		}

		api.post('/api/agent/register', data)	
		.then(function(response) {
			if(response.data.success) {
				that.props.history.push('/manager/agents');
			} else {

				that.props.setFlash({
					active: true,
					message: response.data.error,
					classes: "warning",
					style: {}
				});

			}
			
			that.props.doneLoading();

		}).catch(erro => console.log(erro))


	}

	componentDidMount() {
		
	}

	render() {

		
		return(
			<div>

				<div className={"container-header"}>
					<Trans i18nKey="label_new_agent" />
				</div>

				<form method="#" onSubmit={this.formSubmit}>

				<div className={"row"}>
				<div className={"col-md-5"}>

				<h4 className={"mb-4"}><Trans i18nKey="account_information" /></h4>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_username" /></label>
					<input 
					required
					type="text" 
					className={"form-control" + (!this.state.validUsername ? ' invalid' : '')} 
					name="username" 
					value={this.state.info.username} 
					onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_password" /></label>
					<input required type="password" className={"form-control"} name="password" value={this.state.info.password} onChange={this.handleInput}/>
				</div>

				
				<h4 className={"mb-4"}><Trans i18nKey="personal_information" /></h4>



				<div className={"form-group"}>
					<label><Trans i18nKey="user_phonenumber" /></label>

					<div class="input-group">
						<input required type="text" className={"form-control"} name="phone" value={this.state.info.phone} onChange={this.handleInput} />
					</div>
					
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_firstname" /></label>
					<input type="text" className={"form-control"} name="firstName" value={this.state.info.firstName} onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_lastname" /></label>
					<input type="text" className={"form-control"} name="lastName" value={this.state.info.lastName} onChange={this.handleInput} />
				</div>


  				<div className={"mt-5"}>
				  <button
					disabled={! (this.state.validEmail && this.state.validUsername)} 
				 	className={"cbtn float-right col-md-6"}>
  				<Trans i18nKey="button_create" />
  				</button>
  				</div>


  				</div>
  				


  				</div>

  				</form>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentNew)