import React, { Component, useEffect } from "react";
//import { Link } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { useTranslation, Trans } from "react-i18next";
import PositionsTableRow from './PositionsTableRow';
import ReactDatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,faAngleRight,faPlus,faHistory,faCheckSquare,faChevronCircleDown,faTimes,faCheck,faMinus,faFile,faExclamation,faUndo, faCircleNotch,faList,faEdit,faFileArchive, faFileExport, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import api from "../../Utils/Api";
import NewPatrolVehicule from '../../Components/Task/components/NewPatrolVehicule';

import { buildUriParams } from "../../Utils/Helpers";
//import moment from "moment";
//import MoreInfoDialog, { Item } from "../../Utils/MoreInfoDialog";

//import html2canvas from "html2canvas";

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: e => dispatch(startLoading(e)),
  doneLoading: e => dispatch(doneLoading()),
  setAuth: e => dispatch(setAuthAction(e))
}); 
const remoteAPI = "https://app.netfer.tictechs.tech/";
const remote =  process.env.REACT_APP_BACKEND_URL
const moment = require("moment");

class PositionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPatrolView: false,
      agents: [],
      rows: [],
      showOverlay: false,
      taskid: "",
      agentid: "",
      selected_pm: "",
      activeTest: "igeneral",
      showDeleteOverlay: false,
      showDeleteOverlayConfirmation: false,
      loadingManholeCheck: false,
      summaryContent: {},
      showSummaryOverlay: false,
      manholeCheck: null,
      total: 0,
      selectOptions :[],
			tracker_id: "",
			carname: "",
			dataC: {},
			dataA: {},
			assets:[],
			assetsR:[],
			selected: false, 
      filters: {}
      
    };

    

	   this.timerID = null;
     this.task_description="";//cerine
     this.task_remarque="";//cerine
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  //Set data for selectlist 
  componentWillReceiveProps(nextProps) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (nextProps) {
		  //this.setState({ dataC: nextProps.data });
		  this.setState({ dataC: nextProps.data,
			  			dataA: nextProps.data,
						  assets: nextProps.data.assets,
						  assetsR: nextProps.data.assets,
              
				 });
		  //console.log("imene",nextProps.data)
		}
		console.log("imene C",this.state.dataC.assets);
	  }

    getOptions(){
      let that = this;
      api.get(`/api/carlist/fetch`).then(function(response) {
        if (response.data.success) {
          const options = response.data.cars.map(d => ({
            "value" : d.tracker,
            "label" : d.car_id+" : "+d.matricule,
            "label1" : d.matricule    
            })) 
          that.setState({
            selectOptions:options
            });
        }
      });
    }

    handleChange(e){
      const newD = this.state.dataA;
      const dataFiltered = newD.assets.filter(obj => obj.site.includes(e.label1))
      this.setState(
        {
          dataC:{
            ...this.state.dataC,
            assets:dataFiltered
          }
        }
      )
      this.setState({tracker_id:e.value, 
              carname:e.label1,
              selected: true,
                  
      })
    }




  toggleOverlay(e) {
    this.setState({ showOverlay: !this.state.showOverlay });
  }

  selectTest = (e, test) => {
    e.preventDefault();
    this.setState({
      activeTest: test
    });
  };

  deleteTask = e => {
    const pmid = e.target.getAttribute("data-task-id");

    this.setState({
      selected_pm: pmid,
      showDeleteOverlay: true,
      deleteSteps: 1
    });
  };

  nextDeleteStep = () => {
    this.setState({
      showDeleteOverlay: false,
      showDeleteOverlayConfirmation: true,
      deleteSteps: 2
    });
  };

  confirmDelete = () => {
    this.props.isLoading();

    this.setState({
      showDeleteOverlayConfirmation: false
    });

    const data = {
      taskid: this.state.selected_pm
    };

    api.post("/api/task/delete", data).then(response => {
      this.props.handlers.loadTasks();
    });
  };

  exportPM = (pm, imageData) => {

    
    this.pmExportForm.action =

      "https://app.netfer.tictechs.tech/api/patrol/export/" + pm.taskid + "?token=" + this.props.user.token;
      //Saaaaam
      // "http://localhost/NETFER/back/public/api/patrol/export/" + pm.taskid + "?token=" + this.props.user.token;

      this.imageData.value = imageData;
    // const newInput = document.createElement("input", {
    // 	name: "pm",
    // 	value: JSON.stringify(pm)
    // });
    // console.log(newInput);
    //this.pmExportForm.appendChild(newInput);
    //this.pmInput.value = JSON.stringify(pm)
    this.props.isLoading();
    this.pmExportForm.submit();

    this.props.doneLoading();

    const data = {
      pm: pm
    };

    // api.post('/api/pm/export/igeneral/'+pm.task_id, data)
    // .then(function(response) {
    // 	console.log(response.data)
    // })
  };

  selectManhole = (e, manhole) => {
    this.setState({
      activeManhole: manhole.manhole_id ? manhole.manhole_id : manhole.bridge_id,
      manholeCheck: manhole,
      loadingManholeCheck: false
    });

    return;

    // api.get('/api/manhole/check/fetch/'+check_id)
    // .then(response => {
    // 	this.setState({
    // 		manholeCheck: response.data.data,
    // 		loadingManholeCheck: false
    // 	});

    // 	console.log(response);
    // })
  };

  denyCheck = (type, id) => {
    this.setState({ showDenyOverlay: true, selectedType: type });
    console.log(id);
  };

  confirmDeny = () => {
    this.props.isLoading({
      message: "Returning Check to technician",
      animate: true
    });

    const data = {
      type: this.state.selectedType,
      id: this.state.selectedType === "bridge" ? this.state.manholeCheck.check_id : this.state.manholeCheck.check_id
    };

    this.setState({ showDenyOverlay: false });

    api.post("/api/check/return", data).then(response => {
      console.log(response.data);

      this.props.doneLoading();
    });
  };

  showSummary = summary => {
    this.setState({
      showSummaryOverlay: true,
      summaryContent: summary
    });
  };

  toggleHidden = (x, task) => {
    this.setState({
      manholeCheck: {}
    });
    console.log(task.task_result.photos[0])
    this.props.toggleHidden(x, task);
  };

  updateCheckStatus = (sectionIndex, checkIndex, newStatus, checkType = "manhole") => {
    const { manholeCheck } = this.state;

    manholeCheck.checks[sectionIndex].checks[checkIndex].test_status = newStatus;

    this.setState({
      manholeCheck: manholeCheck
    });

    const data = {
      check_id: manholeCheck.check_id,
      checks: manholeCheck.checks
    };

    api.post("/api/" + checkType + "/check/edit", data).then(res => {
      console.log("Check status updated");
    });
  };

  componentDidMount() {
   
    //this.timerID = setInterval(this.props.handlers.loadTasks, 30000);
    this.timerID = setInterval(this.props.handlers.loadTasks, 60000);
		this.getOptions() ;
   

  }
  togglePatrolTask = (e) => {
    console.log('toggle')
		this.setState({ openPatrolView:  !this.state.openPatrolView  })
	}	
   componentWillUnmount() {
    clearInterval(this.timerID);
  } 
 
  exportXLSX = (event) => {
    const params = buildUriParams(this.state.filters);
    const action = remote+'/api/iot/cars/fetch?export=true&asset='+11+params+'&usertoken='+this.props.user.token;

    window.location = action;
  }
  exportKML = (event) => {

    const params = buildUriParams(this.state.filters);

    const action = remote+'/api/iot/cars/fetch?exportKML=true&asset='+11+params+'&usertoken='+this.props.user.token;

    window.location = action;
  }
  formatDate = date => (date ? new Date(date) : undefined);

  updateFilter = (event, type, isDate = false) => {
    let value = "";
    

    if (isDate) {
      value = event;
    } else {
      value = event.target ? event.target.value : event.value;
    }

    console.log('log' + value)
    this.setState({
      filters: {
        ...this.state.filters,
        [type]: value
      }  
    })
  };
  
  render() {
    //const { t, i18n } = useTranslation();
    
    const data = this.props.data;
    const rows = this.props.rows;
    //const agents = this.props.agents;
    const toggleHidden = this.toggleHidden;
    this.getOptions = this.getOptions.bind(this);
    const {filters} = this.state;
    return (
      <React.Fragment>

       {/* <div className={"ui-rect ns"} style={{width: "100%"}}>
         <div>
          <div>

            {this.props.user.role !== 'tasks'  && <button onClick={this.togglePatrolTask} className={"btn btn-primary btn-sm float-right"}>+ </button>}
            <button onClick={e => this.exportKML(e)}  className={"btn btn-primary  btn-sm float-right mr-2"}>KML </button> 
            <button onClick={e => this.exportXLSX(e)}  className={"btn btn-success  btn-sm float-right mr-2"}>Export </button>  

            <ReactDatePicker
              timeFormat={"HH:mm"}
              dateFormat={"yyyy-MM-dd"}
              placeholderText={"Date fin"}
              showTimeSelect={false}
              selected={this.formatDate(filters.endDate)}
              onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "endDate", true)}
              selectsEnd
              startDate={this.formatDate(filters.startDate)}
              endDate={this.formatDate(filters.endDate)}
              minDate={this.formatDate(filters.startDate)}
              wrapperClassName={"col-md-3  float-right"}
              className='col-md-12'
            /> 

            <ReactDatePicker
              dateFormat={"yyyy-MM-dd"}
              placeholderText={"Date début"}
              timeFormat={"HH:mm"}
              showTimeSelect={false}
              selected={this.formatDate(filters.startDate)}
              onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "startDate", true)}
              selectsStart
              startDate={this.formatDate(filters.startDate)}
              endDate={this.formatDate(filters.endDate)}
              wrapperClassName={"col-md-3 float-right "}
              className='col-md-12 form-control'
              //className={"form-control"}
              />

            <Select  maxMenuWidth={190}  	placeholder="Matricule" options={this.state.selectOptions} onChange={this.handleChange.bind(this)} />         
          </div> 
          <NewPatrolVehicule
            toggleView={e => this.togglePatrolTask(false)}
            agents={this.state.agents}
            open={this.state.openPatrolView}
            loadTasks={this.props.handlers.loadTasks}
            //loadCars={this.loadCars}
            loadCars={this.getOptions}
          />
        </div>
 
				<div className={"row "}>
					<div className={"form-select col-md-12  form-select-sm align-right"}>
						<Select classNamePrefix={"custom-select"} 	placeholder="Matricule" options={this.state.selectOptions} onChange={this.handleChange.bind(this)} />
					</div>			
				</div>
				 
      </div> */}

          <NewPatrolVehicule
            toggleView={e => this.togglePatrolTask(false)}
            agents={this.state.agents}
            open={this.state.openPatrolView}
            loadTasks={this.props.handlers.loadTasks}
            //loadCars={this.loadCars}
            loadCars={this.getOptions}
          />

      <div className={"ui-rect ns"}>
        <div className="col-12">
            <div className="row">
              <div className="col-md-3 form-group">
              <Select   
                  //className={'datePicker'}  
                 /// wrapperClassName="datePicker" 
                  classNamePrefix={"selectcust"}  
                  //maxMenuWidth={120}  	
                  placeholder="Matricule" 
                  options={this.state.selectOptions} 
                  onChange={this.handleChange.bind(this)} />         
              </div>
              <div className="col-md-3 ">
                <ReactDatePicker
                  dateFormat={"yyyy-MM-dd"}
                  placeholderText={"Date début"}
                  timeFormat={"HH:mm"}
                  showTimeSelect={false}
                  selected={this.formatDate(filters.startDate)}
                  onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "startDate", true)}
                  selectsStart
                  startDate={this.formatDate(filters.startDate)}
                  endDate={this.formatDate(filters.endDate)}
                  className='col-md-12 datePicker'
                  //classNamePrefix={"custom-select"}
                  wrapperClassName="datePicker"
                  
                  //className={"form-control"}
                  />
              </div>
              <div className="col-md-3">
              <ReactDatePicker
                  timeFormat={"HH:mm"}
                  dateFormat={"yyyy-MM-dd"}
                  placeholderText={"Date fin"}
                  showTimeSelect={false}
                  selected={this.formatDate(filters.endDate)}
                  onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "endDate", true)}
                  selectsEnd
                  startDate={this.formatDate(filters.startDate)}
                  endDate={this.formatDate(filters.endDate)}
                  minDate={this.formatDate(filters.startDate)}
                  // classNamePrefix={"custom-select"}
                  className='col-md-12 datePicker'
                  wrapperClassName="datePicker"
                /> 
              </div>
              <div className="col-md-3  text-right ">
                
                <button onClick={e => this.exportXLSX(e)}  className={"btn btn-success  btn-sm "}>
                  <FontAwesomeIcon icon={faFileExcel} />  
                </button>
                <button onClick={e => this.exportKML(e)}  className={"btn btn-primary  btn-sm  ml-1"}>
                  <FontAwesomeIcon icon={faFileExport} />   
                </button>  
                
                {this.props.user.role !== 'tasks'  && <button onClick={this.togglePatrolTask} className={"btn btn-primary btn-sm ml-1"}>+ </button>}
              </div>
             
            </div>
          </div>
      </div>
      <br></br>
      <div className={"ui-rect ns"} style={{width: "98%"}}>
           
          <table className={"table table-sm clickable "}  style={{width: "99%"}}>
              <thead>
                <th className={"col-sm-1"}>ID</th>
                <th className={"col-sm-1"}>Vehicule</th>
                <th className={"col-sm-1"}>Type</th>
                <th className={"col-sm-1"}>Time</th>
                <th className={"col-sm-1"}>Action</th>
                
                
              </thead>

              {this.state.dataC.assets && this.state.dataC.assets.map((asset, i) => 		
                <PositionsTableRow 
                  key={asset.key}
                  exportPM={this.exportPM}
                  asset={asset}
                  handlers={this.props.handlers}
                  deleteTask={this.deleteTask}
                  returnTask={this.returnTask}
                  //reloadCars={this.getOptions}
                  reload={this.props.handlers.loadTasks}
                  refresh={this.props.handlers.handler}
                  PaneSet = {this.props.handlers.PaneSet}
                />
              
              )}  

                  {data.assets.length === 0 && <div className={"col-md-12 text-center my-3"}>Aucun vehicule trouvé</div>}
        
        </ table>
        <br></br>
      </div>
        
      </React.Fragment>
    );
  }
}

export default connect(
     mapStateToProps,
     mapDispatchToProps
   )(PositionsTable);

