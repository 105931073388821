import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import LinkListContainer from '../Containers/LinkListContainer';
import LinkNew from '../Components/Link/LinkNew';
import ManholeNew from '../Components/Manhole/ManholeNew';



function mapStateToProps(state) {
  return { auth: state.auth };
};


class ManholeRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
						<Route exact path='/manhole/new' component={ManholeNew} />
                       
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(ManholeRouter)