import React, { Component } from 'react';
import _ from 'lodash';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AddressList from '../Components/Addresses/AddressList'
import {setAuthAction, startLoading, doneLoading} from '../Redux/actions/index';
import { Trans } from "react-i18next";
import Select from 'react-select';
import SocketContext from '../Utils/socket-context';
import api from '../Utils/Api';
import { apiURL } from '../Utils/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import LinkList from '../Components/Link/LinkList';
import Pagination from '../Utils/Pagination';


//const remote = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'http://localhost/djezzy/api/public/';
//const remote = api;

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user, 
		taskList: state.taskList
	}
}

function mapDispatchToProps(dispatch) {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e))
	}
}

class LinkListContainer extends Component {
	
	constructor(props) {
		super(props);
		
		this.props.isLoading({
			message:"Chargement des links",
			animate:true
		});

		this.state = {
			showFilters: false,
			planning_ready: false,
			wilayas: [],
			managers: [],
			links: [],
			agents: [],
			status: [],
			types: [],
			rows: [],
			current: 1,
			pages: 1,
			limit: 10,
			pm_start: "",
			pm_end: "",
			filters: {
				agent: "",
				link: "",
				wilaya: "",
				region: "",
				site_code: "",
				site_wilaya: "",
				site_manager: "",
				type_ac: "",
				marque_ac: "",
				marque_ps: "",
				marque_batterie: "",
				power_type: "",
				bss_equip: "",
				antenne: "",
				type: "",
				typology: "",
				pmdone: false,
				assigned: false,
				link_owner: ""
			},
			power_type: [],
			marque_ac: [],
			bss_equip: [],
			marque_battery: [],
			showOverlay: true
		}

		
		
		this.loadLinks = this.loadLinks.bind(this);
		this.loadLinks =_.debounce(this.loadLinks, 300)
		this.toggleHidden = this.toggleHidden.bind(this);
		this.updateFilter = this.updateFilter.bind(this);

		this.handlers = {
			selectPage: this.selectPage.bind(this),
			loadLinks: this.loadLinks
		}
		
		this.changeLimit = this.changeLimit.bind(this);

	}

	changeLimit(e) {
		this.props.isLoading();
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadLinks())


	}



	changeDate = (name, event) => {

		const that = this;

		this.setState({
			["pm_"+name]: event.target.value,
		}, function() {
		
			that.props.isLoading({
				message: "Mise à jours de la liste",
				animated: true
			});
			that.loadLinks(1);

		})

	}

	updateFilter(event, name) {
		
		let that = this;
		const value = event.target ? event.target.value : event.value;

		console.log(value)

		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			that.props.isLoading();
			that.loadLinks(1);
		}); 


		//window.ga('send', 'event', 'task', 'filter'); // Google Analytics


		

	}


	exportResult = (e) => {

		//e.preventDefault();
		//return;
		console.log('export remote => ' + apiURL)

		const crits = "limit=-1"
					  +"&pmdone="+this.state.filters.pmdone
					  +"&assigned="+this.state.filters.assigned
					  +"&pm_start="+this.state.pm_start
					  +"&pm_end="+this.state.pm_end
					  +"&wilaya="+this.state.filters.wilaya
					  +"&agent="+this.state.filters.agent
					  +"&region="+this.state.filters.region
					  +"&link_owner="+this.state.filters.link_owner
					  +"&link="+this.state.filters.link ;

		const action = apiURL+'/api/link/fetch?usertoken='+this.props.user.token+'&export&'+crits;

		window.location = action;

		
		//e.target.submit();
		
	}



	toggleHidden(e, task) {

		
		
		
		const i = e.target.getAttribute('data-row-index');

		let nstate = this.state;

		nstate.rows[i] && _.set(nstate, 'rows['+i+'].hidden', !nstate.rows[i].hidden);

		

		this.setState(nstate);
	
	}

	componentDidMount() {



		let that = this;


		if((this.props.user && this.props.user.role !== 'agent'))  {

			api.get('/api/task/form?token='+this.props.user.token)
			.then(function(response) {
				if(response.data.success) {

					that.setState({
						agents: [{label: 'All', value:""}, ...response.data.agents], 
						wilayas: [{label: 'All', value:""}, ...response.data.wilayas] 
					})
		
					if(response.data.managers) {
						that.setState({
							managers: [{label: 'All', value:""}, ...response.data.managers]
						})
					}
				}
			});
			
		}
		
		
		this.loadLinks();
		
	}



	loadLinks(p = this.state.current) {

		let nstate = this.state;
		let that = this;

		const crits = "limit="+this.state.limit
					  +"&pmdone="+this.state.filters.pmdone
					  +"&assigned="+this.state.filters.assigned
					  +"&pm_start="+this.state.pm_start
					  +"&pm_end="+this.state.pm_end
					  +"&wilaya="+this.state.filters.wilaya
					  +"&agent="+this.state.filters.agent
					  +"&region="+this.state.filters.region
					  +"&link_owner="+this.state.filters.link_owner
					  +"&link="+this.state.filters.link ;



		api.get('/api/link/fetch?page='+p+'&'+crits)
		.then(function(response) {
			if(response.data.success) {

				response.data.links.map(r =>  {
					nstate.rows.push({"hidden": true})
				});

				console.log(response.data.links);

				that.setState({
					links: response.data.links, 
					pages: response.data.pages, 
					rows: nstate.rows,
					total: response.data.total
				})

			} else {
				if(response.data.errcode === 101) {
					that.props.setAuth(false);
				}
			}

			that.props.doneLoading();

		});
	}


	


	selectPage(p) {
		this.props.isLoading();

		this.setState({current: p});

		this.loadLinks(p);
	}

	importClick = () => {
		//e.preventDefault();

		this.toggleOverlay();

		if(this.state.planning_ready) {
			this.fileFormRef.submit();
		} else {
			document.querySelector("#linksfile").click();
		}


	}

	handelFileChange = (e) => {
		if(e.target.value) {
			this.setState({ planning_ready: true })
		} else {
			this.setState({ planning_ready: false })
		}
		console.log(e.target)
	}

	resetFileForm = () => {
		this.setState({ planning_ready: false });
		this.fileFormRef.reset();
	}

	toggleOverlay = (e) => this.setState({showOverlay: !this.state.showOverlay});
	
	toggleFilters = (e) => {
		e.preventDefault();
		this.setState({
			showFilters: ! this.state.showFilters 
		})
	}

	render() {
		
		return(
			
			<React.Fragment>
			
			<div className={"container-header"}>

				Links

				{!this.props.auth ? <Redirect to="/login" /> :  ""}

				{this.props.user && (this.props.user.role !== 'agent') ? (
					<React.Fragment>
				
						<Link to={"/link/new"}  >
							<button className={"cbtn float-right mr-2"}>New Link</button>
						</Link>
						<button onClick={e => this.exportResult()}  className={"cbtn float-right mr-2"}>Export </button>
						{/* <button onClick={e => this.importClick(e)}  className={"btn btn-primary float-right mr-2"}>import planning </button> */}
				
					</React.Fragment>
					) : ""}

				
			</div>
			
			<div className={"ui-rect np"}>
				<div className={"row form"}>
					<div className={"form-group col-md-3"}>
						<label>Owner</label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "Tous", value: ""},
							{label: "Ooredoo", value: "ooredoo"},
							{label: "Djezzy", value: "djezzy"}
						]}
						placeholder="Link Owner"
						onChange={e => this.updateFilter(e, 'link_owner')}
						/>
					</div>
				{/*</div>

				<div className={"row form"}>*/}
					
					<div className={"form-group col-md-3"}>
						<label>Link</label>
						<input
						name="link"
						className={"form-control"}
						placeholder="Nom ou code link"
						value={this.state.filters.link}
						onChange={e => this.updateFilter(e, 'link')}
						/>
					</div>

					<div className={"col-md-3"}>
						<label>Wilaya</label>
						<Select
						classNamePrefix={"custom-select"}
						options={this.state.wilayas}
						placeholder="Wilaya"
						onChange={e => this.updateFilter(e, 'wilaya')}
						/>
					</div>


					<div className={"col-md-3"}>
						<label>Région</label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "All", value: ""},
							{label: "Ouest", value: "west"},
							{label: "Est", value: "east"},
							{label: "Centre", value: "middle"},
							{label: "Sud", value: "south"}
						]}
						placeholder="Région"
						onChange={e => this.updateFilter(e, 'region')}
						/>
					</div>



					
					

				</div>

			</div>

			<div className={"row ui-rect my-4"}>
				<div className={"col-md-6 items-found"}>

					
				</div>
			
				<div className={"col-md-6 per-page-wrap"}>
					<div className={"per-page-content"}>
						<p>Résultat par page</p> 
						<Select
							classNamePrefix={"custom-select"}
							onChange={this.changeLimit}
							className={"per-page nm"}
							options={[
								{label: "10", value: 10},
								{label: "15", value: 15},
								{label: "30", value: 30}]}
							value={{label: this.state.limit, value: this.state.limit}}
						/>
					</div>
					<Pagination
						data={this.state}
						handleClick={this.selectPage} 
					/>
					
				</div>

			</div>

			<div className={"ui-rect np"}>
				<LinkList
					data={this.state}
					rows={this.state.rows}
					toggleHidden={this.toggleHidden}
					handlers={this.handlers}
				/>
			</div>

			
			</React.Fragment>)


	}
}


const LinkListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <LinkListContainer {...props} socket={socket} />}
	</SocketContext.Consumer>
  )

export default connect(mapStateToProps, mapDispatchToProps)(LinkListConsumer)