import React from "react";
import api from "../../Utils/Api";
import _ from "lodash"
class GeoInput extends React.Component {


    constructor(props) {
        super(props);

        this.geocoder = null;
        this.map = null;

        const that = this;
        // this.searchGeo = _.debounce(function(e) {
        //     return that.searchGeo(e)
        // } );

        this.searchGeo = this.searchGeo.bind(this);
        this.selectSuggetion = this.selectSuggetion.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
        this.inputFocus = this.inputFocus.bind(this);
        this.clearGeo = this.clearGeo.bind(this)

        this.apiSearch = _.debounce((value) =>
            api.get("https://nominatim.openstreetmap.org/search/?format=json&q="+value)
            .then(function(response) {
                if(response.data.length > 0) {
                    that.setState({
                        status: "suggest",
                        suggestions: response.data
                    })
                
                } else {
                    that.setState({
                        status: "suggest",
                        suggestions: []
                    })
                }
            })

        , 200)

        this.state = {
            geo_lat: null,
            geo_lng: null,
            status: "idle",
            addrValue: "",
            geoInput: "",
            use_value: "geo",
            suggestions: []
        }
    }



    componentDidMount() {

		  
    }

    inputFocus(e) {
        
        this.setState({
            status: "focused"
        })
    }

    inputBlur(e) {
        
        
        this.setState({
            status: "idle"
        })
    }

    searchGeo(e) {

        const value = e.target.value;

        this.setState({
            geoInput: value,
            status: "search",
            suggestions: []
        })


        if(value.length > 0) {
            this.apiSearch.cancel();
            
            this.apiSearch(value);
        } else {
            this.setState({
                status: "idle",
                suggestions: []
            })
        }

    }

    selectSuggetion(suggestion) {
        

        if(typeof this.props.onGeoSelect === 'function')
            this.props.onGeoSelect(suggestion);

        this.setState({
            suggestions: [],
            geo_lat: suggestion.lat,
            geo_lng: suggestion.lon,
            geoInput: suggestion.display_name
            
        })

        

    }


    clearGeo() {
        this.selectSuggetion({
            lat: null,
            lon: null,
            display_name: ""
        })
        
    }
    componentDidUpdate(prev) {

        if(prev.inputValue !== this.props.inputValue) {
           this.setState({
               geoInput: this.props.inputValue
           })
        }


    }

    render() {
        
        return (
            <React.Fragment>
                <div onBlur={this.inputBlur} className={"geo-wrapper"} style={{position:"relative"}}>
                    
                    <input 
                    {...this.props} 
                    value={this.state.geoInput} 
                    onChange={this.searchGeo}
                    onFocus={this.inputFocus}
                    autoComplete={"off"}
                    />  

                    {this.state.geoInput.length > 0 && (

                    <span onClick={this.clearGeo} className={"clear-geo-input"}>x</span>
                    )}

                    <input
                    name="geo_lat"
                    value={this.state.geo_lat}
                    type="hidden"
                    />

                    <input
                    name="geo_lng"
                    value={this.state.geo_lng}
                    type="hidden"
                    />


                    {this.state.suggestions.length > 0 ? 
                    
                    (

                    <div className={"geo-suggestions-wrapper" + (this.state.status !== "idle" ? " active" : "")}>
                        <ul>
                            {this.state.suggestions.map(suggestion => (
                                <li onClick={e => this.selectSuggetion(suggestion)}>{suggestion.display_name}</li>
                            ))}
                        </ul>
                    </div>
                    ) : 
                    this.state.geoInput.length > 0
                        ? (<div className={"geo-suggestions-wrapper" + (this.state.status !== "idle" ? " active" : "")}>
                                <ul>
                                    {this.state.status === 'search'
                                    ? (<li>Searching...</li>)
                                    : (<li>No suggestions found</li>)}
                                </ul>
                            </div>)
                        : ("")}
                </div>

            </React.Fragment>
        )
    }
}


export default GeoInput;