export default [
    { LOAD: 33, SPEED: 2, status: 1, t: "180927072508000", course: 0, lat: 53.22376666666667, lng: 50.745841666666664 },
    { LOAD: 33, SPEED: 2, status: 1, t: "180927072508000", course: 0, lat: 53.22376666666667, lng: 50.745841666666664 },
    { LOAD: 30, SPEED: 2.3, status: 1, t: "180927072523000", course: 114, lat: 53.223728333333334, lng: 50.74598666666667 },
    { LOAD: 30, SPEED: 2.3, status: 1, t: "180927072529000", course: 138, lat: 53.223705, lng: 50.746021666666664 },
    { LOAD: 34, SPEED: 5.7, status: 1, t: "180927072538000", course: 149, lat: 53.22365166666667, lng: 50.746075 },
    { LOAD: 34, SPEED: 5.7, status: 1, t: "180927072538000", course: 149, lat: 53.22365166666667, lng: 50.746075 },
    { LOAD: 45, SPEED: 5.8, status: 1, t: "180927072554000", course: 111, lat: 53.22358333333333, lng: 50.74638 },
    { LOAD: 51, SPEED: 5.5, status: 1, t: "180927072608000", course: 15, lat: 53.2238, lng: 50.746478333333336 },
    { LOAD: 24, SPEED: 2, status: 1, t: "180927072623000", course: 245, lat: 53.22374333333333, lng: 50.74627666666667 },
    { LOAD: 22, SPEED: 0, status: 1, t: "180927072638000", course: 165, lat: 53.223725, lng: 50.746285 },
    { LOAD: 19, SPEED: 0, status: 1, t: "180927074023000", course: 0, lat: 53.22374, lng: 50.746285 },
    { LOAD: 27, SPEED: 1, status: 1, t: "180927074023000", course: 0, lat: 53.22374, lng: 50.746285 },
    { LOAD: 35, SPEED: 6.4, status: 1, t: "180927074038000", course: 160, lat: 53.22356833333333, lng: 50.746386666666666 },
    { LOAD: 35, SPEED: 6.4, status: 1, t: "180927074038000", course: 160, lat: 53.22356833333333, lng: 50.746386666666666 },
    { LOAD: 35, SPEED: 6.4, status: 1, t: "180927074050000", course: 270, lat: 53.22356833333333, lng: 50.74625 },
    { LOAD: 19, SPEED: 0, status: 1, t: "180927074053000", course: 0, lat: 53.22358333333333, lng: 50.74625 },
    { LOAD: 23, SPEED: 1.6, status: 1, t: "180927075308000", course: 188, lat: 53.22356833333333, lng: 50.746246666666664 },
    { LOAD: 34, SPEED: 5.6, status: 1, t: "180927075323000", course: 301, lat: 53.223618333333334, lng: 50.746105 },
    { LOAD: 34, SPEED: 5.6, status: 1, t: "180927075329000", course: 352, lat: 53.223681666666664, lng: 50.74609 },
    { LOAD: 21, SPEED: 0, status: 1, t: "180927075338000", course: 134, lat: 53.223675, lng: 50.74610166666667 },
    { LOAD: 62, SPEED: 7.9, status: 1, t: "180927080939000", course: 22, lat: 53.22372, lng: 50.74613166666666 },
    { LOAD: 62, SPEED: 7.9, status: 1, t: "180927080939000", course: 22, lat: 53.22372, lng: 50.74613166666666 },
    { LOAD: 62, SPEED: 7.9, status: 1, t: "180927080939000", course: 22, lat: 53.22372, lng: 50.74613166666666 },
    { LOAD: 78, SPEED: 15.6, status: 1, t: "180927080953000", course: 171, lat: 53.223391666666664, lng: 50.746215 },
    { LOAD: 43, SPEED: 15.8, status: 1, t: "180927081009000", course: 162, lat: 53.22277, lng: 50.746543333333335 },
    { LOAD: 41, SPEED: 15.8, status: 1, t: "180927081023000", course: 154, lat: 53.22221833333333, lng: 50.746993333333336 },
    { LOAD: 45, SPEED: 15.5, status: 1, t: "180927081038000", course: 141, lat: 53.22172166666667, lng: 50.747665 },
    { LOAD: 45, SPEED: 15.5, status: 1, t: "180927081038000", course: 141, lat: 53.22172166666667, lng: 50.747665 },
    { LOAD: 45, SPEED: 15.5, status: 1, t: "180927081050000", course: 138, lat: 53.221408333333336, lng: 50.74813 },
    { LOAD: 42, SPEED: 16.5, status: 1, t: "180927081053000", course: 139, lat: 53.221245, lng: 50.74836666666667 },
    { LOAD: 39, SPEED: 16.6, status: 1, t: "180927081108000", course: 146, lat: 53.220706666666665, lng: 50.74897 },
    { LOAD: 39, SPEED: 16.6, status: 1, t: "180927081116000", course: 144, lat: 53.220531666666666, lng: 50.74918 },
    { LOAD: 57, SPEED: 15.2, status: 1, t: "180927081123000", course: 145, lat: 53.22033666666667, lng: 50.749408333333335 },
    { LOAD: 49, SPEED: 17, status: 1, t: "180927081138000", course: 140, lat: 53.21983, lng: 50.75011833333333 },
    { LOAD: 57, SPEED: 15.2, status: 1, t: "180927081138000", course: 140, lat: 53.21983, lng: 50.75011833333333 },
    { LOAD: 52, SPEED: 16.2, status: 1, t: "180927081153000", course: 134, lat: 53.219375, lng: 50.75090333333333 },
    { LOAD: 50, SPEED: 17.4, status: 1, t: "180927081208000", course: 131, lat: 53.21893333333333, lng: 50.751751666666664 },
    { LOAD: 56, SPEED: 25.2, status: 1, t: "180927081223000", course: 123, lat: 53.21850666666667, lng: 50.75283 },
    { LOAD: 64, SPEED: 20.8, status: 1, t: "180927081238000", course: 119, lat: 53.21807166666667, lng: 50.75411666666667 },
    { LOAD: 64, SPEED: 20.8, status: 1, t: "180927081238000", course: 119, lat: 53.21807166666667, lng: 50.75411666666667 },
    { LOAD: 48, SPEED: 19.8, status: 1, t: "180927081254000", course: 119, lat: 53.217681666666664, lng: 50.75528 },
    { LOAD: 51, SPEED: 19.5, status: 1, t: "180927081308000", course: 118, lat: 53.217315, lng: 50.756413333333334 },
    { LOAD: 55, SPEED: 20.3, status: 1, t: "180927081324000", course: 117, lat: 53.21696166666667, lng: 50.75758 },
    { LOAD: 49, SPEED: 20.5, status: 1, t: "180927081338000", course: 117, lat: 53.216595, lng: 50.75879333333334 },
    { LOAD: 49, SPEED: 20.5, status: 1, t: "180927081338000", course: 117, lat: 53.216595, lng: 50.75879333333334 },
    { LOAD: 51, SPEED: 20.5, status: 1, t: "180927081354000", course: 118, lat: 53.21621666666667, lng: 50.76000666666667 },
    { LOAD: 51, SPEED: 20.5, status: 1, t: "180927081405000", course: 118, lat: 53.215968333333336, lng: 50.7608 },
    { LOAD: 58, SPEED: 18.2, status: 1, t: "180927081408000", course: 121, lat: 53.21584, lng: 50.761155 },
    { LOAD: 51, SPEED: 10.8, status: 1, t: "180927081424000", course: 115, lat: 53.21556833333333, lng: 50.762141666666665 },
    { LOAD: 51, SPEED: 10.8, status: 1, t: "180927081435000", course: 115, lat: 53.215435, lng: 50.762618333333336 },
    { LOAD: 30, SPEED: 11.3, status: 1, t: "180927081438000", course: 109, lat: 53.215378333333334, lng: 50.76288666666667 },
    { LOAD: 30, SPEED: 11.3, status: 1, t: "180927081438000", course: 109, lat: 53.215378333333334, lng: 50.76288666666667 },
    { LOAD: 30, SPEED: 11.3, status: 1, t: "180927081438000", course: 109, lat: 53.215378333333334, lng: 50.76288666666667 },
    { LOAD: 43, SPEED: 11.9, status: 1, t: "180927081453000", course: 85, lat: 53.215423333333334, lng: 50.763686666666665 },
    { LOAD: 28, SPEED: 8.6, status: 1, t: "180927081509000", course: 30, lat: 53.21579666666667, lng: 50.764041666666664 },
    { LOAD: 44, SPEED: 4.7, status: 1, t: "180927081523000", course: 28, lat: 53.21608666666667, lng: 50.76429666666667 },
    { LOAD: 44, SPEED: 4.7, status: 1, t: "180927081535000", course: 26, lat: 53.21628166666667, lng: 50.76445833333333 },
    { LOAD: 52, SPEED: 11.4, status: 1, t: "180927081539000", course: 29, lat: 53.216393333333336, lng: 50.76456 },
    { LOAD: 52, SPEED: 11.4, status: 1, t: "180927081539000", course: 29, lat: 53.216393333333336, lng: 50.76456 },
    { LOAD: 49, SPEED: 11.7, status: 1, t: "180927081553000", course: 47, lat: 53.21669, lng: 50.76508666666667 },
    { LOAD: 49, SPEED: 11.7, status: 1, t: "180927081559000", course: 70, lat: 53.21674333333333, lng: 50.76532666666667 },
    { LOAD: 44, SPEED: 12.1, status: 1, t: "180927081608000", course: 65, lat: 53.216876666666664, lng: 50.765815 },
    { LOAD: 50, SPEED: 12.4, status: 1, t: "180927081623000", course: 62, lat: 53.21711, lng: 50.76653666666667 },
    { LOAD: 50, SPEED: 12.4, status: 1, t: "180927081623000", course: 62, lat: 53.21711, lng: 50.76653666666667 }, 
    { LOAD: 8, SPEED: 10.7, status: 4, t: "180927081638000", course: 62, lat: 53.21732333333333, lng: 50.767205 }, 
    { LOAD: 8, SPEED: 10.7, status: 4, t: "180927081638000", course: 62, lat: 53.21732333333333, lng: 50.767205 }, 
    { LOAD: 8, SPEED: 10.7, status: 4, t: "180927081639000", course: 62, lat: 53.21732333333333, lng: 50.767205 }, 
    { LOAD: 8, SPEED: 10.7, status: 4, t: "180927081645000", course: 54, lat: 53.217395, lng: 50.76737166666667 }, 
    { LOAD: 51, SPEED: 4.1, status: 4, t: "180927081653000", course: 36, lat: 53.21743333333333, lng: 50.76741833333333 }, 
    { LOAD: 51, SPEED: 4.1, status: 4, t: "180927081658000", course: 12, lat: 53.217495, lng: 50.76744 }, 
    { LOAD: 51, SPEED: 4.1, status: 4, t: "180927081707000", course: 12, lat: 53.217675, lng: 50.76750166666667 }, 
    { LOAD: 64, SPEED: 7.2, status: 4, t: "180927081708000", course: 12, lat: 53.217675, lng: 50.76750166666667 }, 
    { LOAD: 69, SPEED: 7.4, status: 4, t: "180927081723000", course: 13, lat: 53.217956666666666, lng: 50.76761166666667 }, 
    { LOAD: 64, SPEED: 7.9, status: 4, t: "180927081738000", course: 10, lat: 53.21825833333333, lng: 50.76770333333333 }, 
    { LOAD: 64, SPEED: 7.9, status: 4, t: "180927081738000", course: 10, lat: 53.21825833333333, lng: 50.76770333333333 }, 
    { LOAD: 73, SPEED: 7.8, status: 4, t: "180927081753000", course: 13, lat: 53.21855166666667, lng: 50.767815 }, 
    { LOAD: 71, SPEED: 7.8, status: 4, t: "180927081809000", course: 12, lat: 53.21885, lng: 50.767925 }, 
    { LOAD: 72, SPEED: 7.8, status: 4, t: "180927081823000", course: 12, lat: 53.219161666666665, lng: 50.76803666666667 }, 
    { LOAD: 62, SPEED: 7.9, status: 4, t: "180927081838000", course: 11, lat: 53.219471666666664, lng: 50.768135 }, 
    { LOAD: 62, SPEED: 7.9, status: 4, t: "180927081838000", course: 11, lat: 53.219471666666664, lng: 50.768135 }, 
    { LOAD: 70, SPEED: 7.8, status: 4, t: "180927081853000", course: 14, lat: 53.219765, lng: 50.768256666666666 }, 
    { LOAD: 68, SPEED: 7.7, status: 4, t: "180927081908000", course: 14, lat: 53.220061666666666, lng: 50.76838333333333 }, 
    { LOAD: 60, SPEED: 8.5, status: 4, t: "180927081923000", course: 185, lat: 53.219883333333335, lng: 50.76835666666667 }, 
    { LOAD: 66, SPEED: 8.4, status: 4, t: "180927081938000", course: 193, lat: 53.219546666666666, lng: 50.76822333333333 }, 
    { LOAD: 66, SPEED: 8.4, status: 4, t: "180927081938000", course: 193, lat: 53.219546666666666, lng: 50.76822333333333 }, 
    { LOAD: 66, SPEED: 8.4, status: 4, t: "180927081938000", course: 193, lat: 53.219546666666666, lng: 50.76822333333333 }, 
    { LOAD: 66, SPEED: 8.4, status: 4, t: "180927081938000", course: 193, lat: 53.219546666666666, lng: 50.76822333333333 }, 
    { LOAD: 66, SPEED: 8.4, status: 4, t: "180927081938000", course: 193, lat: 53.219546666666666, lng: 50.76822333333333 }, 
    { LOAD: 62, SPEED: 8.6, status: 4, t: "180927081953000", course: 192, lat: 53.21922, lng: 50.768105 }, 
    { LOAD: 69, SPEED: 8.4, status: 4, t: "180927082008000", course: 193, lat: 53.21888333333333, lng: 50.767975 }, 
    { LOAD: 68, SPEED: 8.5, status: 4, t: "180927082024000", course: 192, lat: 53.218563333333336, lng: 50.76786 }, 
    { LOAD: 66, SPEED: 8.4, status: 4, t: "180927082038000", course: 191, lat: 53.21822, lng: 50.76775333333333 }, 
    { LOAD: 66, SPEED: 8.4, status: 4, t: "180927082038000", course: 191, lat: 53.21822, lng: 50.76775333333333 }, 
    { LOAD: 70, SPEED: 8.5, status: 4, t: "180927082053000", course: 191, lat: 53.21787666666667, lng: 50.76764333333333 }, 
    { LOAD: 50, SPEED: 3, status: 4, t: "180927082108000", course: 193, lat: 53.217545, lng: 50.767516666666666 }, 
    { LOAD: 42, SPEED: 0, status: 4, t: "180927082123000", course: 270, lat: 53.217545, lng: 50.76750166666667 },
    { LOAD: 42, SPEED: 0, status: 4, t: "180927082123000", course: 270, lat: 53.217545, lng: 50.76750166666667 }, 
    { LOAD: 50, SPEED: 11.5, status: 1, t: "180927082338000", course: 113, lat: 53.217506666666665, lng: 50.767655 }, 
    { LOAD: 50, SPEED: 11.5, status: 1, t: "180927082338000", course: 113, lat: 53.217506666666665, lng: 50.767655 }, 
    { LOAD: 17, SPEED: 8, status: 1, t: "180927082354000", course: 63, lat: 53.21772, lng: 50.768341666666664 }, 
    { LOAD: 38, SPEED: 11.6, status: 1, t: "180927082408000", course: 61, lat: 53.217861666666664, lng: 50.76876 }, 
    { LOAD: 25, SPEED: 16.6, status: 1, t: "180927082424000", course: 53, lat: 53.21823833333333, lng: 50.769585 }, 
    { LOAD: 89, SPEED: 13.3, status: 1, t: "180927082438000", course: 77, lat: 53.21836166666667, lng: 50.77050833333333 }, 
    { LOAD: 89, SPEED: 13.3, status: 1, t: "180927082438000", course: 77, lat: 53.21836166666667, lng: 50.77050833333333 }, 
    { LOAD: 89, SPEED: 13.3, status: 1, t: "180927082438000", course: 77, lat: 53.21836166666667, lng: 50.77050833333333 }, 
    { LOAD: 61, SPEED: 20.5, status: 1, t: "180927082454000", course: 71, lat: 53.218575, lng: 50.77155333333333 }, 
    { LOAD: 49, SPEED: 21.1, status: 1, t: "180927082508000", course: 69, lat: 53.218871666666665, lng: 50.77283833333333 }, 
    { LOAD: 58, SPEED: 21.1, status: 1, t: "180927082524000", course: 65, lat: 53.21923, lng: 50.774096666666665 }, 
    { LOAD: 67, SPEED: 18.4, status: 1, t: "180927082538000", course: 63, lat: 53.2196, lng: 50.775288333333336 }, 
    { LOAD: 67, SPEED: 18.4, status: 1, t: "180927082538000", course: 63, lat: 53.2196, lng: 50.775288333333336 }, 
    { LOAD: 55, SPEED: 18.6, status: 1, t: "180927082553000", course: 60, lat: 53.219966666666664, lng: 50.776345 }, 
    { LOAD: 55, SPEED: 18.6, status: 1, t: "180927082553000", course: 60, lat: 53.219966666666664, lng: 50.776345 }, 
    { LOAD: 55, SPEED: 18.6, status: 1, t: "180927082605000", course: 64, lat: 53.22018, lng: 50.77708833333333 }, 
    { LOAD: 51, SPEED: 18.9, status: 1, t: "180927082608000", course: 59, lat: 53.220306666666666, lng: 50.777438333333336 }, 
    { LOAD: 55, SPEED: 18.5, status: 1, t: "180927082623000", course: 71, lat: 53.220546666666664, lng: 50.778595 }, 
    { LOAD: 55, SPEED: 18.5, status: 1, t: "180927082628000", course: 75, lat: 53.22060833333333, lng: 50.77898833333333 }, 
    { LOAD: 47, SPEED: 18.9, status: 1, t: "180927082638000", course: 72, lat: 53.22076333333333, lng: 50.779785 },
     { LOAD: 47, SPEED: 18.9, status: 1, t: "180927082638000", course: 72, lat: 53.22076333333333, lng: 50.779785 }, 
     { LOAD: 51, SPEED: 16.6, status: 1, t: "180927082653000", course: 67, lat: 53.221046666666666, lng: 50.78088833333333 }, 
     { LOAD: 51, SPEED: 16.6, status: 1, t: "180927082705000", course: 75, lat: 53.221156666666666, lng: 50.781551666666665 }, 
     { LOAD: 26, SPEED: 7.1, status: 1, t: "180927082708000", course: 85, lat: 53.22117166666666, lng: 50.78185666666667 }, 
     { LOAD: 16, SPEED: 0.5, status: 1, t: "180927082723000", course: 58, lat: 53.221286666666664, lng: 50.78217 }, 
     { LOAD: 16, SPEED: 0.5, status: 1, t: "180927082731000", course: 340, lat: 53.221295, lng: 50.782165 }, 
     { LOAD: 21, SPEED: 0, status: 1, t: "180927082738000", course: 350, lat: 53.22130666666666, lng: 50.78216166666667 }
  ]