import React, { Component } from 'react';
import { connect } from 'react-redux';
import ManagerSideMenu from './ManagerSideMenu';
import GuestSideMenu from './GuestSideMenu';
import CManagerSideMenu from './CManagerSideMenu';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

class SideMenu extends Component {


	render() {

		
		return (
			<div>
			<span className={"logo-title"}>Netfer Maintenance Tracker</span>
			
			{this.props.auth ? 
				this.props.user.role === 'manager' 
				? (<ManagerSideMenu />)
				: (this.props.user.role === 'cluster_manager' || this.props.user.role === 'admin') ? (<CManagerSideMenu />) : (<GuestSideMenu />)
			: (this.props.user.role === 'cluster_manager' || this.props.user.role === 'admin') ? (<CManagerSideMenu />) : (<GuestSideMenu />)
			}

				
			</div>
		)
		
	}
}



export default connect(mapStateToProps, null, null, { pure: false})(SideMenu)
