import React, { useState, useRef, useEffect, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faMapMarker, faLock, faSpinner, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import api from '../../Utils/Api';

import Constants from '../../Utils/Constants';
import ApiService from '../../Utils/ApiService';
//import { useSelector } from 'react-redux';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import EditPatrolVehicule from './components/EditPatrolVehicule';
//import api from "../../Utils/Api";


const PositionsTableRow = ({asset, key, deleteasset, returnasset, closeasset, reload,reloadCars,refresh,PaneSet, ...props}) => {

    // const i = key;

    //const {session} = useSelector(state => ({session: state.session}));

    const [activeTest, selectTest] = useState("clockin")
    const [showDetail, setShowDetail] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [jsondata, setJsonData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const exportForm = useRef(null);
    const detailInput = useRef(null);
    const [centerLat, setLat] = useState(null);
    const [centerLng, setLng] = useState(null);
    const [check, setVar] = useState(null);
    const [date, setDate] =useState(null);
    const [lat, setLatitude] = useState(null);
    const [lng, setLangtitude] = useState(null);
    const [zoom, setZoom] = useState(null);
    const [q, setQ] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    //date picker
    const [timerID, setTimerID]= useState(null);
    const [openPatrolView, setopenPatrolView]= useState(false);

    const handleChange = event => {
        setSearchTerm(event.target.value);
        console.log('results',searchTerm)
      };
    
 
    useEffect(() => {
        const results = data.filter((d) =>
          d[2].toLowerCase().includes(searchTerm)
        );
        console.log("results json",results);
        setSearchResults(results);
        setJsonData(results)
  }, [searchTerm]);

   
       
//to use search filter i convirted data to Json data so i can go through dates and filter
//on the second hand , used it to always keep the data after filter 
    function toJson(data) {
        console.log("toJson",data);
        var jsonArg = new Object();
        var pluginArrayArg = new Array();       
        data.map((d, i) => (
        jsonArg.lat = d[0],
        jsonArg.lng = d[1],
        jsonArg.date =  d[2],
        pluginArrayArg.push(jsonArg)
        ));
        //console.log("jsonArray",pluginArrayArg)
        setJsonData(pluginArrayArg)
        console.log("toJson 1",pluginArrayArg)
    }

   /*  function search(data){
        setJsonData(data)
        return setData(jsondata.filter(
            (d)=>
            d.date.toLowerCase().indexOf(q) > -1
        ));
        console.log("Q",q)
        
    } */

    //const timerID = setInterval( () => selectAsset(),3000);
    const selectAsset = (show = true) => {

        if(!showDetail) {
            setLoading(true);
            api.get('/api/position/fetch?asset='+asset.iot_device_id)
            .then(res => {
                if(res.data.success) {
                    setLat(res.data.positions[0].at(0));
                    setLng(res.data.positions[0].at(1));
                    setDate(res.data.positions[0].at(2));
                    setVar(res.data.positions.length);
                  
                    setZoom(16);
                    setDataLoaded(true);
                    setData(res.data.positions);
                    setJsonData(res.data.positions);
                
                }
                setLoading(false);
            })
        }            

        setShowDetail(!showDetail)
    }

    function refreshPage() {
        window.location.reload(false);
      }

    const stopAlarm = () => {

        setLoading(true);

        ApiService.stopAlarm(asset.iot_device_id)
        .then(res => {
            if(res.data.success) {
                reload();
            }
            setLoading(false);
        })
    }


    /* const exportPM = (anomalies) => {
        setLoading(true);
		if(anomalies) {
			exportForm.current.action = Constants.apiEndPoints.EXPORT_asset_ANOMALIES(asset.asset_id);
		} else {
			exportForm.current.action = Constants.apiEndPoints.EXPORT_asset(asset.asset_id);
		}

        if(!dataLoaded)
            ApiService.fetchassetDetail(asset.asset_id)
            .then(res => {
                if(res.data.success && res.data.total > 0) {
                    
                    setDataLoaded(true);
                    setData(res.data.assets[0]);
                    detailInput.current.value = JSON.stringify(res.data.assets[0]);
                    exportForm.current.submit();
                    setLoading(false);
                }
                setLoading(false);

            })
        else {
            detailInput.current.value = JSON.stringify(data);
            exportForm.current.submit();
            setLoading(false);
        }
    } */
    
    const useForceUpdate = () => useState()[1];

    const forceUpdate = useForceUpdate();

   
    const changeCenter = (d,i,j) => {
        setLoading(true);
        setLat(d.at(0));
        setLng(d.at(1));
        setZoom(16);
        setLoading(false);
        forceUpdate();
        setVar(i);
        setDate(j);
        
        console.log("hello after", centerLat, centerLng, zoom);
    }

    const changeCenterHandler = changeCenter.bind(this);

    const togglePatrolTask = (e) => {

		//this.setState({ openPatrolView: !(typeof e === "boolean") || !this.state.openPatrolView  })
        setopenPatrolView(e)
	}	


    return (<React.Fragment>
        {/* <form method="post" ref={exportForm} target={"_blank"} >
            <input type="hidden" name={"pm"} ref={detailInput} />
            <input type="hidden" name={"token"} value={session.user.token} />
        </form> */}
         <tbody>
            {/* <tr onClick={() => {refresh(asset.iot_position.lat, asset.iot_position.lng, true,asset); PaneSet(asset.iot_device_id, asset, true);}}> */}
            <tr className={'clickable-row'} onClick={() => {refresh(asset);}}>
                <td>
                    {asset.car_id ? asset.car_id : "-" }
                </td>
                <td>
                    {asset.site ? asset.site : "-" }
                </td>

                <td>
                    {asset.asset_type ? asset.asset_type : "-"}
                </td>
                <td>
                    {date ? date: asset.iot_time }
                </td>
                <td>
                    <button onClick={e => togglePatrolTask(true)}  className={"btn btn-primary  btn-sm float-right mr-2"}>Edit </button> 
                </td>
                {/* <td>
                {asset.iot_position && asset.iot_position.lat && (
                        <a href={"https://www.google.com/maps/place/"+asset.iot_position.lat+","+asset.iot_position.lng+"/@"+asset.iot_position.lat+","+asset.iot_position.lng+",15z"} target='_blank' className="task-action-btn" >
                            <FontAwesomeIcon icon={faMapMarker}/>Localisation
                        </a>
                    )}
                </td> */}

            </tr>
            
        </tbody>

        <EditPatrolVehicule
            toggleView={e => togglePatrolTask(false)}
            // agents={this.state.agents}
            open={openPatrolView}
            id={asset.car_id}
            data={asset}
            // loadTasks={this.props.handlers.loadTasks}
            // //loadCars={this.loadCars}
            // loadCars={this.getOptions}
          />



    </React.Fragment>

    )
}
export default PositionsTableRow;
