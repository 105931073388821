import React, { Component } from 'react';
import _ from 'lodash';
import {connect} from "react-redux"
import GuestTopMenu from "./Menus/GuestTopMenu";
import AgentTopMenu from "./Menus/AgentTopMenu";
import ManagerTopMenu from "./Menus/ManagerTopMenu";
import TesteurTopMenu from "./Menus/TesteurTopMenu";
import LocalisationTopMenu from './Menus/LocalisationTopMenu';

import {readNotifications, updateNotifications, setAuthAction} from "./../Redux/actions/index";
import api from '../Utils/Api';
import CManagerTopMenu from './Menus/CManagerTopMenu';
import AdminTopMenu from './Menus/AdminTopMenu';
import TasksTopMenu from './Menus/TasksTopMenu';
import LocalisationAllTopMenu from './Menus/LocalisationAllTopMenu';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user,
		notifications: state.notifications
	}
}

const mapDispatchToProps = dispatch => {
	return {
		logOut: e => dispatch(setAuthAction(e)),
		updateNotifications: e => dispatch(updateNotifications(e)),
		readNotifications: e => dispatch(readNotifications(e))
	}
}
	


class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			updatedNotification: false
		}

		this.setReadNotifications = _.debounce(this.setReadNotifications.bind(this), 1000);

		
	}

	componentDidMount() {
		
	}

	setReadNotifications() {
		if(!this.state.updatedNotification && this.props.notifications.length) {

			const that = this;

			api.get('/api/notification/update?limit='+btoa(this.props.notifications[this.props.notifications.length-1].datetime)+'&token='+this.props.user.token)
			.then(function(response) {
				that.props.readNotifications(1);
				return 1;
			});
		}
	}
	
	
	render() {

		return(
			<div 
			className="header-wrap">
				<div className="header-content fluid-container">
					{(this.props.auth) ? 
						(this.props.user.role === "testeur")
						?	<TesteurTopMenu setReadNotifications={this.setReadNotifications} {...this.props} />
						:
							(this.props.user.role === "agent")
							?	<AgentTopMenu setReadNotifications={this.setReadNotifications} {...this.props} />
							: 	this.props.user.role === "manager"
								? <ManagerTopMenu 
								setReadNotifications={this.setReadNotifications}
								{...this.props} 
								/>
								: this.props.user.role === "cluster_manager" ? (<CManagerTopMenu
									setReadNotifications={this.setReadNotifications}
									{...this.props} 
								  />)

								  : this.props.user.role === 'admin' ? (<AdminTopMenu 
									setReadNotifications={this.setReadNotifications}
									{...this.props} 
									/>) 
									: this.props.user.role === 'locations'  ? (<LocalisationTopMenu 
										setReadNotifications={this.setReadNotifications}
										{...this.props} 
										/>)
										: this.props.user.role === 'all_locations'  ? (<LocalisationAllTopMenu 
											setReadNotifications={this.setReadNotifications}
											{...this.props} 
											/>)
											:this.props.user.role === 'tasks'  && (<TasksTopMenu 
												setReadNotifications={this.setReadNotifications}
												{...this.props} 
												/>)
						
					: 
						(<GuestTopMenu />)
					}
					
				
					<div className={"clear"}></div>
				</div>
				
			</div>
		)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Header);