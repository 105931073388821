import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';

export default class NewPatrolTask extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			links: [],
			manholes: []
		};

		this.taskForm = null;
	}


	loadLinks = () => {
		api.get('/api/link/fetch?limit=-1')
		.then(response => {
			if(response.data.success)
				this.setState({ links: response.data.links.map(item => ({label: item.link_name, value: item.link_id})) })
		})
	}

	loadManholes = (link) => {

		api.get('/api/manhole/fetch/'+link)
		.then(response => {
			if(response.data.success)
				this.setState({ manholes: response.data.manholes.map(item => ({label: item.manhole_type+" "+item.manhole_name, value: item.manhole_id}) )})
		})
		

	}

	componentDidMount() {
		this.loadLinks();
	}

	submitForm = (e) => {
		e.preventDefault();

		const data = {};

		const formData = new FormData(e.target);
		
		for(let [key, val] of formData.entries()) {
			data[key] = val;
		}


		api.post('/api/siteaccess/new', data)
		.then(response => {
			if(response.data.success) {
				this.props.toggleView();
				this.props.loadTasks();
            }
				// console.log("Successfully added");
		})

		return false;
		

	}

	render() {
        const {open, toggleView} = this.props;
        
		return (
			<div className={"ui-overlay "+(open && "open")}>
				<div className={"ui-overlay-content col-md-4 offset-md-4"}>
					<button 
						onClick={toggleView} 
						className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					<h5 className={"mb-4"}>Ajouter une nouvelle tâche Site Access</h5>


					<form ref={ref => this.taskForm = ref} onSubmit={this.submitForm}>
						 
 

						<div className={"form-group"}>
							<label>Site Name</label>
                         
							<input type="text" name="link_name" className={"form-control"} />
						 
						</div>

						<div className={"form-group"}>
							<label>Date</label>
							<input className={"form-control"} type="date" name="plan_date" />
						</div>

						<div className={"form-group"}>
							<label>Technicien</label>
							<Select
								name={"agent"}
								classNamePrefix={"custom-select"}
								options={this.props.agents}
							/>
						</div>



						<button className={"cbtn success"}>Ajouter</button>
					</form>

					
					
					
				</div>
			</div>
		)
	}
}