import React, { Component } from 'react';
import _ from 'lodash';
import { Link, Redirect, NavLink, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import TaskList from '../Components/Task/TaskList'
import {setAuthAction, startLoading, doneLoading} from '../Redux/actions/index';
import { Trans } from "react-i18next";
import Select from 'react-select';
import SocketContext from '../Utils/socket-context';
import api from '../Utils/Api';
import Pagination from "../Utils/Pagination";
import { faCheckSquare, faArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PatrolTaskList from '../Components/Task/OdfTaskList';

import NewPatrolTask from '../Components/Task/components/NewOdfTask';



const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user, 
		taskList: state.taskList
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e))
	}
}

class SiteAccessTaskContainer extends Component {
	
	constructor(props) {
		super(props);
		
		this.props.isLoading({
			message:"Loading Links",
			animate:true
		});

		this.state = {
			openPatrolView: false,
			planning_ready: false,
			taskid: null,
			managers: [],
			tasks: [],
			agents: [],
			status: [],
			types: [],
			rows: [],
			current: 1,
			pages: 1,
			limit: 10,
			filters: {
				link_owner: "",
				agent: "",
				link: "",
				cr: "",
				wilaya: "",
				date_from: "",
				date_to: "",
				status: "active"
			},
			wilayas: [],
			linkTaskData: null,
			manholeListLoading: true,
			activeRow: null,
			selectMode: false,
			selectedTasks: []
		}

		this.handlers = {
			selectPage: this.selectPage.bind(this),
			loadTasks: this.loadTasks.bind(this),
			updateFilter: this.updateFilter,
			selectTask: this.selectTask,
			unselectTask: this.unselectTask,
			selectAll: this.selectAll
		}
		
		this.loadTasks = this.loadTasks.bind(this);
		this.toggleHidden = this.toggleHidden.bind(this);
		//this.updateFilter = _.debounce(this.updateFilter, 200);

		this.changeLimit = this.changeLimit.bind(this);

	}

	changeLimit(e) {
		this.props.isLoading();
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadTasks())


	}



	updateFilter = (event, name) => {

		let that = this;
		const value = event.target ? event.target.value : event.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, () => {
			console.log(name, event)
			that.props.isLoading();
			this.loadTasks(1);
		}); 


		

	}


	filterResult() {

	}

	submitAssignement(e) {


	}

	toggleOverlay(e) {

	}
	


	handleInput(e) {
	
	}

	toggleHidden(e, task) {
		
		e.persist();

		console.log("task ",Object.keys(task),task);

		// return;


		const i = e.target.getAttribute('data-row-index');
		console.log(this.state.activeRow)

		this.setState({ 
			linkTaskData : {}, 
			manholeListLoading: true,
			manholeCheck: null,
			activeRow: this.state.activeRow === i ? null : i
		});

		


		if(this.state.activeRow !== i) {

			api.get("/api/odf/detail/"+task.taskid).then(res => {
				this.setState({
					linkTaskData: res.data,
					manholeListLoading: false
				});
			})

		}
	
	}

	componentDidMount() {


		let that = this;


		if(this.props.taskList.length > 0) {
			this.setState({tasks: this.props.taskList})
		} else {
			if(this.props.match.params.taskid && this.props.match.params.taskid) {

				this.updateFilter({ value:  this.props.match.params.taskid }, 'cr')
				
			} else if (this.props.match.params.agentid) {
				this.updateFilter({ value:  this.props.match.params.agentid }, 'agent')
			} else
				this.loadTasks();
		}


		api.get('/api/task/form?token='+this.props.user.token)
		.then(function(response) {
			if(response.data.success) {

				that.setState({
					agents: [{label: 'All', value:""}, ...response.data.agents], 
					wilayas: [{label: 'All', value:""}, ...response.data.wilayas] 
				})
	
				if(response.data.managers) {
					that.setState({
						managers: [{label: 'All', value:""}, ...response.data.managers]
					})
				}
			}
		});


	}

	


	loadTasks(p = this.state.current) {

		let nstate = this.state;
		let that = this;

		let crits = "limit="+this.state.limit
					  +"&agent="+this.state.filters.agent
					  +"&link="+this.state.filters.link
					  +"&wilaya="+this.state.filters.wilaya
					  +"&link_owner="+this.state.filters.link_owner
					  +"&date_from="+this.state.filters.date_from
					  +"&date_to="+this.state.filters.date_to
					  +"&status="+this.state.filters.status
					  +"&cr="+this.state.filters.cr;


		api.get('/api/odf/fetch?page='+p+'&'+crits)
		.then(function(response) {
			console.log(response)
			if(response.data.success) {

				response.data.tasks.map(r =>  {
					nstate.rows.push({"hidden": true})
				});


				that.setState({tasks: response.data.tasks, pages: response.data.pages, rows: nstate.rows})

			} else {
				if(response.data.errcode === 101) {
					that.props.setAuth(false);
				}
			}

			that.props.doneLoading();

		});
	}


	importClick = () => {
		//e.preventDefault();

		this.toggleOverlay();

		if(this.state.planning_ready) {
			this.fileFormRef.submit();
		} else {
			document.querySelector("#linksfile").click();
		}


	}

	handelFileChange = (e) => {
		if(e.target.value) {
			this.setState({ planning_ready: true })
		} else {
			this.setState({ planning_ready: false })
		}
		console.log(e.target)
	}

	resetFileForm = () => {
		this.setState({ planning_ready: false });
		this.fileFormRef.reset();
	}


	

	selectPage = (p) => {
		this.props.isLoading();

		this.setState({current: p});

		this.loadTasks(p);
	}

	exportPlanningTemplate = () => {
		alert('test')
	}


	toggleSelectMode = () => {
		this.setState({ selectMode: !this.state.selectMode })
	}

	selectTask = (taskid) => {
		const {selectedTasks} = this.state;

		selectedTasks.push(taskid);

		this.setState({ selectedTasks });
	}

	unselectTask = (taskid) => {
		let { selectedTasks } = this.state;

		const idx = selectedTasks.indexOf(taskid);

		selectedTasks.splice(idx, 1);
		this.setState({ selectedTasks });
	}

	selectAll = () => {
		const {selectedTasks} = this.state;

		// filter existing tasks
		selectedTasks.push(...this.state.tasks.map(task => selectedTasks.indexOf(task.taskid) > -1 ? false : task.taskid).filter(Boolean));
		
		this.setState({ selectedTasks });
	}
	
	multipleArchive = (e) => {

		const data = {
			tasks: this.state.selectedTasks
		}

		this.props.isLoading({
			message: "Archivage en cours",
			animated: true
		});
		
		api.post('/api/task/archive', data)
		.then(res => {
			this.loadTasks();
		});

		return false;
	}

	togglePatrolTask = (e) => {

		this.setState({ openPatrolView: !(typeof e === "boolean") || !this.state.openPatrolView  })
	}	

	render() {
		
		return(
			
			<React.Fragment>
			
			{!this.props.auth ? <Redirect to="/login" /> :  ""}

			<div className={"container-header"}>
				ODF

				<button onClick={this.togglePatrolTask} className={"cbtn float-right"}>Nouvelle tâche</button>
			</div>


			<NewPatrolTask
				toggleView={e => this.togglePatrolTask(false)}
				agents={this.state.agents}
				open={this.state.openPatrolView}
				loadTasks={this.loadTasks}
			/>

			<div className={"ui-rect np"}>


				<div className={"row form "}>
					<div className={"form-group col-md-3"}>
						<label>Owner</label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "Tous", value: ""},
							{label: "Ooredoo", value: "ooredoo"},
							{label: "Djezzy", value: "djezzy"}
						]}
						placeholder="Link Owner"
						onChange={e => this.updateFilter(e, 'link_owner')}
						/>
					</div>

					<div className={"form-group col-md-3"}>
						<label>From</label>
						<input type="date"
						className={"form-control"}
						onChange={e => this.updateFilter(e, 'date_from')}
						/>
					</div>

					<div className={"form-group col-md-3"}>
						<label>To</label>
						<input type="date" 
						className={"form-control"}
						onChange={e => this.updateFilter(e, 'date_to')}
						/>
					</div>

					<div className={"form-group col-md-3"}>
						<label>Status</label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "Tous", value: "all"},
							{label: "Active", value: "active"},
							{label: "Archivé", value: "archive"}
						]}
						placeholder="Task Status"
						onChange={e => this.updateFilter(e, 'status')}
						/>
					</div>


				</div>
				
				<div className={"row form"}>
					
					{this.props.user.role !== 'agent' && (

						<div className={"col-md-3"}>
							<label>Technicien</label>
							<Select
							className={"classnametest"}
							classNamePrefix={"custom-select"}
							isLoading={!this.state.agents.length}
							options={this.state.agents}
							placeholder="Technicien"
							onChange={e => this.updateFilter(e, 'agent')}
							/>
						</div>
					)}

					<div className={"col-md-3"}>
						<label>Wilaya</label>
						<Select
						classNamePrefix={"custom-select"}
						options={this.state.wilayas}
						placeholder="Wilaya"
						onChange={e => this.updateFilter(e, 'wilaya')}
						/>
					</div>


					<div className={"form-group col-md-3"}>
						<label>Link</label>
						<input
						name="link"
						className={"form-control"}
						placeholder="Nom ou code link"
						value={this.state.filters.link}
						onChange={e => this.updateFilter(e, 'link')}
						/>
					</div>
					<div className={"form-group col-md-3"}>
						<label>CR</label>
						<input
						name="cr"
						className={"form-control"}
						onKeyUp={e => this.updateFilter(e, 'cr')}
						defaultValue={this.state.filters.cr}
						/>
					</div>

				</div>

			</div>
			

				<div className={"row ui-rect my-4"}>
					<div className={"col-md-6 items-found"}>

						{this.props.user.role !== 'tasks'  && <button onClick={this.toggleSelectMode} type="button" className={"cbtn "+ (this.state.selectMode ? "danger" : "success")}>
							{this.state.selectMode ? "Annuler" : "Mode selection"}
						</button>}

						{this.state.selectMode && this.state.selectedTasks.length > 0 && (<>
							<button onClick={this.multipleArchive} className="cbtn ml-3" ><FontAwesomeIcon icon={faArchive}  /> Archiver la selection</button>
						</>)}

						
					</div>
				
					<div className={"col-md-6 per-page-wrap"}>
						<div className={"per-page-content"}>
							<p>Résultat par page</p> 
							<Select
								classNamePrefix={"custom-select"}
								onChange={this.changeLimit}
								className={"per-page nm"}
								options={[
									{label: "10", value: 10},
									{label: "15", value: 15},
									{label: "30", value: 30}]}
								value={{label: this.state.limit, value: this.state.limit}}
							/>
						</div>
						<Pagination
							data={this.state}
							handleClick={this.selectPage} 
						/>
						
					</div>

				</div>
				
				<div className={"ui-rect np"}>
                    <PatrolTaskList
                        data={this.state}
                        rows={this.state.rows}
                        toggleHidden={this.toggleHidden}
                        handlers={this.handlers}
                    />
            


				</div>

			
			</React.Fragment>)


	}
}


const PatrolTaskConsumer = props => (
	<SocketContext.Consumer>
		{socket => <SiteAccessTaskContainer {...props} socket={socket} />}
	</SocketContext.Consumer>
  )

export default connect(mapStateToProps, mapDispatchToProps)(PatrolTaskConsumer)