import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import maint from "../../assets/maintnbg.png"

const axios = require("axios");



function mapStateToProps(state) {
  return { auth: state.auth };
};


class MaitenanceContainer extends Component {

	constructor(props) {

		super(props);

		this.state = {
			
		}

	}

	
	componentWillMount(){

		
	
	}

	render() {
		return(
			<div  > 
				<div className='d-flex justify-content-center'>
					<img  src={maint} alt='logo' width={600} height={400}/>
				</div>
				<div className='d-flex justify-content-center'>
					<h1 style={{color: '#444444'}}>Sorry! We are under maintenance currently!!</h1>
				</div>
			</div>
			

			
		)
	}
}

export default connect(mapStateToProps)(MaitenanceContainer)
