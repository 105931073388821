import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Select from 'react-select';

import "react-table/react-table.css";
import { connect } from 'react-redux';
import {setAuthAction, startLoading, doneLoading, closeFlash, setFlash} from '../../Redux/actions/index';
import {  Trans } from "react-i18next";


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import Pagination from '../../Utils/Pagination';


const moment = require("moment");



const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user, 
		taskList: state.taskList
	}
}

const mapDispatchToProps = (dispatch) => 
	({
		setAuth: user => dispatch(setAuthAction(user)),
		doneLoading: () => dispatch(doneLoading()),
		isLoading: (p) => dispatch(startLoading(p)),
		setFlash: (f) => dispatch(setFlash(f)),
		closeFlash: (f) => dispatch(closeFlash(f))
	})




class TaskList extends Component {

	

	
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			showOverlay: false,
			siteid: "",
			agentid: "",
			date: ""
		}

		this.toggleOverlay = this.toggleOverlay.bind(this);

	}

	handleEscape = (e) => {
		if(e.keyCode === 27) {
			this.setState({showOverlay: false});
		}
	}

	toggleOverlay(e) {
		this.setState({showOverlay: !this.state.showOverlay});
	}

	componentDidMount() {
		document.addEventListener('keyup', this.handleEscape)
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.handleEscape);
	}

	reassignSite = (site, e) => {
		
		this.setState({
			date: site.site_next_pm ? site.site_next_pm : "",
			agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
			agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
			siteid: site.site_id
		})
		
		this.toggleOverlay()
	}

	formSubmit = (e) => {

		e.preventDefault();

		if(!this.state.date || !this.state.agentid) {
			return false;	
		}

		this.props.isLoading();
		const that = this;
		
		const data = {
            planning_date: this.state.date,
            selected_site: this.state.siteid,
            agent: this.state.agentid
		}

		
		
		api.post('/api/planning/assign', data)
		.then(function(response) {
			if(response.data.success) {
				that.props.setFlash({
					active: true,
					message: "Site attribué avec succés.",
					classes: "success",
					style: {}
				})
				that.props.handlers.loadSites();
				that.setState({ showOverlay: false })
			} else {
				that.props.setFlash({
					active: true,
					message: "Une erreur est survenue, veuillez contacter l'administrateur.",
					classes: "warning",
					style: {}
				})
			}

			that.props.doneLoading();

		})

	}

	

	render() {
		//const { t, i18n } = useTranslation();
		
		const data = this.props.data;
		const toggleHidden = this.props.toggleHidden;
		const showOverlay = this.state.showOverlay


		return(

			<React.Fragment>	



			
			

			<div className={"row"}>

			<div className={"col-md-12"}>
				<div className={"row"}>
					<div className={"col-md-6"}>
					
						<p>
							<b>{data.total}</b> sites trouvés
						</p>
					</div>
					<div className={"col-md-6"}>

						<ul className={"pagination"}>
							<li className={"label"}>Pages</li>
							<Pagination
							data={this.props.data}
							handleClick={this.props.handlers.selectPage} />

						</ul>
					</div>

				</div>

				<div className={"clear mb-6"}></div>
			</div>


			</div>

			

			<div className="col-md-12 ctable mb-3">
			
			
			<div className={"d-none d-sm-block"}>
				
				<div className={"row head-titles ctable-row"}>

					<div className={"col-md-1"}>
						Site ID
					</div>

					
					<div className={"col-md-1"}>
						Site Code
					</div>

					<div className={"col-md-2"}>
						Site Name
					</div>
					
					<div className={"col-md-1"}>
						Wilaya
					</div>

					{/* <div className={"col-md-2"}>
						<Trans i18nKey="label_address" />
					</div> */}

					<div className={"col-md-2"}>
						Dernier PM
					</div>

					<div className={"col-md-2"}>
						PM Plan
					</div>

					<div className={"col-md-2"}>
						Attribué à
					</div>

					<div className={"col-md-1"}>
						<Trans i18nKey="label_details" />
					</div>

				</div>
			</div>

			{data.sites.map((site, i) => 
				
			(<React.Fragment key={i}>
				
				<div className={"row "}>
				<div className={"col-md-12"}>

				<div className={"row ctable-row"}>
					
					
						<div className={"col-md-1"}>
							 #{site.site_id}
						</div>

						<div className={"col-md-1"}>
							{site.site_code}
						</div>

						<div className={"col-md-2"}>
							{site.site_name}
						</div>
						
						<div className={"col-md-1"}>
							{site.site_address_wilaya.toUpperCase()}
						</div>

						<div className={"col-md-2"}>
							<Link to={'/task/'+site.site_last_pm_id} >
							{site.site_last_pm_at}
							</Link>
						</div>

						<div className={"col-md-2"}>
							{site.site_next_pm}
 						</div>

 						<div className={"col-md-2 seperate"}>
							{site.site_next_pm_agent ? (<>{site.site_next_pm_agent} <a onClick={e => this.reassignSite(site, e)} href="#" className={"btn btn-sm btn-primary ml-2 "}>Réattribuer</a></>) : 
							<a onClick={e => this.reassignSite(site, e)} href="#" className={"btn btn-sm btn-primary float-right"}>
								Attribuer à un agent
							</a>}
							
						</div>

						

						<div className={"col-md-1"}>
							{/* <div>

							<button  onClick={toggleHidden} data-row-index={i}  className={"btn btn-block btn-sm btn-link"}>Detail site </button>
							</div> */}
						</div>

					
				</div>

				
				
			</div>
			</div>


			</React.Fragment>

			))}
			{data.sites.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucun site trouvé</div>)}





			<div className={showOverlay? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
					<button data-package-id="0" onClick={e => { this.toggleOverlay(e); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					<h5>Attribuer le site à un agent</h5>
					<form onSubmit={this.formSubmit}>
					<div className="form-group mt-3">
					<label className={"font-exp"}>PM Plan</label>
					<input required value={this.state.date} onChange={t => this.setState({ date: t.target.value })} type="date" className={"form-control"} />
					</div>

					<div className="form-group mt-3">
					<label className={"font-exp"}>Select agent</label>
					<Select
					
					value={{'value': this.state.agentid, label: this.state.agent_username}}
					options={data.agents}
					cacheOptions
					defaultOptions 
					onChange={e => this.setState({agentid: e.value, agent_username: e.label})}
					placeholder="Agent"
					className={"d-block mt-1"}
					/>

					<input type="submit" value="Update" className={"btn btn-primary btn-sm mt-2"} />
					</div>
					</form>
					<div>
						<span className={"font-exp"}>Agent will receive a notification.</span>
					</div>
					
				</div>
			</div>


			</div>

			<div className={"row"}>
			<div className={"col-md-12"}>
				<ul className={"pagination"}>
					<li className={"label"}>Pages</li>
					<Pagination
					data={this.props.data}
					handleClick={this.props.handlers.selectPage} />

				</ul>

				<div className={"clear mb-6"}></div>
			</div>


			</div>

			</React.Fragment>
		)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(TaskList)