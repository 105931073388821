import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router'
import { closeFlash, setFlash, setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import api from '../../Utils/Api';
import { useTranslation, Trans } from "react-i18next";



const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    isLoading: (p) => dispatch(startLoading(p)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
}



class LoginBox extends Component {

	constructor(props) {

		super(props);


		this.props.doneLoading();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.passwordRef = React.createRef();

		this.state = {
			messageSpan: "",
			confirmation: false,
			form: {
				platform: "web",
				username: "",
				password: "",
				code: "",
				device: "Web"
			}
		};

		props.closeFlash();
	}

	handleInputChange(e) {
		let s = this.state;
		s.form[e.target.name] = e.target.value;
		this.setState({...s});
	}

	handleSubmit(e) {
		e.preventDefault();
		let that = this;


		/*if(this.state.form.password.length === 0)
			return this.passwordRef.current.focus();*/

		if(this.state.form.password.length < 4 || this.state.form.password.length < 4) {

			that.props.setFlash({
				active: true,
				message: "Enter valid username & password",
				classes: "warning",
				style: {}
			})

			return 
		}

		this.props.isLoading({message:'Connecting', animate:true});

		
				

		api.post('/api/user/connect', this.state.form)
		.then(function(response) {
			

			if(response.data && response.data.success === true) {
	
				that.props.setAuth(response.data);				

				// window.location.href = window.location.href+'/account';

			} else {
				if(response.data && response.data.errcode === 102)
					that.setState({confirmation: true});

				if(response.data.errcode === 110) {
					that.props.setFlash({
						active: true,
						message: (<>Vous n'êtes pas autorisé à accéder à la platforme web, <a target='_blank' href='https://play.google.com/store/apps/details?id=com.djezzy'>Veuillez télécharger l'application mobile ici</a></>),
						classes: "warning",
						style: {}
					})
				} else {

					that.props.setFlash({
						active: true,
						message: response.data.error,
						classes: "warning",
						style: {}
					})
				}

			}
				
				

			that.props.doneLoading();
		})
		.catch(error => {
			console.log(error);
			that.props.doneLoading();
		})

		
	}

	render() {
		return (
			

			

			<div className={"login-page"}>

			<div className={"container-header"}>

				<center>
					Main Tracker
				</center>
				
			</div>
			
		{/*{this.props.auth && this.props.user.role === "testeur" ? <Redirect to="/positions" /> : <Redirect to={"/dashboard"} /> }  */}
		 {/*  {this.props.auth && <Redirect to={"/dashboard"} /> }   */}
			{this.props.auth && <Redirect to= {this.props.user.role === "testeur" ? "/positions" : (this.props.user.role === "locations"  || this.props.user.role === "all_locations" ) ? "locations" : this.props.user.role === "tasks" ? "task": "/dashboard"} /> }  
			{<span className={"mb-4"}>{this.state.messageSpan}</span>}

			<div className="col-md-5 login-box ui-rect">
			
			<form onSubmit={this.handleSubmit}  name="loginForm">
				<input type="hidden" name="hidden" value="aze" />
				
					<div className={"c-form-row form-group"}>
						<label className="label-style"><Trans i18nKey="user_username" /> </label>
						<input disabled={this.state.confirmation} tabIndex="1" className="input-style" type="text" name="username" onChange={this.handleInputChange} value={this.state.form.username} /><br />
					</div>

					<div className={"c-form-row form-group"}>
						<label className="label-style"><Trans i18nKey="user_password" /> </label>
						<input disabled={this.state.confirmation} ref={this.passwordRef} tabIndex="2" className="input-style" type="password" name="password" onChange={this.handleInputChange} value={this.state.form.password} />
					</div>

					{this.state.confirmation ? (<div className={"c-form-row form-group"}>
						<label className="label-style"><Trans i18nKey="confirmation_code" /> </label>
						<input tabIndex="3" className="input-style" type="text" name="code" onChange={this.handleInputChange} value={this.state.form.code} />
					</div>) : ""}


					<div className={"c-form-row form-group"}>
						<button className={"cbtn btn-block btn-primary"} type="submit"><Trans i18nKey="label_signin" /></button>
					</div>

					{/* <div className={"c-form-row mt-4"}>
						<a href="#"><span className={"btn btn-info float-left"}><Trans i18nKey="label_joinus" /></span></a>
						<span className={"float-right"}><a href="#"><Trans i18nKey="password_recovery" /></a></span>
					</div> */}



					<div className="clear"></div>
			
			</form>

			</div>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginBox)