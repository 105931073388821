import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as actions from './../../Redux/actions/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import api from '../../Utils/Api';
import SocketContext from '../../Utils/socket-context';
import { useTranslation, Trans } from "react-i18next";


const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(actions.setAuthAction(user)),
		isLoading: e => dispatch(actions.startLoading(e)),
		doneLoading: () => dispatch(actions.doneLoading()),
		setFlash: (f) => dispatch(actions.setFlash(f)),
    	closeFlash: (f) => dispatch(actions.closeFlash(f))
	}
}

const moment = require("moment");


class AgentList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			filter: 'all',
			users: [],
			tableIsLoading: true,
			pages: 1,
			current: 1,
			showOverlay: false,
			selectedUser: 0,
			lastUpdate: 0,
			app_id: "sodMSfpEnNEZfHC7oV0M",
			app_code: "noVfKVAMsfkjYV3Cv6jmnA",
			center: {
					lat: 35,
					lng: 3,
			},
			zoom: 7,
			appId: "sodMSfpEnNEZfHC7oV0M",
			appCode: "noVfKVAMsfkjYV3Cv6jmnA",
			useHTTPS: true,
			markers: {}

		}

		this.platform = null;
		this.map = null;
		this.markers = null;
		this.ui = null;


		this.props.isLoading();
		this.loadAgents = this.loadAgents.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.setMarkers = this.setMarkers.bind(this);
		this.agentInterval = null;
		this.updateFilter = this.updateFilter.bind(this);
	}



	setMarkers() {

		let that = this;

		const users = this.state.users;

		if(users.length === 0)
			return false;


		let markers = this.state.markers;

		[...users].forEach((item, i) => {
			if(this.state.markers[item.userid]) {

				console.log('set position')

				this.state.markers[item.userid].setPosition({
					lat: item.geo.geo.coords.latitude,
					lng: item.geo.geo.coords.longitude
				})

				markers[item.userid].setIcon(new window.H.map.DomIcon('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-pin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" class="svg-inline--fa fa-map-pin fa-w-9 fa-2x mapPinSvg user-status-'+item.status_id+' "><path fill="currentColor" d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z" class=""></path></svg>'))


				this.state.markers[item.userid].setData("<p class='agent-bubble'>Agent : "+item.username+
				"<br />Status : "+that.state.users[i].status+
				"<br />Localisé : "+moment(moment.unix(that.state.users[i].geo.update_time)).fromNow().toString()+
				"</p>")

			} else if(item.geo) {

			
				markers[item.userid] =  new window.H.map.DomMarker({
					lat: item.geo.geo.coords.latitude,
					lng: item.geo.geo.coords.longitude
				})

				markers[item.userid].setIcon(new window.H.map.DomIcon('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-pin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" class="svg-inline--fa fa-map-pin fa-w-9 fa-2x mapPinSvg user-status-'+item.status_id+' "><path fill="currentColor" d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z" class=""></path></svg>'))

				markers[item.userid].setData("<p class='agent-bubble'>Agent : "+item.username+
										 "<br />Status : "+that.state.users[i].status+
										 "<br />Localisé : "+moment(moment.unix(that.state.users[i].geo.update_time)).fromNow().toString()+
										 "</p>")

				console.log(markers)

				this.setState({
					markers: markers
				}, function(state) {
					console.log('new state ', that.state)

					if(that.state.markers[item.userid]) {

						that.state.markers[item.userid].addEventListener('tap', function(e) {

							var bubble =  new window.H.ui.InfoBubble(e.target.getPosition(), {
							// read custom data
								content: e.target.getData()
							});
							// show info bubble
							that.ui.addBubble(bubble);
							console.log('enter event ', item.userid	)
						})

						that.state.markers[item.userid].addEventListener('pointerleave', function(e) {
							console.log('leave event ', item.userid	)
						})

						that.map.addObject(that.state.markers[item.userid]);
					}
				})
			}
				
			
		})
		
			

		
	}


	updateFilter(e) {
		console.log(e)
		const that = this;
		const users = this.state.users;
		let value;

		if(e) {
			value = e.target.value;
			this.setState({
				filter: value
			})
		} else {
			value = this.state.filter;
		}

		if(value === "all") {

			Object.keys(that.state.markers).forEach(key => {
				that.state.markers[key].setVisibility(true)

				// const user = users.filter(user => user.userid === key)[0];

				// if(user.status !== "Online")
			})

		} else if(value === "duty") {

			

			Object.keys(that.state.markers).forEach(key => {

				const user = users.filter(user => user.userid === parseInt(key))[0];

				if(user.task === 0)
					that.state.markers[key].setVisibility(false)
				else
					that.state.markers[key].setVisibility(true)

			})

		} else {

			

			Object.keys(that.state.markers).forEach(key => {

				const user = users.filter(user => user.userid === parseInt(key))[0];

				if(user.status !== "Online")
					that.state.markers[key].setVisibility(false)
				else
					that.state.markers[key].setVisibility(true)

			})

		}



	}


	getTimestamp() {
		var now = new Date();
		return parseInt(now.getTime()/1000)
	}

	componentDidMount() {

		
		const that = this;

		this.loadAgents();

		this.agentInterval = setInterval(function() {
			var now = new Date();
			if(that.getTimestamp() - that.state.lastUpdate > 15) {
				console.log('int load')
				that.loadAgents();
			}
		}, 3500)



		this.platform = new window.H.service.Platform(this.state);
		var layer = this.platform.createDefaultLayers();
		var container = document.querySelector('#agents-map');

		this.map = new window.H.Map(container, layer.normal.map, {
			center: this.state.center,
			zoom: this.state.zoom,
		});
	
		var events = new window.H.mapevents.MapEvents(this.map);
		
		var behavior = new window.H.mapevents.Behavior(events);
		
		this.ui = new window.H.ui.UI.createDefault(this.map, layer)


	}
	

	toggleOverlay(e) {
		this.setState({showOverlay: !this.state.showOverlay});
	}


	loadAgents(p = this.state.current) {
		const that = this;
		var now = new Date();

		this.setState({
			lastUpdate: this.getTimestamp()
		}, function() {

			api.get('/api/users/map?token='+that.props.user.token)
			.then(function(response) {
				if(response.data.success) {
					that.setState({
						users: response.data.users
					}, function() {
						that.setMarkers()
						that.updateFilter();
					});
				} else {
					that.props.setAuth(response.data);
				}

				that.props.doneLoading();
			});
		});

		
	}
 

	

	render() {
		const toggleOverlay = this.toggleOverlay;
		const showOverlay = this.state.showOverlay;
		const data = this.state;
		return(
			<div>


			{!this.props.auth ? <Redirect to="/login" /> :  ""}

			<div className={showOverlay ? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content col-md-4 offset-md-4"}>
					<p>Overlay content</p>
				</div>
			</div>


			<div className={"container-header"}>
			<Trans i18nKey="agents_map" />
			</div>
				
			<div className={"clear"}></div>

			<h4>Filters</h4>
			<input onChange={this.updateFilter} name="filter" value="all" type="radio" checked={this.state.filter === "all"} />
			<span className={"ml-1"}><Trans i18nKey="filter_show_all" /></span> <br />
			<input onChange={this.updateFilter} name="filter" value="duty" type="radio" checked={this.state.filter === "duty"} /> 
			<span className={"ml-1"}><Trans i18nKey="filter_show_onduty" /></span>
			<br />
			<input onChange={this.updateFilter} name="filter" value="available" type="radio" checked={this.state.filter === "available"} /> 
			<span className={"ml-1"}><Trans i18nKey="filter_show_available" /></span>
			<br />
			<br />

			<div style={{height: '500px'}} id={"agents-map"}></div>


			
			</div>
		)
	}
}



const AgentListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <AgentList {...props} socket={socket} />}
	</SocketContext.Consumer>
  )
	
//export default YourDeeplyNestedComponentWithSocket

export default connect(mapStateToProps, mapDispatchToProps)(AgentListConsumer)