import React, { Component } from 'react';


import Datetime from 'react-datetime';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMapPin,  faCommentAlt,  faTag, faList, faMapMarker, faPhone, faUser, faTable, faUserTag } from '@fortawesome/free-solid-svg-icons'
import '../../../react-datetime.css';
import GeoInput from '../Utils/GeoInput';
import { Trans } from "react-i18next";

import api from '../../Utils/Api';



class TaskNew extends Component {

	constructor(props) {
		super(props);

		this.state = {
			single: {
				name: "",
				phone: "",
				address: "",
				geo_lat: "",
				geo_lng: ""
			},
			selectedType: null,
			pinOnMap: false,
			pinForTask: 'single',
			geo_lat: null,
			geo_lng: null,
			app_id: "sodMSfpEnNEZfHC7oV0M",
			app_code: "noVfKVAMsfkjYV3Cv6jmnA",
			useHTTPS: true,
            center: {
                lat: 36.75,
                lng: 3.04,
			},
			zoom: 5,
			address: "",
			last_use: "component",
			pickup_lat: "",
			pickup_lng: "",
			delivery_lat: "",
			delivery_lng: "",
			subtasks: {
				"pickup": {
					id:0,
					address: "",
					address_lat: "",
					address_lng: "",
					marker: null,
					name: "",
					phone: "",
					type: null,
				},
				"delivery": {
					id:0,
					address: "",
					address_lat: "",
					address_lng: "",
					marker: null,
					name: "",
					phone: "",
					type: null,
				}

			},
			emptySubtask: {
				id:0,
				address: "",
				address_lat: "",
				address_lng: "",
				name: "",
				phone: "",
				marker: null,
				type: null,
			}
			
		}
		this.taskForm = React.createRef();
		this.submitTask = this.submitTask.bind(this);
		this.selectAddress = this.selectAddress.bind(this);
		this.pinToggle = this.pinToggle.bind(this)


		this.platform = null;
		this.map = null;
		this.marker = null;
		this.fromMarker = null;
		this.toMarker = null;

		this.typeChange = this.typeChange.bind(this)
		this.pickStop = this.pickStop.bind(this);
		this.selectFromBook = this.selectFromBook.bind(this)
	}

	selectFromBook(e, type = "single") {
		console.log(type)
		if(type === "single") {
			
			this.setState({
				single: {
					name: "test",
					phone: '06656',
					address: "Address test",
					geo_lat: "",
					geo_lng: ""
				},
				address: "addres etest"
				
			})

		} else if(type === "pickup") {

			this.setState({
				pickup: {
					name: "test",
					phone: '06656',
					address: "Address test"
				},
				pickup_lat: "",
				pickup_lng: ""
			})

		} else if(type === "delivery") {

			this.setState({
				delivery: {
					name: "test",
					phone: '06656',
					address: "Address test"
				},
				delivery_lat: "",
				delivery_lng: ""
			})

		}
		
	}
	
	componentDidMount() {


		this.platform = new window.H.service.Platform(this.state);

        var layer = this.platform.createDefaultLayers();
        var container = document.querySelector('#here-maps');

		

		this.map = new window.H.Map(container, layer.normal.map, {
			center: this.state.center,
			zoom: this.state.zoom,
			});
		
		var events = new window.H.mapevents.MapEvents(this.map);
		// eslint-disable-next-line
		var behavior = new window.H.mapevents.Behavior(events);
		// eslint-disable-next-line
		var ui = new window.H.ui.UI.createDefault(this.map, layer)

		const that = this;

		this.map.addEventListener('tap', function(evt) {
			// Log 'tap' and 'mouse' events:
			if(that.state.pinOnMap) {
				var coord = that.map.screenToGeo(evt.currentPointer.viewportX,
					evt.currentPointer.viewportY);
				
				if(that.state.pinForTask === 'pickup') {
					
					if(that.fromMarker) {
						that.fromMarker.setPosition(coord)
					} else {
						that.fromMarker = new window.H.map.Marker(coord);
						that.map.addObject(that.fromMarker);
					}

					const sub = that.state.subtasks;

					sub.pickup.address_lat = coord.lat;
					sub.pickup.address_lng = coord.lng;

					that.setState({
						pickup_lat: coord.lat,
						pickup_lng: coord.lng,
						subtasks: sub,
						pinOnMap: false
					});


				} else if(that.state.pinForTask === 'delivery') {

					if(that.toMarker) {
						that.toMarker.setPosition(coord)
					} else {
						that.toMarker = new window.H.map.Marker(coord);
						that.map.addObject(that.toMarker);
					}


					const sub = that.state.subtasks;

					sub.pickup.address_lat = coord.lat;
					sub.pickup.address_lng = coord.lng;

					that.setState({
						delivery_lat: coord.lat,
						delivery_lng: coord.lng,
						subtasks: sub,
						pinOnMap: false
					});


				} else if(that.state.pinForTask === 'single') {

					if(that.marker) {
						that.marker.setPosition(coord)
					} else {
						that.marker = new window.H.map.Marker(coord);
						that.map.addObject(that.marker);
					}


					that.setState({
						geo_lat: coord.lat,
						geo_lng: coord.lng,
						pinOnMap: false
					})

				}


				

				api.get('https://nominatim.openstreetmap.org/reverse?lat='+coord.lat+'&lon='+coord.lng+'&format=json')
				.then(function(response) {
					if(!response.data.error) {
						if(that.state.pinForTask === 'pickup')
							that.setState(state => ({
								subtasks: {
									...state.subtasks,
									pickup: {
										...state.subtasks.pickup,
										address: response.data.display_name
									}
								}
							}))
							
						else if(that.state.pinForTask === 'delivery')
							that.setState(state => ({
								subtasks: {
									...state.subtasks,
									delivery: {
										...state.subtasks.delivery,
										address: response.data.display_name
									}
								}
							}))
						else 
							that.setState({
								address: response.data.display_name
							})
						 
					} else {
						that.setState({
							address: "Custom address"
						})
					}
				})
				

				//that.map.setCenter(coord);
			}

		});


	}

	typeChange(e) {
		if(e.value === 6) {
			this.toMarker && this.toMarker.setVisibility(true)
			this.fromMarker && this.fromMarker.setVisibility(true)
			this.marker && this.marker.setVisibility(false)
		} else {

			this.toMarker && this.toMarker.setVisibility(false)
			this.fromMarker && this.fromMarker.setVisibility(false)
			this.marker && this.marker.setVisibility(true)

		}



		this.setState({ selectedType: e.value})
	}



	selectAddress(addr, e) {

		const obj = {
			lat: addr.lat,
			lng: addr.lon
		};

		


		if(obj.lat) {

			if(e && e === "pickup") {
				this.map.setCenter(obj)
	
				if(!this.fromMarker) {
					this.fromMarker = new window.H.map.Marker(obj);
					this.map.addObject(this.fromMarker);
				} else {
					this.fromMarker.setPosition(obj)
				}

				this.setState({
					pickup_lat: addr.lat,
					pickup_lng: addr.lon
				})

			} else if (e && e === "delivery") {

				this.map.setCenter(obj)
	
				if(!this.toMarker) {
					this.toMarker = new window.H.map.Marker(obj);
					this.map.addObject(this.toMarker);
				} else {
					this.toMarker.setPosition(obj)
				}

				this.setState({
					delivery_lat: addr.lat,
					delivery_lng: addr.lon
				})

			} else {
				this.map.setCenter(obj)
	
				if(!this.marker) {
					this.marker = new window.H.map.Marker(obj);
					this.map.addObject(this.marker);
				} else {
					this.marker.setPosition(obj)
				}

				this.setState({
					geo_lat: addr.lat,
					geo_lng: addr.lon
				})
			}

			this.map.setZoom(12);
		}
		

		
	}

	submitTask(e) {
		e.preventDefault();
		
		console.log(this.state)
		
		this.props.handlers.submitTask(e, this.taskForm.current);
	}

	
	pinToggle(e) {

		this.setState({
			pinForTask: e,
			pinOnMap: !this.state.pinOnMap
		})

	}

	pickStop(i) {

		const item = i | this.state.emptySubtask 
		return (<React.Fragment>
			<div className={"task-single"}>

				<div className={"form-group"}>
					
					<h4>Pickup</h4>
				</div>

				<div className={"form-group"}>

					<div className={"row"}>

						<div className={"col-md-6"}>
							<label><Trans i18nKey="label_name" /><FontAwesomeIcon icon={faUser} className={"form-label-icon"} /></label>
							<input defaultValue={this.state.subtasks.pickup.name} type="text" name="pickup_task_client_name" className={"form-control pickup_task_client_name"} />
						</div>

						<div className={"col-md-6"}>
							<label><Trans i18nKey="user_phone" /><FontAwesomeIcon icon={faPhone} className={"form-label-icon"} /></label>
							<input defaultValue={this.state.subtasks.pickup.phone} type="text" name="pickup_task_client_phone" className={"form-control pickup_task_client_phone"} />
						</div>

					</div>
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="label_address" />
						<FontAwesomeIcon icon={faMapMarker} className={"form-label-icon"} />
						<button 
						type="button"
						onClick={e => this.pinToggle('pickup')} 
						className={"float-right btn btn-link"}
						style={!this.state.pinOnMap || this.state.pinForTask !== "pickup" ? {color:"#888"} : {}}>

						Pin on map

						<FontAwesomeIcon icon={faMapPin} style={{marginLeft: '10px'}} />
						</button>
					</label>

					<input value={this.state.pickup_lat ? this.state.pickup_lat : ""} type="hidden" name="pickup_lat" className={"form-control"} />
					<input value={this.state.pickup_lng ? this.state.pickup_lng : ""} type="hidden" name="pickup_lng" className={"form-control"} />

					<GeoInput 	
					onGeoSelect={e => this.selectAddress(e, 'pickup')} 
					name="pickup_task_address" 
					inputValue={this.state.subtasks.pickup.address} 
					className={"form-control"} />
					
				</div>


				<span className={"add-subtask"}></span>
			</div>
			
			
			<div className={"task-single"}>

				<div className={"form-group"}>	
					<h4>Delivery</h4>
				</div>

				<div className={"form-group"}>
					<div className={"row"}>

						<div className={"col-md-6"}>
						<label><Trans i18nKey="label_name" /><FontAwesomeIcon icon={faUser} className={"form-label-icon"} /></label>
						<input type="text" name="delivery_task_client_name" className={"form-control"} />
						</div>

						<div className={"col-md-6"}>
						<label><Trans i18nKey="user_phone" /><FontAwesomeIcon icon={faPhone} className={"form-label-icon"} /></label>

						<input type="text" name="delivery_task_client_phone" className={"form-control"} />
						</div>

					</div>
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="label_address" /> 
						<FontAwesomeIcon icon={faMapMarker} className={"form-label-icon"} />
						<button 
						type="button"
						onClick={e => this.pinToggle('delivery')} 
						className={"float-right btn btn-link"}
						style={!this.state.pinOnMap || this.state.pinForTask !== "delivery" ? {color:"#888"} : {}}>

						Pin on map

						<FontAwesomeIcon icon={faMapPin} style={{marginLeft: '10px'}} />
						</button>
					</label>

					<input value={this.state.delivery_lat ? this.state.delivery_lat : ""} type="hidden" name="delivery_lat" className={"form-control"} />
					<input value={this.state.delivery_lng ? this.state.delivery_lng : ""} type="hidden" name="delivery_lng" className={"form-control"} />

					<GeoInput 	
					onGeoSelect={e => this.selectAddress(e, 'delivery')}
					name="delivery_task_address" 
					inputValue={this.state.subtasks.delivery.address} 
					className={"form-control"} />
					
				</div>

				
				<span className={"add-subtask"}></span>
			</div>
			</React.Fragment>)
	}

	render() {


		
		const refs = this.props.refs;
		const data = this.props.data;
		return(
			<React.Fragment>
			<div className={"container-header"}>
				<Trans i18nKey="button_new_task" />
			</div>

			<form ref={this.taskForm} onSubmit={this.submitTask}>
			<div className={"row"}>
			<div className={"col-md-6 taskForm"}>
				

				<div className={"form-group"}>
					<label><Trans i18nKey="task_name" /><FontAwesomeIcon icon={faTag} className={"form-label-icon"} /> </label>
					<input type="text" name="task_name" className={"form-control"} />
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="task_type" /><FontAwesomeIcon icon={faList} className={"form-label-icon"} /> </label>
					<Select
					onChange={this.typeChange}
					name="task_type"
					options={data.types}
					isLoading={data.types.length === 0}
					/>
				</div>

				{this.state.selectedType === 6
				? this.pickStop()
				: (<React.Fragment>

					<div className={"form-group"}>
						<div className={"row"}>

							<div className={"col-md-6"}>
							<label><Trans i18nKey="label_name" /><FontAwesomeIcon icon={faUser} className={"form-label-icon"} />
							
							{/* <button type="button" class="float-right btn btn-link address-book-link" onClick={this.selectFromBook}>Address book</button>
							 */}
							
							</label>
							<input defaultValue={this.state.single.name} type="text"  name="task_client_name" className={"form-control task_client_name"} />
							</div>

							<div className={"col-md-6"}>
							<label><Trans i18nKey="user_phone" /><FontAwesomeIcon icon={faPhone} className={"form-label-icon"} /></label>

							<input defaultValue={this.state.single.phone} type="text" name="task_client_phone" className={"form-control task_client_phone"} />
							</div>

						</div>
					</div>

					<div className={"form-group"}>
						<label><Trans i18nKey="label_address" /> 
							<FontAwesomeIcon icon={faMapMarker} className={"form-label-icon"} />
							<button 
							type="button"
							onClick={e => this.pinToggle('single')} 
							className={"float-right btn btn-link"}
							style={!this.state.pinOnMap ? {color:"#888"} : {}}>

							Pin on map

							<FontAwesomeIcon icon={faMapPin} style={{marginLeft: '10px'}} />
							</button>
						</label>

						<input value={this.state.geo_lat ? this.state.geo_lat : ""} type="hidden" name="geo_lat" className={"form-control"} />
						<input value={this.state.geo_lng ? this.state.geo_lng : ""} type="hidden" name="geo_lng" className={"form-control"} />

						<GeoInput 	
						onGeoSelect={this.selectAddress} 
						name="task_address" 
						inputValue={this.state.address} 
						className={"form-control"} />
						
					</div>

					</React.Fragment>
				)}

				


				<div className={"form-group d-none"}>
					<div className={"row"}>
					
						<div className={"col-md-6"}>
						<label>Latitude </label>
						<input disabled value={this.state.geo_lat ? this.state.geo_lat : ""} type="text" name="address_lat" className={"form-control"} />

						<input value={this.state.geo_lat ? this.state.geo_lat : ""} type="hidden" name="geo_lat" className={"form-control"} />
						</div>

						<div className={"col-md-6"}>
						<label>Longitude </label>
						<input 
						disabled 
						value={this.state.geo_lng ? this.state.geo_lng : ""} type="text" name="address_lng" className={"form-control"} />

						<input value={this.state.geo_lng ? this.state.geo_lng : ""} type="hidden" name="geo_lng" className={"form-control"} />
						</div>

					</div>
				</div>




				<div className={"form-group"}>
					<div className={"row"}>

						<div className={"col-md-6"}>
						<label>Start <FontAwesomeIcon icon={faTable} className={"form-label-icon"} /></label>
						<Datetime dateFormat="YYYY-MM-DD" inputProps={{ name:"task_start_date", className:"form-control" }} />						
						</div>

						<div className={"col-md-6"}>
						<label><Trans i18nKey="label_end" /> <FontAwesomeIcon icon={faTable} className={"form-label-icon"} /></label>
						<Datetime dateFormat="YYYY-MM-DD" inputProps={{ name:"task_end_date", className:"form-control" }} />
						</div>

					</div>

				</div>

				<div className={"form-group"}>

					<label><Trans i18nKey="special_instructions" /> <FontAwesomeIcon icon={faCommentAlt} className={"form-label-icon"} /></label>
					<textarea type="text" className={"form-control"} name="instruction" />
					
				</div>


				<div className={"form-group"}>
					<label><Trans i18nKey="task_assign_to_agent" /><FontAwesomeIcon icon={faUserTag} className={"form-label-icon"} /></label>
					<Select
					name="task_agent"
					options={data.agents}
					isLoading={data.agents.length === 0}
					/>
				</div>

				<div className={"form-group"}>
					<button type="submit" className={"btn btn-block btn-success float-right"}><Trans i18nKey="button_add_task" /></button>
				</div>

		
			</div>

			<div className={"col-md-6 taskForm"}>
				
				<div style={{height: "400px", borderColor: '#ccc', borderWidth: "1px", borderRadius: "2px", borderStyle: "solid"}} id="here-maps"></div>
			</div>


			</div>

			</form>
			

			</React.Fragment>
		)
	}
}


export default TaskNew