import React from "react"

import { connect } from 'react-redux';
import api from '../../Utils/Api';
import { Line, Bar, HorizontalBar  } from 'react-chartjs-2';
import {  startLoading, doneLoading } from './../../Redux/actions/index';
import Select from "react-select";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


//const moment = require("moment");
const $ = require("jquery")


const mapStateToProps = state => {
	return {
		user: state.user
	}
} 

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}

class ManagerDashboard extends React.Component {


	constructor(props) {
        super(props);
        
		this.state = {
            pm: {
                manholes_done: 0,
                manholes_planned: 0,
                done_ontime: 0,
                done_notontime: 0,
                total: 0,
                not_done: 0,
                chart: []
            },
            power: {
                types: {},
                groupes: {},
                equipement: {}
            },
            ac: {
                type: {},
                marque: {},
                modele: {}
            },
            typology: {},
            shelter: {},
            bss: {},
			sites: 0,
            agents: 0,
            managers: 0,
            filters: {
                agents: [],
                managers: [],
                cluster_managers: [],
                wilayas: [],
                manager: "",
                cluster_manager: "",
                wilaya: "",
                start_date: "",
                end_date: ""
            },
            tasks: {},
            agents_performance: []
		}

		props.isLoading();
	}

	componentDidMount() {
		
        const that = this;
        
        api.get('/api/clustermanager/dashboard/form')
		.then(function(response) {

            if(response.data.success) {
                that.setState({ filters: { ...response.data.data,  
                                            manager: "",
                                            cluster_manager: "",
                                            wilaya: "",
                                            start_date: "",
                                            end_date: ""
                                        } 
                                });
            }    
        })
        
        var today = new Date();
        var date_fin = today.toISOString().substr(0,10);

        var now = new Date();
        var quarter = Math.floor((now.getMonth() / 3));
        var firstDate = new Date(now.getFullYear(), quarter * 3, 1);
        var date_debut = new Date(firstDate.getTime() - (firstDate.getTimezoneOffset() * 60000)).toISOString().substr(0,10);
        
            

            const filters = "manager="+this.state.filters.manager+
                            "&cluster_manager="+this.state.filters.cluster_manager+
                            "&wilaya="+this.state.filters.wilaya+
                            "&start_date="+date_debut+
                            "&end_date="+date_fin


		api.get('/api/clustermanager/dashboard/data?'+filters)
		.then(function(response) {
			if(response.data.success)
				that.setState(response.data.data)
        }).then(() => that.props.doneLoading());
        



    }

    updateFilter = (name, e) => {

        const value = e.target ? e.target.value : e.value;
        
        const { filters } = this.state;

        filters[name] = value;
        //console.log(value)

        this.setState({ filters: filters })




    }

    filterDashboard = () => {

        const that = this;

        const filters = "manager="+this.state.filters.manager+
                        "&cluster_manager="+this.state.filters.cluster_manager+
                        "&wilaya="+this.state.filters.wilaya+
                        "&start_date="+this.state.filters.start_date+
                        "&end_date="+this.state.filters.end_date
        
        this.props.isLoading({
            message: "Mise à jour du dashboard",
            animated: true
        });

        console.log(filters)

        api.get('/api/clustermanager/dashboard/data?'+filters)
		.then(function(response) {
			if(response.data.success)
				that.setState(response.data.data)
        }).then(() => that.props.doneLoading());
    }
    

    exportAll = () => {

        var el = document.querySelectorAll(".exportable");
        
        var doc = new jsPDF('p', 'px',  "a4", false, true);
        var top = 10;

        
        
        this.props.isLoading({
            message: "Génération du fichier PDF en cours",
            animated: true
        });

        const that = this;

        setTimeout(function() {

            var promises = Array(...el).map(element => {

                return new Promise(function(resolve, reject) {
                    html2canvas(element).then(canvas => {
    
        
                        var imgData = canvas.toDataURL(
                            'image/png');
                        
                        const imgProps= doc.getImageProperties(imgData);
                        const pdfWidth = doc.internal.pageSize.getWidth() - 10;
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
                        
                        resolve({
                            data: imgData,
                            width: pdfWidth,
                            height: pdfHeight
                        });
                        
                        
                        
                    });
    
    
                });
    
                
            })


            Promise.all(promises).then(data => {
    
                for (var ind in data) {
    
                    
                    if( top+data[ind].height > doc.internal.pageSize.getHeight()) {
                        doc.addPage();
                        top = 10;
                    } 

                    
                    doc.addImage(data[ind].data, 'PNG', 5, top, data[ind].width, data[ind].height, undefined, "MEDIUM");
                    top = top+data[ind].height+10;
                }
                doc.save('dashboard.pdf');
                that.props.doneLoading();
            })
            
        }, 50)

    
    }

    singleExport = (e) => {

        var el = $(e.target).parent().parent();//e.target;//document.querySelectorAll(".exportable");
        
        var doc = new jsPDF('p', 'px',  "a4", false, true);
        var top = 10;

        
        this.props.isLoading({
            message: "Génération du fichier PDF en cours",
            animated: true
        });

        const that = this;

        setTimeout(function() {

            var promises = Array(...el).map(element => {

                return new Promise(function(resolve, reject) {
                    html2canvas(element).then(canvas => {
    
                        var imgData = canvas.toDataURL(
                            'image/png');
                        
                        const imgProps= doc.getImageProperties(imgData);
                        const pdfWidth = doc.internal.pageSize.getWidth() - 10;
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
                        
                        resolve({
                            data: imgData,
                            width: pdfWidth,
                            height: pdfHeight
                        });
                        
                        
                    });
    
                });
    
                
            })


            Promise.all(promises).then(data => {
                for (var ind in data) {
                    if( top+data[ind].height > doc.internal.pageSize.getHeight()) {
                        doc.addPage();
                        top = 10;
                    } 

                    doc.addImage(data[ind].data, 'PNG', 5, top, data[ind].width, data[ind].height, undefined, "MEDIUM");
                    top = top+data[ind].height+10;
                }

                doc.save('dashboard.pdf');
                that.props.doneLoading();
            })
            
        }, 50)


    }

    
    render() {
        var today = new Date();
        var date_fin = today.toISOString().substr(0,10);
        today.setMonth(today.getMonth() - 3)
        //var date_debut = new Date(today.getFullYear(), today.getMonth(), 2);
        //date_debut = date_debut.toISOString().substr(0,10);

        var now = new Date();
        var quarter = Math.floor((now.getMonth() / 3));
        var firstDate = new Date(now.getFullYear(), quarter * 3, 1);
        //var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);

        //console.log(endDate.toISOString().substr(0,10))
        //date_debut = date_debut.toISOString().substr(0,10);
 
        ////solution  
        var date_debut = new Date(firstDate.getTime() - (firstDate.getTimezoneOffset() * 60000)).toISOString().substr(0,10);
        //console.log(date_debut)


		return (<div className={"dashboard-page"}>
		<div className={"container-header nmb"}>
			Dashboard

            <button onClick={e => this.exportAll()} className={"cbtn float-right"} >Exporter le dashboard</button>
		</div>
        

        <div className={"row"}>
				<div className={"filters-container"}>


                    <div className={"col-md-2"}>
						<div className={"form-group"}>
							<label>Début</label>
							<input onChange={e => this.updateFilter('start_date', e)} defaultValue={date_debut} type="date" className={"form-control"} />
						</div>
					</div>

					<div className={"col-md-2"}>
						<div className={"form-group"}>
							<label>Fin</label>
							<input onChange={e => this.updateFilter('end_date', e)} defaultValue={date_fin} type="date" className={"form-control"} />
						</div>
					</div>

                    <div className={"col-md-2"}>
						<div className={"form-group"}>
							<label>Wilaya</label>
							<Select
                            classNamePrefix={"custom-select"}
                            onChange={e => this.updateFilter('wilaya', e)}
							options={this.state.filters.wilayas}
							/>
						</div>
					</div>

                    <div className={"col-md-2 filter-btn"}>
						<button onClick={this.filterDashboard} className={"cbtn"}>Filtrer</button>
					</div>


                </div>
        </div>

        

        <div className={"row dashboard-row exportable"}>

			<div className={"col-md-4"}>

                <div className={"single-chart"}>
                    <h3>Links : <span className={"single-value"}>{this.state.filtered_sites}</span></h3>
                </div>

            </div>

            <div className={"col-md-4"}>

                <div className={"single-chart"}>
                    <h3>Agents : <span className={"single-value"}>{this.state.agents}</span></h3>
                </div>

            </div>


        </div>
        

        


        <div className={"exportable"}>
            <h2>Manholes <button onClick={this.singleExport} className={"export-single"}>Export</button></h2>
            <div className={"row dashboard-row"}>



                <div className={"col-md-4 "}>

                    <div className={"single-kpi"}>
                        <span className={"dashboard-kpi-value"}>{this.state.pm.manholes_planned}</span>
                        <span className={"dashboard-kpi-label"}>Manholes Planned</span>
                    </div>

                </div>


                <div className={"col-md-4 "}>

                    <div className={"single-kpi"}>
                        <span className={"dashboard-kpi-value"}>{Math.floor( ( (this.state.pm.manholes_done*100) / (this.state.pm.manholes_planned*100) ) *10000 )/100+"%"}</span>
                        <span className={"dashboard-kpi-label"}>Done/Planned</span>
                    </div>

                </div>

               
               <div className={"col-md-4 "}>

                    <div className={"single-kpi"}>
                        <span className={"dashboard-kpi-value"}>{Math.floor( ( (this.state.pm.manholes_done*100) / (this.state.agents*100) ) *100)/100 }</span>
                        <span className={"dashboard-kpi-label"}>Done par technicien</span>
                    </div>

                </div>


            </div>


        



        <div className={"row dashboard-row exportable"}>
            
			<div className={"col-md-12"}>
                <div className={"single-chart"} >

                    <Bar 
                    
                        data={{
                            labels: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                            datasets: [
                              {
                                label: 'Planned',
                                borderColor: 'rgba(231, 23, 34, 1)',
                                borderWidth: 1,
                                hoverBackgroundColor: 'rgba(231, 23, 34, .8)',
                                backgroundColor: 'rgba(231, 23, 34, .7)',
                                hoverBorderColor: 'rgba(231, 23, 34, 1)',
                                data: Object.values(this.state.pm.chart).map(item => item.plan)
                              },
                              {
                                label: 'Réalisé',
                                borderColor: 'rgba(69, 92, 116, 1)',
                                borderWidth: 1,
                                hoverBackgroundColor: 'rgba(69, 92, 116, .8)',
                                backgroundColor: 'rgba(69, 92, 116, .7)',
                                hoverBorderColor: 'rgba(69, 92, 116, 1)',
                                data: Object.values(this.state.pm.chart).map(item => item.done)
                              }
                            ]
                          }}
                    />

                </div>
            </div>
        </div>

        </div>

        <div className={"exportable"}>
            
        <h2>Agents Performance <button onClick={this.singleExport} className={"export-single"}>Export</button></h2>
        <div className={"row dashboard-row exportable"}>

			<div className={"col-md-12"}>
                <div className={"single-chart"} >

                    <Bar 
                    
                        data={{
                            labels: Object.values(this.state.agents_performance).map(item => item.username),
                            datasets: 
                                [
                                {
                                    label: "Manholes Planned",
                                    borderColor: 'rgba(69, 92, 116, 1)',
                                    borderWidth: 1,
                                    backgroundColor: 'rgba(69, 92, 116, .9)',
                                    hoverBackgroundColor: 'rgba(69, 92, 116, .8)',
                                    hoverBorderColor: 'rgba(69, 92, 116, 1)',
                                    data: Object.values(this.state.agents_performance).map(item => item.planned)
                                },
                                {
                                    label: "Manholes Done",
                                    borderColor: 'rgba(69, 92, 116, 1)',
                                    borderWidth: 1,
                                    backgroundColor: 'rgba(231, 23, 34, .7)',
                                    hoverBackgroundColor: 'rgba(231, 23, 34, 1)',
                                    hoverBorderColor: 'rgba(69, 92, 116, 1)',
                                    data: Object.values(this.state.agents_performance).map(item => item.done)
                                }
                            ]


                             
                          }}
                    />

                </div>
            </div>
        </div>



        </div>
        
        


		</div>)
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDashboard)

