import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import { Trans } from "react-i18next";


const remote = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://salim.tictechs.tech';


const mapStateToProps = state => {
	return {
        user: state.user,
        auth: state.auth
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
        doneLoading: () => dispatch(doneLoading()),
        setAuth: e => dispatch(setAuthAction(e))
	}
}


class BillingDetail extends Component {


    constructor(props) {
        super(props);

        this.state = {
           data: {}
        };

        this.approveBill = this.approveBill.bind(this)

        props.isLoading();
    }

    componentDidMount() {
        const that = this;
        api.get('/api/bill/'+this.props.match.params.billid)
        .then(function(response) {
            
            
            that.setState({
                data: response.data.data
            })
            that.props.doneLoading();
        });
       
    }

    approveBill(billid) {
        const that = this;
        this.props.isLoading();
        api.post('/api/bill/'+billid+'/approve')
        .then(function(response) {
            console.log(response.data)
            that.props.doneLoading();
        })

        return false;
    }

    rejectBill(billid) {
        const that = this;
        this.props.isLoading();

        api.post('/api/bill/'+billid+'/reject')
        .then(function(response) {
            console.log(response.data)
            that.props.doneLoading();
        })

        return false;
    }

  

    render() {
        return (<React.Fragment>

            <div>

				<div className={"container-header"}>
                    <Trans i18nKey="label_payment" />
				</div>
                <div className={"col-md-12"}>

                    <div className={"row mb-2"}>
                        <div className={"col-md-2"}>Nom </div>
                        <div className={"col-md-3"}>{this.state.data.last_name} </div>
                    </div>

                    <div className={"row mb-2"}>
                        <div className={"col-md-2"}>Prénom </div>
                        <div className={"col-md-3"}>{this.state.data.first_name} </div>
                    </div>

                    <div className={"row mb-2"}>
                        <div className={"col-md-2"}>Raison social </div>
                        <div className={"col-md-3"}>{this.state.data.raison_social} </div>
                    </div>
                    
                    <div className={"row mb-2"}>
                        <div className={"col-md-2"}>Date & heure </div>
                        <div className={"col-md-3"}>{this.state.data.created_at} </div>
                    </div>
                    <div className={"row mb-2"}>
                        <div className={"col-md-2"}>Méthode </div>
                        <div className={"col-md-3"}>{this.state.data.method} </div>
                    </div>
                    <div className={"row mb-2"}>
                        <div className={"col-md-2"}>Montant </div>
                        <div className={"col-md-3"}>{this.state.data.amount} </div>
                    </div>
                    <div className={"row mb-2"}>
                        <div className={"col-md-2"}>Status </div>
                        <div className={"col-md-3"}>{this.state.data.status} </div>
                    </div>

                </div>



            </div>
        </React.Fragment>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetail)