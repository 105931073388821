import React from "react"


class AgentsPerformance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agents: {}
        }
    }



    render() {
        return <>Agents Dashboard</>
    }

}

export default AgentsPerformance;