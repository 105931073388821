import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink, Redirect, Route,  Switch} from 'react-router-dom';
import AccountPersonal from './AccountPersonal';
import AccountPrivacy from './AccountPrivacy';
import AccountSecurity from './AccountSecurity';
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import {  Trans } from "react-i18next";

function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
  };
}


 class UserAccount extends Component {

 	constructor(props) {
 		super(props);
 		

 		this.state = {
 			
 				username: "",
 				password: "",
 				email: "",
 				firstName: "",
 				lastName: "",
 				phone: "",
 				manager_username: "",
 				manager_fullname: "",
 				role: ""
 			
 		}

 	}


 	componentDidMount() {

 	}

 	
 	

	render() {
		return(

			<div className={"account-page"}>
				{!this.props.auth ? <Redirect to="/login" /> :  ""}
				<div className={"container-header nmb"}>
				<Trans i18nKey="label_account" />
				</div>
				<div className={"page-menu"}>
					<ul>
						<li><NavLink strict exact to="/account"><Trans i18nKey="menu_account" /></NavLink></li>
						{/* <li><NavLink strict exact to="/account/plan"><Trans i18nKey="menu_plan" /></NavLink></li>
						<li><NavLink strict exact to="/account/billing"><Trans i18nKey="account_billing" /></NavLink></li> */}
						{/* <li><NavLink strict exact to="/account/privacy"><Trans i18nKey="label_notifications" /></NavLink></li> */}
						<li><NavLink to="/account/security"><Trans i18nKey="menu_security" /></NavLink></li>
					</ul>
				</div>

				<Switch>
					<Route exact path='/account' component={AccountPersonal} />
					<Route exact path='/account/privacy' component={AccountPrivacy} />
					<Route exact path='/account/security' component={AccountSecurity} />
					{/* <Route exact path='/account/plan' component={AccountPlan} />
					<Route exact path='/account/billing' component={AccountBilling} /> */}
					
				</Switch>

			</div>

			
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);