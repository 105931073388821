import React, { Component } from 'react';


import Datetime from 'react-datetime';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMapPin,  faCommentAlt,  faTag, faList, faMapMarker, faPhone, faUser, faTable, faUserTag } from '@fortawesome/free-solid-svg-icons'
import '../../../react-datetime.css';
import GeoInput from '../Utils/GeoInput';
import { Trans } from "react-i18next";

import api from '../../Utils/Api';



class TaskNew extends Component {

	constructor(props) {
		super(props);

		this.state = {
			wilayas: [],
			single: {
				name: "",
				phone: "",
				address: "",
				geo_lat: "",
				geo_lng: ""
			},
			selectedType: null,
			pinOnMap: false,
			pinForTask: 'single',
			geo_lat: null,
			geo_lng: null,
			app_id: "sodMSfpEnNEZfHC7oV0M",
			app_code: "noVfKVAMsfkjYV3Cv6jmnA",
			useHTTPS: true,
            center: {
                lat: 36.75,
                lng: 3.04,
			},
			zoom: 5,
			address: "",
			last_use: "component",
			pickup_lat: "",
			pickup_lng: "",
			delivery_lat: "",
			delivery_lng: "",
			subtasks: {
				"pickup": {
					id:0,
					address: "",
					address_lat: "",
					address_lng: "",
					marker: null,
					name: "",
					phone: "",
					type: null,
				},
				"delivery": {
					id:0,
					address: "",
					address_lat: "",
					address_lng: "",
					marker: null,
					name: "",
					phone: "",
					type: null,
				}

			},
			emptySubtask: {
				id:0,
				address: "",
				address_lat: "",
				address_lng: "",
				name: "",
				phone: "",
				marker: null,
				type: null,
			},
			guards: []
			
		}

		this.addGuard = this.addGuard.bind(this);
		this.taskForm = React.createRef();
		this.submitTask = this.submitTask.bind(this);
		this.selectAddress = this.selectAddress.bind(this);
		this.pinToggle = this.pinToggle.bind(this)


		this.platform = null;
		this.map = null;
		this.marker = null;
		this.fromMarker = null;
		this.toMarker = null;

		this.typeChange = this.typeChange.bind(this)
		this.pickStop = this.pickStop.bind(this);
		this.selectFromBook = this.selectFromBook.bind(this)
	}

	selectFromBook(e, type = "single") {
		console.log(type)
		if(type === "single") {
			
			this.setState({
				single: {
					name: "test",
					phone: '06656',
					address: "Address test",
					geo_lat: "",
					geo_lng: ""
				},
				address: "addres etest"
				
			})

		} else if(type === "pickup") {

			this.setState({
				pickup: {
					name: "test",
					phone: '06656',
					address: "Address test"
				},
				pickup_lat: "",
				pickup_lng: ""
			})

		} else if(type === "delivery") {

			this.setState({
				delivery: {
					name: "test",
					phone: '06656',
					address: "Address test"
				},
				delivery_lat: "",
				delivery_lng: ""
			})

		}
		
	}
	
	addGuard = () => {
		let { guards } = this.state;
		guards.push({
			name: "",
			phone: ""
		})

		this.setState({
			guards: guards
		})
	}

	removeGuard = (index) => {
		let { guards } = this.state;
		guards.splice(index, 1)

		this.setState({
			guards: guards
		})
	}

	componentDidMount() {



//        var layer = this.platform.createDefaultLayers();
        var container = document.querySelector('#here-maps');

		
		
		const that = this;

		api.get('/api/clustermanager/dashboard/form')
		.then(function(response) {
			
			that.setState({
				wilayas: response.data.data.wilayas
			})
		});

		


	}

	typeChange(e) {
		if(e.value === 6) {
			this.toMarker && this.toMarker.setVisibility(true)
			this.fromMarker && this.fromMarker.setVisibility(true)
			this.marker && this.marker.setVisibility(false)
		} else {

			this.toMarker && this.toMarker.setVisibility(false)
			this.fromMarker && this.fromMarker.setVisibility(false)
			this.marker && this.marker.setVisibility(true)

		}



		this.setState({ selectedType: e.value})
	}



	selectAddress(addr, e) {

		const obj = {
			lat: addr.lat,
			lng: addr.lon
		};

		


		if(obj.lat) {

			
			this.map.setCenter(obj)

			if(!this.marker) {
				this.marker = new window.H.map.Marker(obj);
				this.map.addObject(this.marker);
			} else {
				this.marker.setPosition(obj)
			}

			this.setState({
				geo_lat: addr.lat,
				geo_lng: addr.lon
			})
			

			this.map.setZoom(12);
		}
		

		
	}

	submitTask(e) {
		e.preventDefault();
		
		console.log(this.state)
		
		this.props.handlers.submitTask(e, this.taskForm.current);
	}

	
	pinToggle(e) {

		this.setState({
			pinForTask: e,
			pinOnMap: !this.state.pinOnMap
		})

	}

	pickStop(i) {

		const item = i | this.state.emptySubtask 
		return (<React.Fragment>
			<div className={"task-single"}>

				<div className={"form-group"}>
					
					<h4>Pickup</h4>
				</div>

				<div className={"form-group"}>

					<div className={"row"}>

						<div className={"col-md-6"}>
							<label><Trans i18nKey="label_name" /><FontAwesomeIcon icon={faUser} className={"form-label-icon"} /></label>
							<input defaultValue={this.state.subtasks.pickup.name} type="text" name="pickup_task_client_name" className={"form-control pickup_task_client_name"} />
						</div>

						<div className={"col-md-6"}>
							<label><Trans i18nKey="user_phone" /><FontAwesomeIcon icon={faPhone} className={"form-label-icon"} /></label>
							<input defaultValue={this.state.subtasks.pickup.phone} type="text" name="pickup_task_client_phone" className={"form-control pickup_task_client_phone"} />
						</div>

					</div>
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="label_address" />
						<FontAwesomeIcon icon={faMapMarker} className={"form-label-icon"} />
						<button 
						type="button"
						onClick={e => this.pinToggle('pickup')} 
						className={"float-right btn btn-link"}
						style={!this.state.pinOnMap || this.state.pinForTask !== "pickup" ? {color:"#888"} : {}}>

						Pin on map

						<FontAwesomeIcon icon={faMapPin} style={{marginLeft: '10px'}} />
						</button>
					</label>

					<input value={this.state.pickup_lat ? this.state.pickup_lat : ""} type="hidden" name="pickup_lat" className={"form-control"} />
					<input value={this.state.pickup_lng ? this.state.pickup_lng : ""} type="hidden" name="pickup_lng" className={"form-control"} />

					<GeoInput 	
					onGeoSelect={e => this.selectAddress(e, 'pickup')} 
					name="pickup_task_address" 
					inputValue={this.state.subtasks.pickup.address} 
					className={"form-control"} />
					
				</div>


				<span className={"add-subtask"}></span>
			</div>
			
			
			<div className={"task-single"}>

				<div className={"form-group"}>	
					<h4>Delivery</h4>
				</div>

				<div className={"form-group"}>
					<div className={"row"}>

						<div className={"col-md-6"}>
						<label><Trans i18nKey="label_name" /><FontAwesomeIcon icon={faUser} className={"form-label-icon"} /></label>
						<input type="text" name="delivery_task_client_name" className={"form-control"} />
						</div>

						<div className={"col-md-6"}>
						<label><Trans i18nKey="user_phone" /><FontAwesomeIcon icon={faPhone} className={"form-label-icon"} /></label>

						<input type="text" name="delivery_task_client_phone" className={"form-control"} />
						</div>

					</div>
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="label_address" /> 
						<FontAwesomeIcon icon={faMapMarker} className={"form-label-icon"} />
						<button 
						type="button"
						onClick={e => this.pinToggle('delivery')} 
						className={"float-right btn btn-link"}
						style={!this.state.pinOnMap || this.state.pinForTask !== "delivery" ? {color:"#888"} : {}}>

						Pin on map

						<FontAwesomeIcon icon={faMapPin} style={{marginLeft: '10px'}} />
						</button>
					</label>

					<input value={this.state.delivery_lat ? this.state.delivery_lat : ""} type="hidden" name="delivery_lat" className={"form-control"} />
					<input value={this.state.delivery_lng ? this.state.delivery_lng : ""} type="hidden" name="delivery_lng" className={"form-control"} />

					<GeoInput 	
					onGeoSelect={e => this.selectAddress(e, 'delivery')}
					name="delivery_task_address" 
					inputValue={this.state.subtasks.delivery.address} 
					className={"form-control"} />
					
				</div>

				
				<span className={"add-subtask"}></span>
			</div>
			</React.Fragment>)
	}

	render() {


		
		const refs = this.props.refs;
		const data = this.props.data;
		return(
			<React.Fragment>
			<div className={"container-header"}>
				Nouveau site
			</div>

			<form ref={this.taskForm} onSubmit={this.submitTask}>
			<div className={"row"}>
			<div className={"col-md-6 taskForm"}>
				

				<div className={"form-group"}>
					<label>Nom du site  </label>
					<input type="text" name="site_name" className={"form-control"} />
				</div>

				<div className={"form-group"}>
					<label>Code du site </label>
					<input type="text" name="site_code" className={"form-control"} />
				</div>

				<div className={"form-group"}>
					<label>Wilaya </label>
					
					<Select
					onChange={this.typeChange}
					name="site_address_wilaya"
					options={this.state.wilayas}
					/>

				</div>
				
				<div className={"form-group"}>
					<label>Adresse du site </label>
					<input type="text" name="site_addess_info" className={"form-control"} />
				</div>

				<div className={"form-group"}>
					<label>O&M Region  </label>
					<input type="text" name="site_region" className={"form-control"} />
				</div>


				


				{/* <div className={"form-group"}>
					<label><Trans i18nKey="task_type" /><FontAwesomeIcon icon={faList} className={"form-label-icon"} /> </label>
					<Select
					onChange={this.typeChange}
					name="task_type"
					options={data.types}
					isLoading={data.types.length === 0}
					/>
				</div> */}

				 {/* <React.Fragment>

					<div className={"form-group"}>
						<div className={"row"}>

							<div className={"col-md-6"}>
							<label>Contact<FontAwesomeIcon icon={faUser} className={"form-label-icon"} /></label>
							<input defaultValue={this.state.single.name} type="text"  name="site_contact" className={"form-control task_client_name"} />
							</div>

							<div className={"col-md-6"}>
							<label><Trans i18nKey="user_phone" /><FontAwesomeIcon icon={faPhone} className={"form-label-icon"} /></label>

							<input defaultValue={this.state.single.phone} type="text" name="site_contact_phone" className={"form-control task_client_phone"} />
							</div>

						</div>
					</div>

					<div className={"form-group"}>
						<label>
							Position 
							<FontAwesomeIcon icon={faMapMarker} className={"form-label-icon"} />
							<button 
							type="button"
							onClick={e => this.pinToggle('single')} 
							className={"float-right btn btn-link"}
							style={!this.state.pinOnMap ? {color:"#888"} : {}}>

							Pin on map

							<FontAwesomeIcon icon={faMapPin} style={{marginLeft: '10px'}} />
							</button>
						</label>

						<input type="text" name="geo_lat" className={"form-control"} />
						<input type="text" name="geo_lng" className={"form-control"} />

						<GeoInput 	
						onGeoSelect={this.selectAddress} 
						name="site_address_auto" 
						inputValue={this.state.address} 
						className={"form-control d-none"} />
						
					</div>

					</React.Fragment> */}
				

				


				<div className={"form-group"}>
					<div className={"row"}>
					
						<div className={"col-md-6"}>
						<label>Latitude </label>
						{/* <input disabled value={this.state.geo_lat ? this.state.geo_lat : ""} type="text" name="address_lat" className={"form-control"} /> */}

						<input type="text" name="geo_lat" className={"form-control"} />
						</div>

						<div className={"col-md-6"}>
						<label>Longitude </label>

						{/* <input 
						disabled 
						value={this.state.geo_lng ? this.state.geo_lng : ""} type="text" name="address_lng" className={"form-control"} /> */}

						<input type="text" name="geo_lng" className={"form-control"} />
						</div>

					</div>
				</div>

				<div className={"form-group"}>
					<label>Classification  </label>
					
					<Select
					onChange={this.typeChange}
					name="site_classification"
					options={[
						{
							label: 'Résidentiel',
							value: 1
						},
						{
							label: 'Bureau',
							value: 2
						},
						{
							label: 'Administratif',
							value: 3
						},
						{
							label: 'Commercial',
							value: 4
						},
						{
							label: 'Terrain',
							value: 5
						},
						{
							label: "Château d'eau",
							value: 6
						},
						{
							label: 'Autre',
							value: 7
						}
					]}
					/>

				</div>

				<div className={"form-group"}>
					<label>Shelter/Local  </label>
					
					<Select
					onChange={this.typeChange}
					name="site_shelter_type_select"
					options={[
						{
							label: 'Shelter',
							value: 1
						},
						{
							label: 'Local technique',
							value: 2
						},
						{
							label: 'Outdoor',
							value: 3
						}
					]}
					/>
				</div>
				<div className={"form-group"}>
					<input name="site_shelter_type" type="text" className={"form-control"} placeholder={"Type"} />
				</div>

				<div className={"form-group"}>
					<label>Clés  </label>
					
					<Select
					name="site_keys_select"
					options={[
						{
							label: 'Clé master',
							value: 1
						},
						{
							label: 'Clé normal',
							value: 2
						}
					]}
					/>
				</div>

				<div className={"form-group"}>
					<input name="site_keys_type" type="text" className={"form-control"} placeholder={"Type"} />
				</div>

				<div className={"form-group"}>
					<label>Support d'antenne  </label>
					
					<Select
					name="site_antenna_support"
					options={[
						{
							label: 'Mâts',
							value: 1
						},
						{
							label: 'Support Haubané',
							value: 2
						},
						{
							label: 'Pylone',
							value: 3
						}
					]}
					/>
				</div>
				
				<div className={"row"}>

					<div className={"form-group col-md-6"}>
						<input name="site_antenna_support_type" type="text" className={"form-control"} placeholder={"Type"} />
					</div>

					<div className={"form-group col-md-6"}>
						<input name="site_antenna_support_number" type="text" className={"form-control"} placeholder={"Nombre"} />
					</div>
				</div>


				<div className={"form-group"}>
					<label>
						Gardiennage
						<button type="button" onClick={this.addGuard} className={"btn btn-link float-right"}>Ajouter </button>
					</label>
					
				</div>
				{this.state.guards.map((guard, index) => (
					<div key={index} className={"row"}>

						<div className={"form-group col-md-5"}>
							<label>Nom et prénom</label>
							<input name={"gaurds["+index+"][name]"} type="text" className={"form-control"} placeholder={"Nom et prénom"} />
						</div>

						<div className={"form-group col-md-5"}>
							<label>Numéro de téléphone</label>
							<input name={"gaurds["+index+"][phone]"} type="text" className={"form-control"} placeholder={"Numéro de téléphone"} />
						</div>

						<div className={"form-group col-md-2"} style={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
							<button onClick={e => this.removeGuard(index)} type="button" className={"btn-link btn"} style={{display: 'flex'}} >Supprimer</button>
						</div>
					</div>
				))}



				<div className={"form-group"}>
					<button type="submit" className={"btn btn-block btn-success float-right"}><Trans i18nKey="button_add_task" /></button>
				</div>

		
			</div>

			<div className={"col-md-6 taskForm"}>
				
				{/* <div style={{height: "400px", borderColor: '#ccc', borderWidth: "1px", borderRadius: "2px", borderStyle: "solid"}} id="here-maps"></div> */}
			</div>


			</div>

			</form>
			

			</React.Fragment>
		)
	}
}


export default TaskNew