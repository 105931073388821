import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, doneLoading, startLoading, setOverlay } from './../Redux/actions/index';
import TaskEdit from '../Components/Task/TaskEdit';
import api from '../Utils/Api';



const mapStateToProps = state => {
	return {
		user: state.user,
		loading: state.loading
	}
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    setOverlay: (x) => dispatch(setOverlay(x)),
    isLoading: (e) => dispatch(startLoading(e))
  };
}


class TaskEditContainer extends Component {

	constructor(props) {
		super(props);

		this.props.isLoading({message: "Loading task information", animate:true});

		
		this.state = {

			agents: [],
			types: [],
			status: [],
			task: {
				task_id: '',
				task_name: '',
				task_type: '',
				task_type_id: '',
				task_start_date: '',
				task_end_date: '',
				task_address:'', 
				task_client_phone: '',
				task_client_name: '',
				task_agent_id: '',
				task_agent: '',
				task_status: '',
				task_status_id: 0,
				task_instruction: ''

			},
			ready: false
		}

		this.handlers = {
			submitTask: this.submitTask.bind(this)
		}
		this.refs = {
			taskForm: React.createRef()
		}


	}

	submitTask(e, ref) {
		e.preventDefault();
		let that = this;

		this.props.isLoading({
			message:"Updating task",
			animate:true
		});

		const formdata = new FormData(ref);

		
		let data = {};

		for(var [key, value] of formdata.entries()) 
			data[key] = value
		
		data['user'] = this.props.user;
			

		console.log(data);


		api.post('/api/task/edit', (data))
		.then(function(response) {

			if(response.data.success) {

				that.props.history.push('/task');

				that.props.setOverlay({
					message: 'Task successfully updated !',
					label: "success",
					button: {
						type: "close",
						label: "Dismiss",
						link: "javascript:void(0)"
					}
				});
			} else {
				if(response.data.errcode === 101) {
					that.props.setAuth(response.data)
				}
			}

			that.props.doneLoading();


			
		}).catch(err => that.props.doneLoading());
		
	}


	componentDidMount() {
		
		

		let that = this;

		api.get('/api/task/form?token='+this.props.user.token)
		.then(function(response) {
			that.setState({agents: response.data.agents, types: response.data.types, status: response.data.status})

			api.get('/api/task/'+that.props.match.params.taskid+'?token='+that.props.user.token)
			.then(function(response) {
				if(response.data.success)
					that.setState({task: response.data.task, ready: true})
				else
					that.props.setAuth(false);

				that.props.doneLoading();
				
			});
		});
	}


	render() {
		

		if(this.state.ready)
		return(

			<TaskEdit
				data={this.state}
				handlers={this.handlers}
				refs={this.refs}
			/>
		) 

		return <React.Fragment />

	}
}


export default connect(mapStateToProps, mapDispatchToProps)(TaskEditContainer)