import React, { Component } from 'react';
import _ from 'lodash';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AddressList from '../Components/Addresses/AddressList'
import {setAuthAction, startLoading, doneLoading} from '../Redux/actions/index';
import { Trans } from "react-i18next";
import Select from 'react-select';
import SocketContext from '../Utils/socket-context';
import api from '../Utils/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


const remote = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'http://localhost/djezzy/api/public/';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user, 
		taskList: state.taskList
	}
}

function mapDispatchToProps(dispatch) {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e))
	}
}

class AddressListContainer extends Component {
	
	constructor(props) {
		super(props);
		
		this.props.isLoading({
			message:"Chargement des sites",
			animate:true
		});

		this.state = {
			showFilters: false,
			planning_ready: false,
			wilayas: [],
			managers: [],
			sites: [],
			agents: [],
			status: [],
			types: [],
			rows: [],
			current: 1,
			pages: 1,
			limit: 10,
			pm_start: "",
			pm_end: "",
			filters: {
				site_agent: "",
				site_name: "",
				site_code: "",
				site_wilaya: "",
				site_manager: "",
				type_ac: "",
				marque_ac: "",
				marque_ps: "",
				marque_batterie: "",
				power_type: "",
				bss_equip: "",
				antenne: "",
				type: "",
				typology: "",
				pmdone: false,
				assigned: false
			},
			power_type: [],
			marque_ac: [],
			bss_equip: [],
			marque_battery: [],
			showOverlay: true
		}

		
		
		this.loadSites = this.loadSites.bind(this);
		this.loadSites =_.debounce(this.loadSites, 300)
		this.toggleHidden = this.toggleHidden.bind(this);
		this.updateFilter = this.updateFilter.bind(this);

		this.handlers = {
			selectPage: this.selectPage.bind(this),
			loadSites: this.loadSites
		}
		
		this.changeLimit = this.changeLimit.bind(this);

	}

	changeLimit(e) {
		this.props.isLoading();
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadSites())


	}



	changeDate = (name, event) => {

		const that = this;

		this.setState({
			["pm_"+name]: event.target.value,
		}, function() {
		
			that.props.isLoading({
				message: "Mise à jours de la liste",
				animated: true
			});
			that.loadSites(1);

		})

	}

	updateFilter(event, name) {
		
		let that = this;
		const value = event.target ? event.target.value : event.value;

		console.log(value)

		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			that.props.isLoading();
			that.loadSites(1);
		}); 


		//window.ga('send', 'event', 'task', 'filter'); // Google Analytics


		

	}


	exportResult = (e) => {

		e.preventDefault();

		const crits = "limit=-1"
					  +"&pmdone="+this.state.filters.pmdone
					  +"&site_name="+this.state.filters.site_name
					  +"&site_code="+this.state.filters.site_code
					  +"&site_wilaya="+this.state.filters.site_wilaya
					  +"&site_manager="+this.state.filters.site_manager
					  +"&pm_start="+this.state.pm_start
					  +"&pm_end="+this.state.pm_end
					  +"&site_agent="+this.state.filters.site_agent
					  +"&marque_batterie="+this.state.filters.marque_batterie
					  +"&marque_ac="+this.state.filters.marque_ac
					  +"&type_ac="+this.state.filters.type_ac
					  +"&power_type="+this.state.filters.power_type
					  +"&bss_equip="+this.state.filters.bss_equip
					  +"&type="+this.state.filters.type
					  +"&typology="+this.state.filters.typology
					  +"&antenne="+this.state.filters.antenne ;

		const action = remote+'/api/sites/fetch/filter?export&usertoken='+this.props.user.token+'&excel&'+crits;

		window.location = action;

		
		//e.target.submit();
		
	}



	toggleHidden(e) {
		
		const i = e.target.getAttribute('data-row-index');
		let nstate = this.state;

		nstate.rows[i] && _.set(nstate, 'rows['+i+'].hidden', !nstate.rows[i].hidden);

		this.setState(nstate);
	
	}

	componentDidMount() {



		let that = this;


		api.get('/api/sites/filters?dev&limit=-1')
		.then(function(response) {
			if(response.data.success) {
				
				that.setState({
					agents: [
						{
							label: "All", value: ""
						}, 
						...response.data.agents
					],
					wilayas: response.data.wilayas,
					marque_ac: [{label: 'All', value: ''}, ...response.data.marque_ac],
					bss_equip: [{label: 'All', value: ''}, ...response.data.bss_equip],
					power_type: [{label: 'All', value: ''}, ...response.data.power_type],
					marque_battery: [{label: 'All', value: ''}, ...response.data.marque_battery]
				})

				//console.log({power_type: [{label: 'All', value: '', ...response.data.power_type}]})
				
			}
		});

		if(this.props.user && this.props.user.role === 'cluster_manager' || this.props.user.role === 'admin')  {

			api.get('/api/manager/fetch?limit=-1')
			.then(function(response) {
				if(response.data.success) {
					
					that.setState({
						managers: [
							{
								label: "All", value: ""
							}, 
							...response.data.users.map((item, i) => ({ label: item.username, value: item.userid}))
						] 
					})
					
				}
			});
			
		}
		
		
		this.loadSites();
		
	}



	loadSites(p = this.state.current) {

		let nstate = this.state;
		let that = this;

		const crits = "limit="+this.state.limit
					  +"&pmdone="+this.state.filters.pmdone
					  +"&assigned="+this.state.filters.assigned
					  +"&site_name="+this.state.filters.site_name
					  +"&site_code="+this.state.filters.site_code
					  +"&site_wilaya="+this.state.filters.site_wilaya
					  +"&site_manager="+this.state.filters.site_manager
					  +"&pm_start="+this.state.pm_start
					  +"&pm_end="+this.state.pm_end
					  +"&site_agent="+this.state.filters.site_agent
					  +"&marque_batterie="+this.state.filters.marque_batterie
					  +"&marque_ac="+this.state.filters.marque_ac
					  +"&type_ac="+this.state.filters.type_ac
					  +"&power_type="+this.state.filters.power_type
					  +"&bss_equip="+this.state.filters.bss_equip
					  +"&type="+this.state.filters.type
					  +"&typology="+this.state.filters.typology
					  +"&antenne="+this.state.filters.antenne ;



		api.get('/api/sites/fetch/filter?page='+p+'&'+crits)
		.then(function(response) {
			if(response.data.success) {

				response.data.sites.map(r =>  {
					nstate.rows.push({"hidden": true})
				});


				that.setState({
					sites: response.data.sites, 
					pages: response.data.pages, 
					rows: nstate.rows,
					total: response.data.total
				})

			} else {
				if(response.data.errcode === 101) {
					that.props.setAuth(false);
				}
			}

			that.props.doneLoading();

		});
	}


	


	selectPage(p) {
		this.props.isLoading();

		this.setState({current: p});

		this.loadSites(p);
	}

	importClick = (e) => {
		e.preventDefault();

		this.toggleOverlay();

		document.querySelector("#planning_file").click();


	}

	handelFileChange = (e) => {
		if(e.target.value) {
			this.setState({ planning_ready: true })
		} else {
			this.setState({ planning_ready: false })
		}
		console.log(e.target)
	}

	toggleOverlay = (e) => this.setState({showOverlay: !this.state.showOverlay});
	
	toggleFilters = (e) => {
		e.preventDefault();
		this.setState({
			showFilters: ! this.state.showFilters 
		})
	}

	render() {
		
		return(
			
			<React.Fragment>
			

			

			<div className={"container-header"}>

				<Trans i18nKey="label_sites" />

				{!this.props.auth ? <Redirect to="/login" /> :  ""}

				{this.props.user && (this.props.user.role === 'cluster_manager' || this.props.user.role === 'admin') ? (
					<React.Fragment>
					
					<Link to={"/sites/new"}  className={"btn btn-primary float-right mr-2"}><Trans i18nKey="label_new_site" /> </Link>

					</React.Fragment>
					) : ""}

				<form style={{display: "inline"}} onSubmit={this.exportResult} action='http://djezzy.tictechs.tech/api/export/csv' method="get">
				<button type="submit" className={"btn btn-primary btn float-right mr-2"}>Exporter le résultat</button>
				</form>
			</div>



			{ this.props.user.role === 'cluster_manager' || this.props.user.role === 'manager' || this.props.user.role === 'admin' 

			? (<React.Fragment>
			
				{this.props.user.role === 'admin' 
				? (
					<div className={"row mb-5"}>
						<div className={"col-md-3"}>
							<label>Manager</label>
							<Select
							options={this.state.managers}
							placeholder={"Selectionnez..."}
							onChange={e => this.updateFilter(e, "author")} 
							/>
						</div>
					</div>
				) : ""}

				<div className={"row form"}>
				
					<div className={"col-md-6"}>
						<h4>Filters</h4>
					</div>

					<div className={"filters-container vert"}>
						<div className={"row"}>


							{(this.props.user.role === 'cluster_manager' || this.props.user.role === 'admin') ? (

							<div className={"form-group col-md-2"}>
								<label>Manager</label>
								<Select
								options={this.state.managers}
								placeholder={"Selectionnez..."}
								onChange={e => this.updateFilter(e, "site_manager")} 
								/>
							</div>
							) : (this.props.user.role === 'manager') ? (
								<div className={"form-group col-md-2"}>
									<label>Agent</label>
									<Select
									options={this.state.agents}
									placeholder={"Selectionnez..."}
									onChange={e => this.updateFilter(e, "site_agent")} 
									/>
								</div>
							) : <></>}


							


							<div className={"form-group col-md-2"}>
								<label>Nom du site</label>
								<input onChange={e => this.updateFilter(e, 'site_name')} name="site_name" type="text" className={"form-control"} />
							</div>


							<div className={"form-group col-md-2"}>
								<label>Code site</label>
								<input onChange={e => this.updateFilter(e, 'site_code')} name="site_code" type="text" className={"form-control"} />
							</div>

							<div className={"form-group col-md-2"}>
								<label>Wilaya</label>
									<Select
									options={[
										{ label: 'All', value: '' },
										...this.state.wilayas
									]}
									placeholder={"Wilaya"}
									onChange={e => this.updateFilter(e, "site_wilaya")} 
									/>
							</div>

							<div className={"col-md-2 fposb"}>
								<div className="custom-control custom-radio">
									<input 
									onChange={e => this.updateFilter({value: e.target.checked ? "" : ""}, 'assigned')}
									name="assigned"
									 type="radio" className="custom-control-input" id="customCheck1" />
									<label 
									className="custom-control-label" htmlFor="customCheck1">Tous</label>
								</div>

								<div className="custom-control custom-radio">
									<input 
									onChange={e => this.updateFilter({value: e.target.checked ? false : ""}, 'assigned')}
									name="assigned"
									defaultChecked={true}
									 type="radio" className="custom-control-input" id="customCheck2" />
									<label 
									className="custom-control-label" htmlFor="customCheck2">Non Attribué</label>
								</div>

								<div className="custom-control custom-radio">
									<input 
									onChange={e => this.updateFilter({value: e.target.checked ? true : ""}, 'assigned')}
									name="assigned"
									 type="radio" className="custom-control-input" id="customCheck3" />
									<label 
									className="custom-control-label" htmlFor="customCheck3">Attribué</label>
								</div>
							
							</div>

							<div className={"col-md-2 fposb"}>

								<div className="custom-control custom-radio">
									<input 
									onChange={e => this.updateFilter({value: e.target.checked ? "" : ""}, 'pmdone')}
									name="pmdoneornot"
									 type="radio" className="custom-control-input" id="customCheck11" />
									<label 
									className="custom-control-label" htmlFor="customCheck11">Tous</label>
								</div>
								
								<div className="custom-control custom-radio">
									<input 
									onChange={e => this.updateFilter({value: e.target.checked ? false : ""}, 'pmdone')}
									name="pmdoneornot"
									defaultChecked={true}
									 type="radio" className="custom-control-input" id="customCheck13" />
									<label 
									className="custom-control-label" htmlFor="customCheck13">PM Not Done</label>
								</div>

								<div className="custom-control custom-radio">
									<input 
									onChange={e => this.updateFilter({value: e.target.checked ? true : ""}, 'pmdone')}
									name="pmdoneornot"
									 type="radio" className="custom-control-input" id="customCheck12" />
									<label 
									className="custom-control-label" htmlFor="customCheck12">PM Done</label>
								</div>

							
							</div>



						</div>
								
						<div className={"row"}>
							<div className={"col-md-3"}>
							<a onClick={this.toggleFilters} href="#" className={"btn-sm btn-primary"}>Recherche avancée</a>
							</div>
						</div>
						
						<div className={"advanced-filters"+(this.state.showFilters ? " open" : "")}>

						
							<div className={"row mt-2"}>

								<div className={"form-group col-md-2"}>
									<label>Type de site</label>
									<Select
									options={[
										{label: "All", value: ""},
										{label: "Indoor", value: "indoor"},
										{label: "Outdoor", value: "outdoor"},
										{label: "Local Technique", value: "localtechnique"}
									]}
									placeholder={"Selectionnez..."}
									onChange={e => this.updateFilter(e, "type")} 
									/>
								</div>

								<div className={"form-group col-md-2"}>
									<label>Typology</label>
									<Select
									options={[
										{label: "All", value: ""},
										{label: "Green Field", value: "greenfield"},
										{label: "Rooftop", value: "rooftop"}
									]}
									placeholder={"Selectionnez..."}
									onChange={e => this.updateFilter(e, "typology")} 
									/>
								</div>

								

								<div className={"form-group col-md-2"}>
									<label>Support antenne</label>
									<Select
									options={[
										{label: "All", value: ""},
										{label: "Mat", value: "mat"},
										{label: "Pylone", value: "pylone"}
									]}
									placeholder={"Selectionez..."}
									onChange={e => this.updateFilter(e, "antenne")} 
									/>
								</div>

							</div>

							<div className={"row"}>



								<div className={"form-group col-md-2"}>
									<label>Type AC</label>
									<Select
									options={[
										{ label: 'All', value: ""},
										{ label: "Monobloc", value: "monobloc" },
										{ label: "Split", value: "split" }
									]}
									placeholder={"Type AC"}
									onChange={e => this.updateFilter(e, "type_ac")} 
									/>
								</div>

								<div className={"form-group col-md-2"}>
									<label>Marque AC</label>
									<Select
									options={this.state.marque_ac}
									placeholder={"Marque AC"}
									onChange={e => this.updateFilter(e, "marque_ac")} 
									/>
								</div>



								<div className={"form-group col-md-2"}>
									<label>Type Power supply</label>
									<Select
									options={[...this.state.power_type]}
									placeholder={"Type PS"}
									onChange={e => this.updateFilter(e, "power_type")} 
									/>


								</div>

								<div className={"form-group col-md-2"}>
									<label>Batterie</label>
									<Select
									options={this.state.marque_battery}
									placeholder={"Marque batterie"}
									onChange={e => this.updateFilter(e, "marque_batterie")} 
									/>
									
								</div>

								<div className={"form-group col-md-2"}>
									<label>BSS</label>
									<Select
									options={this.state.bss_equip}
									placeholder={"Equipement BSS"}
									onChange={e => this.updateFilter(e, "bss_equip")} 
									/>

								</div>

								

							</div>
						</div>



					</div>
				

					

				</div>

				</React.Fragment>) : ("")}

				

				<div className={"per-page-wrap"}>
				Résultat par page 
					<Select
					onChange={this.changeLimit}
					className={"per-page nm"}
						options={[
							{label: "10", value: 10},
							{label: "15", value: 15},
							{label: "30", value: 30}]}
						value={{label: this.state.limit, value: this.state.limit}}
					/>
				</div>

				<AddressList
				data={this.state}
				rows={this.state.rows}
				toggleHidden={this.toggleHidden}
				handlers={this.handlers}
				
				/>

			
			</React.Fragment>)


	}
}


const AddressListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <AddressListContainer {...props} socket={socket} />}
	</SocketContext.Consumer>
  )

export default connect(mapStateToProps, mapDispatchToProps)(AddressListConsumer)