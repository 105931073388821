import React, { Component } from 'react';
import {  NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

class ManagerSideMenu extends Component {



	

	render() {

		if(this.props.auth)
		return (
			
			<ul className={"sideMenu-list"}>
				<li>
					
					<NavLink  to={"/manager/agents"}><Trans i18nKey="label_agents" /></NavLink>
					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/manager/agents"}><Trans i18nKey="menu_my_agents" /></NavLink></li>
						<li><NavLink exact to={"/manager/agents/new"}><Trans i18nKey="menu_add_agent" /></NavLink></li>
					</ul>
				</li>
				

				<li>
					<NavLink to={"/link"}>Links</NavLink> 

					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/link"}>Link list</NavLink></li>
						<li><NavLink strict exact to={"/link/new"}>Ajouter nouveau link</NavLink></li>
					</ul>

				</li>

				<li>
					<NavLink to={"/task"}>Tasks</NavLink> 

					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/task"}>Task list </NavLink></li>
					</ul>

				</li>


				<li>
					<NavLink to={"/account"}><Trans i18nKey="menu_account" /></NavLink> 

					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/account"}><Trans i18nKey="menu_personal" /></NavLink></li>
						{/* <li><NavLink strict exact to={"/account/privacy"}><Trans i18nKey="menu_notifications" /></NavLink></li> */}
						<li><NavLink strict exact to={"/account/security"}><Trans i18nKey="menu_security" /></NavLink></li>
					</ul>

				</li>

			</ul>

		)
	}
}



export default connect(mapStateToProps, null, null, { pure: false})(ManagerSideMenu)
/*
<div className="list-group">
					<Link className="list-group-item list-group-item-action" to={"/items"}>Items</Link>
					<Link className="list-group-item list-group-item-action" to={"/packages"}>Packages</Link>
					<Link className="list-group-item list-group-item-action" to={"/roles"}>Roles</Link>
					<Link className="list-group-item list-group-item-action" to={"/security"}>Security</Link>
					<Link className="list-group-item list-group-item-action" to={"/config"}>Configuration</Link>

				</div>
				*/