/*  const apiURL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'https://djezzy.tictechs.tech';
//const apiURL =  'https://djezzy.tictechs.tech';
//const apiURL ='http://127.0.0.1:8000'
export default {
    apiURL,
    apiEndPoints: {
        CONNECT: '/api/user/connect',
        FETCH_NOTIFICATIONS: '/api/notification/fetch',
        FETCH_FILTERS: '/api/form/filters',
        FETCH_CARS: '',
        FETCH_ASSET_POSITION: id => '/api/position/fetch?asset='+id,
        EXPORT_TASK: id => apiURL+"/api/pm/export/igeneral/"+id,
        EXPORT_TASK_ANOMALIES: id => apiURL+"/api/anomalies/checks_export/"+id,
        EXPORT_TASK_GPM: id => apiURL+"/api/gpm/export/igeneral/"+id,
        EXPORT_TASK_ANOMALIES_GPM: id => apiURL+"/api/gpm/anomalies/checks_export/"+id,
        EXPORT_TASK_CM: id => apiURL+"/api/cm/export/test/igeneral/"+id,
        EXPORT_TASK_ANOMALIES_CM: id => apiURL+"/api/cm/anomalies/checks_export/"+id,
        STOP_ALARM: id => '/api/iot/alarm/stop?asset='+id,
    }
}
  */

export const  apiURL = 'https://app.netfer.tictechs.tech';