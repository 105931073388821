import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Select from 'react-select';
import Async from 'react-select/lib/Async';
import ReactTable from "react-table";
import Tooltip from "../../Utils/Tooltip";
import "react-table/react-table.css";
import { connect } from 'react-redux';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import { useTranslation, Trans } from "react-i18next";

import SingleCheck from './../Tests/SingleCheck';

import IGeneral from './../Tests/IGeneral';
import Power from './../Tests/Power';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faAngleRight, faPlus, faHistory, faCheckSquare, faChevronCircleDown, faTimes, faCheck, faMinus, faFile, faExclamation, faUndo, faCircleNotch, faList, faEdit, faFileArchive } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import moment from 'moment';
import MoreInfoDialog, { Item } from '../../Utils/MoreInfoDialog';



const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user,
		taskList: state.taskList
	}
}

const mapDispatchToProps = (dispatch) =>
({
	isLoading: e => dispatch(startLoading(e)),
	doneLoading: e => dispatch(doneLoading()),
	setAuth: e => dispatch(setAuthAction(e))
})




class TaskList extends Component {




	constructor(props) {
		super(props);
		this.state = {
			agents: [],
			rows: [],
			showOverlay: false,
			taskid: "",
			agentid: "",
			selected_pm: "",
			activeTest: "igeneral",
			showDeleteOverlay: false,
			showDeleteOverlayConfirmation: false,
			loadingManholeCheck: false,
			summaryContent: {},
			showSummaryOverlay: false,
			manholeCheck: null
		}

		this.toggleOverlay = this.toggleOverlay.bind(this);


	}


	toggleOverlay(e) {
		this.setState({ showOverlay: !this.state.showOverlay });
	}



	selectTest = (e, test) => {
		e.preventDefault();
		this.setState({
			activeTest: test
		})
	}

	deleteTask = (e) => {

		const pmid = e.target.getAttribute('data-task-id');



		this.setState({
			selected_pm: pmid,
			showDeleteOverlay: true,
			deleteSteps: 1
		})


	}

	nextDeleteStep = () => {
		this.setState({
			showDeleteOverlay: false,
			showDeleteOverlayConfirmation: true,
			deleteSteps: 2
		})
	}

	confirmDelete = () => {
		this.props.isLoading();

		this.setState({
			showDeleteOverlayConfirmation: false
		})

		const data = {
			taskid: this.state.selected_pm
		}

		api.post('/api/task/delete', data)
			.then((response) => {
				this.props.handlers.loadTasks()
			})

	}


	exportPM = (pm) => {

		console.log(this.pmExportForm);

		this.pmExportForm.action = "https://app.netfer.tictechs.tech/api/pm/export/" + pm.task_id + "?token=" + this.props.user.token;
		// const newInput = document.createElement("input", {
		// 	name: "pm",
		// 	value: JSON.stringify(pm)
		// });
		// console.log(newInput);
		//this.pmExportForm.appendChild(newInput);
		//this.pmInput.value = JSON.stringify(pm)
		this.props.isLoading();
		this.pmExportForm.submit();

		this.props.doneLoading();

		const data = {
			pm: pm
		}

		// api.post('/api/pm/export/igeneral/'+pm.task_id, data)
		// .then(function(response) {
		// 	console.log(response.data)
		// })
	}

	selectManhole = (e, manhole) => {
		console.log("manhole tasklist",manhole)
		this.setState({
			activeManhole: manhole.manhole_id ? manhole.manhole_id : manhole.bridge_id,
			manholeCheck: manhole,
			loadingManholeCheck: false
		});


		return;

		// api.get('/api/manhole/check/fetch/'+check_id)
		// .then(response => {
		// 	this.setState({
		// 		manholeCheck: response.data.data,
		// 		loadingManholeCheck: false
		// 	});

		// 	console.log(response);
		// })

	}

	denyCheck = (type, id) => {
		this.setState({ showDenyOverlay: true, selectedType: type })
		console.log(id);
	}

	confirmDeny = () => {
		this.props.isLoading({
			message: "Returning Check to technician",
			animate: true
		});

		const data = {
			type: this.state.selectedType,
			id: this.state.selectedType === 'bridge' ? this.state.manholeCheck.check_id : this.state.manholeCheck.check_id
		}


		this.setState({ showDenyOverlay: false });


		api.post('/api/check/return', data)
			.then(response => {

				console.log(response.data);

				this.props.doneLoading();
			})


	}


	showSummary = (summary) => {
		this.setState({
			showSummaryOverlay: true,
			summaryContent: summary
		})
	}


	toggleHidden = (x, task) => {
		this.setState({
			manholeCheck: {}
		})
		this.props.toggleHidden(x, task)
	 
	}

	updateCheckStatus = (sectionIndex, checkIndex, newStatus, checkType = "manhole") => {
		const { manholeCheck } = this.state;

		manholeCheck.checks[sectionIndex].checks[checkIndex].test_status = newStatus;

		this.setState({
			manholeCheck: manholeCheck
		});

		const data = {
			check_id: manholeCheck.check_id,
			checks: manholeCheck.checks
		}

		api.post('/api/' + checkType + '/check/edit', data)
			.then(res => {
				console.log('Check status updated')
			});



	}

	componentDidMount() {
		api.get('/api/agent/fetch?limit=-1&status=enabled')
			.then(response => {
				if(response.data.success){
					this.setState({
						agents: response.data.users.map(user => ({ label: user.username, value: user.agentid }))
					})
				}
				

			})
	}

	render() {
		//const { t, i18n } = useTranslation();

		const data = this.props.data;
		const rows = this.props.rows;
		const toggleHidden = this.toggleHidden;
		(this.props.data.linkTaskData && this.props.data.linkTaskData.bridges) ? console.log(this.props.data.linkTaskData.bridges) : console.log('');


		return (

			<React.Fragment>
				<form method="post" ref={ref => this.pmExportForm = ref}>
					<input type="hidden" name={"pm"} ref={ref => this.pmInput = ref} />
				</form>

				<div className={this.state.showDeleteOverlay ? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={e => this.setState({ showDeleteOverlay: false })}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>

						<h5 className={"mb-4"}>Voulez-vous vraiment supprimer cette tâche ?</h5>


						<button onClick={e => this.setState({ showDeleteOverlay: false })} className={"btn btn-success btn-sm mt-2"}>ANNULER</button>
						<button onClick={this.nextDeleteStep} type="submit" className={"btn btn-danger btn-sm ml-2 mt-2"}>OUI, SUPPRIMER</button>


					</div>
				</div>


				<div className={this.state.showDenyOverlay ? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={e => this.setState({ showDenyOverlay: false })}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>

						<h5 className={"mb-4"}>Voulez-vous vraiment retourner ce Check ?</h5>


						<button onClick={e => this.setState({ showDenyOverlay: false })} className={"btn btn-success btn-sm mt-2"}>ANNULER</button>
						<button onClick={this.confirmDeny} type="submit" className={"btn btn-danger btn-sm ml-2 mt-2"}>OUI, CONFIRMER</button>


					</div>
				</div>


				<div className={this.state.showDeleteOverlayConfirmation ? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={e => this.setState({ showDeleteOverlayConfirmation: false })}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>

						<h5 className={"mb-5"}>Êtes-vous sur de vouloir supprimer cette tâche ?</h5>

						<button onClick={e => this.setState({ showDeleteOverlayConfirmation: false })} type="submit" className={"btn btn-success btn-sm mt-2"}>Retour</button>
						<button onClick={this.confirmDelete} type="submit" className={"btn btn-danger btn-sm mt-2 ml-2"}>Confirmer la suppression</button>

						<div>
							<span className={"font-exp"}>Attention, cette action est irreversible.</span>
						</div>

					</div>
				</div>


				<div className={this.state.showSummaryOverlay ? "ui-overlay open" : "ui-overlay"}>
					<div className={"ui-overlay-content anomalies-overlay col-md-6 offset-md-3"}>
						<button
							onClick={e => this.setState({ showSummaryOverlay: false })}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>

						<h5 className={"mb-5"}>Anomalies summary</h5>
						<div className={"anomalies-table"}>
							{Object.keys(this.state.summaryContent).map(item => (
								<div className={"anomalies-row"}>
									<div className={"anomalies-content"}>{item}</div>
									<div className={"anomalies-count"}>{this.state.summaryContent[item]}</div>
								</div>

							))}
						</div>



					</div>
				</div>





				<div className="col-md-12 ctable mb-3">


					<div className={"d-none d-sm-block"}>
						<div className={"row head-titles ctable-row"}>

							<div className={"col-md-1"}>
								CR
							</div>

							<div className={"col-md-2"}>
								Technicien
							</div>

							<div className={"col-md-2"}>
								Link
							</div>

							<div className={"col-md-2"}>
								<Trans i18nKey="label_datetime" />
							</div>

							<div className={"col-md-2"}>
								Progress
							</div>

							<div className={"col-md-3"}>
								<Trans i18nKey="label_details" />
								{data.selectMode && <button type="button" onClick={this.props.handlers.selectAll} className={"cbtn small ml-5"}>Selectionner tous</button>}
							</div>

						</div>
					</div>

					{data.tasks.map((task, i) =>

					(<React.Fragment key={i}>
						<div className={"row "}>
							<div className={"col-md-12"}>

								<TaskRow

									{...this.props}
									i={i}
									exportPM={this.exportPM}
									toggleHidden={this.toggleHidden}
									task={task}
									showSummary={this.showSummary}
									deleteTask={this.deleteTask}
									handlers={this.props.handlers}
									agents={this.state.agents}
									selected={!(data.selectedTasks.indexOf(task.task_id) === -1)}
									selectMode={data.selectMode}
								/>

								<div className={"animateRow row" + (parseInt(this.props.data.activeRow) !== parseInt(i) ? " hideRow" : "")}>

									<div className={"pm-wrapper col-md-12 row no-gutters"}>
										{this.props.data.manholeListLoading ?
											(<span>Loading list</span>)
											: (<>

												<div className={"col-md-3 pm-sidebar"}>
													<ul>

														{this.props.data.linkTaskData && this.props.data.linkTaskData.manholes && this.props.data.linkTaskData.manholes.map(manhole => (
															<li className={this.state.activeManhole === manhole.manhole_id ? "active" : ""}>
																<a onClick={e => this.selectManhole(e, manhole)} href="#">
																	<CheckOrTime task={manhole} />
																	{manhole.manhole_type.toUpperCase()} - {manhole.manhole_name}
																	<FontAwesomeIcon icon={faAngleRight} size={22} />
																</a>

															</li>
														))}

														{this.props.data.linkTaskData && this.props.data.linkTaskData.bridges && this.props.data.linkTaskData.bridges.map(bridge => (

															<li className={this.state.activeManhole === bridge.bridge_id ? "active" : ""}>
																<a onClick={e => this.selectManhole(e, bridge)} href="#">
																	<CheckOrTime task={bridge} />
																	Bridge - {bridge.bridge_from}
																	<FontAwesomeIcon icon={faAngleRight} size={22} />
																</a>

															</li>
														))}

 

													</ul>


												</div>

												<div className={"col-md-9 pm-content"}>
													{this.state.loadingManholeCheck || !this.state.manholeCheck ? <></> :
														!this.state.manholeCheck.done ? "" :

															this.state.manholeCheck.item_type === 'manhole' ?

																(

																	<div className={"pm-test-single show"}>
																		<h5>
																			Manhole {this.state.manholeCheck.manhole_type ? this.state.manholeCheck.manhole_type.toUpperCase() : ""} - {this.state.manholeCheck.manhole_name}
																			<span className={"float-right"}>{this.state.manholeCheck.created_at}</span>
																		</h5>

																		<button className={"btn btn-sm return-btn btn-danger float-right"} onClick={e => this.denyCheck('manhole', this.state.manholeCheck.manhole_id)}>Refuser et retourner</button>
																		<div className={"clearfix mb-6"}></div>
																		<div className={"clearfix mb-6"}></div>


																		{this.state.manholeCheck.photos && (

																			<div className={"manhole-photos"}>
																				{this.state.manholeCheck.photos.before_closed && (
																					<span>
																						<h5>{this.state.manholeCheck.manhole_type.toLowerCase() === "bmh" ? "Manhole photo" : "Before PM Closed"}</h5>
																						<img src={"http://app.netfer.tictechs.tech/" + this.state.manholeCheck.photos.before_closed.url} />
																						{this.state.manholeCheck.photos.before_closed.datetime ? (

																							<PhotoInfo data={this.state.manholeCheck.photos.before_closed} />

																						) : <></>}
																					</span>
																				)}

																				{this.state.manholeCheck.photos.before_open && (
																					<span>
																						<h5>Before PM Open</h5>
																						<img src={"http://app.netfer.tictechs.tech/" + this.state.manholeCheck.photos.before_open.url} />
																						<PhotoInfo data={this.state.manholeCheck.photos.before_open} />

																					</span>
																				)}

																				{this.state.manholeCheck.photos.after_open && (
																					<span>
																						<h5>After PM Open</h5>
																						<img src={"http://app.netfer.tictechs.tech/" + this.state.manholeCheck.photos.after_open.url} />
																						<PhotoInfo data={this.state.manholeCheck.photos.after_open} />

																					</span>
																				)}

																				{this.state.manholeCheck.photos.after_closed && (
																					<span>
																						<h5>After PM Closed</h5>
																						<img src={"http://app.netfer.tictechs.tech/" + this.state.manholeCheck.photos.after_closed.url} />
																						<PhotoInfo data={this.state.manholeCheck.photos.after_closed} />

																					</span>
																				)}

																			</div>
																		)}

																		<TaskChecks
																			task={this.state.manholeCheck}
																			taskType={"manhole"}
																			updateStatus={this.updateCheckStatus}
																		/>



																	</div>
																) 
																:
																 
																 (
																	
																<>

																	<div className={"pm-test-single show"}>
																		<h5>
																			Bridge Between  {this.state.manholeCheck.bridge_from} and {this.state.manholeCheck.bridge_to} {this.state.manholeCheck.bridge_place ? `(${this.state.manholeCheck.bridge_place})` : <></>}
																			<span className={"float-right mr-2"}>{this.state.manholeCheck.created_at}</span>
																		</h5>

																		<button className={"btn return-btn btn-danger"} onClick={e => this.denyCheck('bridge', this.state.manholeCheck.bridge_id)}>Refuser et retourner</button>


																		{this.state.manholeCheck.photos && (

																			<div className={"manhole-photos"}>
																				{this.state.manholeCheck.photos.before_closed && (
																					<span>
																						<h5>Bridge General view</h5>
																						<img src={"http://app.netfer.tictechs.tech/" + this.state.manholeCheck.photos.before_closed.url} />
																						<PhotoInfo data={this.state.manholeCheck.photos.before_closed} />

																					</span>
																				)}

																				{this.state.manholeCheck.photos.before_open && (
																					<span>
																						<h5>HDPE concrete protection at ends and bridges</h5>
																						<img src={"http://app.netfer.tictechs.tech/" + this.state.manholeCheck.photos.before_open.url} />
																						<PhotoInfo data={this.state.manholeCheck.photos.before_open} />
																					</span>
																				)}

																				{this.state.manholeCheck.photos.after_open && (
																					<span>
																						<h5>Fixation of Galanized Tubes</h5>
																						<img src={"http://app.netfer.tictechs.tech/" + this.state.manholeCheck.photos.after_open.url} />
																						<PhotoInfo data={this.state.manholeCheck.photos.after_open} />
																					</span>
																				)}

																				{this.state.manholeCheck.photos.after_closed && (
																					<span>
																						<h5>Fixation of Galanized Tubes</h5>
																						<img src={"http://app.netfer.tictechs.tech/" + this.state.manholeCheck.photos.after_closed.url} />
																						<PhotoInfo data={this.state.manholeCheck.photos.after_closed} />
																					</span>
																				)}

																			</div>
																		)}


																		<TaskChecks
																			task={this.state.manholeCheck}
																			taskType={"bridge"}
																			updateStatus={this.updateCheckStatus}
																		/>


																	</div>
																</>)
															 

																 
													}
												</div>
											</>
											)}



									</div>
								</div>
							</div>
						</div>
					</React.Fragment>

					))}
					{data.tasks.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucun PM trouvé</div>)}



				</div>


			</React.Fragment>
		)
	}
}


const CheckOrTime = ({ task, ...props }) =>
	task && task.done
		? <FontAwesomeIcon color={task.status === 'returned' ? "rgb(255,140,0)" : 'rgb(48, 209, 88)'} icon={task.status === 'returned' ? faUndo : faCheck} size={22} />
		: <FontAwesomeIcon color="#FF3B30" icon={faTimes} size={22} />



export default connect(mapStateToProps, mapDispatchToProps)(TaskList)


class TaskRow extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			editing: false,
			task: this.props.task
		}
		// console.log(this.state.task)


	}

	// componentDidUpdate() {
	// 	if(this.state.task.task_cr != this.props.task.task_cr)
	// 		this.setState({task: this.props.task})
	// }


	enableEditing = () => {

		this.setState({
			editing: true
		}, () => {
			if (this.crRef)
				this.crRef.focus()
		})
	}

	updateCr = (val) => this.setState({ task: { ...this.state.task, task_cr: val } })

	saveEditing = () => {

		this.props.isLoading();


		const data = {
			cr: this.state.task.task_cr,
			task_agent_id: this.state.task.task_agent_id,
			task_plan: this.state.task.task_plan
		}

		api.post('/api/task/editcr/' + this.state.task.task_id, data)
			.then(res => {
				console.log('edited cr')
			})
			.catch(err => this.props.doneLoading())
			.then(res => {
				this.props.doneLoading();
			})



		this.setState({
			editing: false
		})
	}


	componentDidMount() {
		document.addEventListener('keyup', this.handleEscape)
	}

	handleEscape = (e) => {
		if (e.keyCode === 27) {
			this.setState({ editing: false });
		} else if (e.keyCode === 13) {
			if (this.state.editing)
				this.saveEditing();
		}

	}


	componentDidUpdate() {
		console.log('didupdate')
		if (this.state.task.task_id !== this.props.task.task_id) {
			this.setState({
				task: this.props.task
			})
		}


	}

	componentWillReceiveProps(props) {
		console.log('wrprops')
		if (props.task !== this.props.task) {
			this.setState({
				task: this.props.task
			})
		}
	}

	updatePlan = (e) => {
		const val = e.target.value;
		this.setState({ task: { ...this.state.task, task_plan: val } })
	}

	updateAgent = (e) => {
		this.setState({ task: { ...this.state.task, task_agent: e.label, task_agent_id: e.value } })
	}

	archiveTask = (e) => {

		const data = {
			taskid: this.state.task.task_agent_id,
		}

		this.props.isLoading({
			message: "Archivage en cours",
			animated: true
		});

		api.post('/api/task/' + this.state.task.task_id + "/archive", data)
			.then(res => {
				this.props.handlers.loadTasks();
				console.log('Archived')
			})
			.catch(err => this.props.doneLoading())
			.then(res => {
				this.props.doneLoading();
			})

	}



	render() {
		const task = this.state.task;
		 
		const i = this.props.i
		console.log(this.state)
		return (
			<div className={"row ctable-row"}>


				<div className={"col-md-1"}>

					{this.state.editing ? (
						<>
							<input onChange={e => this.updateCr(e.target.value)} ref={ref => this.crRef = ref} type="text" defaultValue={task.task_cr} className={"form-control"} />
						</>
					) : task.task_cr}
				</div>


				<div className={"col-md-2"}>
					{this.state.editing ? (
						<Select
							classNamePrefix={"custom-select"}
							options={this.props.agents}
							value={{ label: task.task_agent, value: task.task_agent_id }}
							className={"col-md-12"}
							onChange={this.updateAgent}
						/>
					) : (
						<a href={"/manager/agents/" + task.task_agent_id}>{task.task_agent}</a>
					)}

				</div>

				<div className={"col-md-2 link-name-col"}>
					<span>

						<a onClick={e => this.props.handlers.updateFilter({ value: task.link_name }, 'link')} href={"javascript:void(0)"} style={{ color: "inherit" }}>
							{task.link_name}
						</a>
					</span>
					<span className={"link-count"}>
						{task.manhole_count > 0 && (<>{task.manhole_count} Manholes</>)} {task.bridge_count > 0 && (<>{task.bridge_count} bridges</>)}{task.siteaccess_count>0 && (<> { task.siteaccess_count} site_access</>)}
					</span>
					
					 
				</div>


				<div className={"col-md-2"}>
					{this.state.editing ? (
						<input type="date" className={"form-control"} value={task.task_plan} onChange={this.updatePlan} />
					) : (
						<span className={"task-plan-col"}>
							<span className={"line task-" + task.task_delay}>
								<span className={"line-label"}>Start</span>
								<span className={"line-value"}>{task.task_started}</span>
							</span>
							<span className={"line"}>
								<span className={"line-label"}>Plan</span>
								<span className={"line-value"}>{task.task_plan}</span>
							</span>
						</span>
					)}


				</div>

				<div className={"col-md-2 task-tests-col"}>
					<span>{task.manholes_done} out of {task.manhole_count}</span>

					<a class="task-count-anomalies" onClick={e => this.props.showSummary(task.summary)} href="#">{Boolean(task.summary) && Object.values(task.summary).reduce((acc, curr) => acc + curr, 0)} anomalies</a>



				</div>

				<div className={"col-md-3 task-details-col"}>

					{this.props.selectMode ? (
						<button className={"cbtn small success"} onClick={e => this.props.selected ? this.props.handlers.unselectTask(task.task_id) : this.props.handlers.selectTask(task.task_id)} type="button">{this.props.selected ? "Désectionner" : "Selectionner"}</button>
					) : (
						<>

							{task.geo && task.geo.lat && (
								<a href={"https://www.google.com/maps/place/" + task.geo.lat + "," + task.geo.lng + "/@" + task.geo.lat + "," + task.geo.lng + ",15z"} target='_blank' className="mr-2 btn btn-sm btn-info" >Localisation</a>
							)}

							{this.state.editing
								? (this.props.user.role !== 'tasks' && <button onClick={e => this.saveEditing()} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faCheckSquare} /> Sauvegarder</button>)
								: (this.props.user.role !== 'tasks' && <button onClick={e => this.enableEditing()} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faEdit} /> Modifier</button>)
							}


				  
							<button onClick={e => this.props.toggleHidden(e, task)} data-row-index={i} className="task-action-btn" >
								 <span><FontAwesomeIcon icon={faPlus}   /> </span>info
								</button>

							<button onClick={e => this.props.exportPM(task)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faFile} /> Export</button>



							{this.props.user.role !== 'tasks'  &&<MoreInfoDialog title={"Plus"}>
								{(this.props.user.role !== 'agent' ) && (<Item onClick={this.props.deleteTask} data-task-id={task.task_id} className="danger" ><FontAwesomeIcon className={"dialog-icon danger"} icon={faMinus} /> Supprimer</Item>)}

								<Item onClick={e => this.archiveTask(task)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faFileArchive} />{task.task_status === "archived" ? "Désarchiver" : "Archiver"}</Item>
							</MoreInfoDialog>}

						</>
					)}





				</div>


			</div>
		)
	}
}

const LocationLink = ({ location, ...props }) => {
	return (
		<a href={"https://www.google.com/maps/place/" +
			location.latitude + "," +
			location.longitude + "/@" +
			location.latitude + "," +
			location.longitude + ",15z"} target='_blank' className="btn btn-sm btn-link" >
			{location.latitude + ", " + location.longitude}
		</a>
	)
}

const PhotoInfo = ({ data, ...props }) => {
	return (

		<div className={"photo-comment"}>
			<p>{moment(typeof data.datetime === "number" ? data.datetime * 1000 : data.datetime).format('YYYY-MM-DD HH:mm')}
				{
					data.location.latitude &&
					(
						<>
							<br />
							<LocationLink location={data.location} />
						</>
					)
				}
			</p>
		</div>
	)
}


class TaskChecks extends React.Component {
	constructor(props) {
		super();
	}


	render() {
		const { task, taskType, updateStatus } = this.props;

		return (<>
			{task.checks && task.checks.map((section, section_index) => (
				<>

					{section.section_name && (<b>{section.section_name}</b>)}

					{section.checks.map((item, i) => (

						<SingleCheck
							checkType={taskType}
							updateTest={updateStatus}
							sectionIndex={section_index}
							checkIndex={i}
							test_content={item.test_content}
							test_status={item.test_status}
						/>

					))}

				</>
			))}
		</>
		)
	}

}