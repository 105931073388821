import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import api from '../../Utils/Api';
import { setFlash, closeFlash, setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import { useTranslation, Trans } from "react-i18next";



function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
}


 class AccountPersonal extends Component {

 	constructor(props) {
 		super(props);
 		this.formSubmit = this.formSubmit.bind(this);
 		this.handleInput = this.handleInput.bind(this);
 		

 		this.state = {
 				userid: 0,
 				username: "",
 				password: "",
 				email: "",
 				firstName: "",
 				lastName: "",
 				email: "",
 				phone: "",
 				manager_username: "",
 				manager_fullname: "",
 				role: ""
 			
 		}

 	}


 	componentDidMount() {

 		this.props.isLoading();
 		let that = this;

 		api.get('/api/user/'+this.props.user.userid+'?tab=personal&token='+this.props.user.token)
 		.then(function(response) {
 			that.setState(response.data);
 			that.props.doneLoading();
 		}).catch(err => that.props.doneLoading())
 	}


 	handleInput(e) {
 		this.setState({
 			[e.target.name]: e.target.value
 		})
 	}

 	formSubmit(e) {
 		e.preventDefault();
 		this.props.isLoading();
 		const data = {
 			user: this.props.user,
			agent: this.state
 		}

 		let that = this;

 		api.post('/api/user/edit/'+this.props.user.userid, data)
		.then(function(response) {
			that.props.setFlash({
				active: true,
				message: "Account information have been succesfully updated.",
				classes: "success",
				style: {}
			})
			that.props.doneLoading();

		})

 	}

	render() {
		return(

			

				<form onSubmit={this.formSubmit} className={"account-personal"}>
				<div className={"row"}>

					<div className={"col-md-6"}>

					<div className={"row mb-4"}>
						<div className={"col-md-3"}>
							<label><Trans i18nKey="user_username" /> </label>
						</div>
						<div className={"col-md-7"}>
							<input value={this.state.username} type="text" className={"form-control"} disabled />
						</div>
					</div>


					<div className={"row mb-4 "}>
						<div className={"col-md-3"}>
							<label><Trans i18nKey="user_email" /> </label>
						</div>
						<div className={"col-md-7"}>
							<input name="email" onChange={this.handleInput} value={this.state.email} type="email" className={"form-control"} />
						</div>
					</div>


					<div className={"row mb-4 "}>
						<div className={"col-md-3"}>
							<label><Trans i18nKey="user_firstname" /> </label>
						</div>
						<div className={"col-md-7"}>
							<input name="firstName" onChange={this.handleInput} value={this.state.firstName} type="text" className={"form-control"} />
						</div>
					</div>


					<div className={"row mb-4 "}>
						<div className={"col-md-3"}>
							<label><Trans i18nKey="user_lastname" /> </label>
						</div>
						<div className={"col-md-7"}>
							<input name="lastName" onChange={this.handleInput} value={this.state.lastName} type="text" className={"form-control"} />
						</div>
					</div>

					<div className={"row mb-4 "}>
						<div className={"col-md-3"}>
							<label><Trans i18nKey="user_phone" /> </label>
						</div>
						<div className={"col-md-7"}>
							<input name="phone" onChange={this.handleInput} value={this.state.phone || ""} type="text" className={"form-control"} />
						</div>
					</div>


					<div className={"row mb-4 "}>
						<div className={"col-md-3"}>
						</div>
						<div className={"col-md-7"}>
							<input type="submit" className={"form-control btn btn-primary"} value="Save" />
						</div>
					</div>

					</div>
					<div className={"col-md-6"}>
						{this.state.role && this.state.role !== 'manager' ? (
							<React.Fragment>
						<div className={"row mb-4 "}>
							<div className={"col-md-3"}>
								<label>Manager </label>
							</div>
							<div className={"col-md-7"}>
								<input value={this.state.manager_username || ""} type="text" className={"form-control"} disabled />
							</div>
						</div>

						<div className={"row mb-4 "}>
							<div className={"col-md-3"}>
								<label>Team </label>
							</div>
							<div className={"col-md-7"}>
								<input type="text" className={"form-control"} disabled />
							</div>
						</div>
						</React.Fragment>
						) : ("")}

					</div>
				</div>
				</form>


			
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPersonal);