export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function checkIfImageExists(url, callback) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };
    
    img.onerror = () => {
      callback(false);
    };
  }
}

export function OBJtoXML(obj) {
    var xml = '';
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
      if (obj[prop] instanceof Array) {
        for (var array in obj[prop]) {
          xml += "<" + prop + ">";
          xml += OBJtoXML(new Object(obj[prop][array]));
          xml += "</" + prop + ">";
        }
      } else if (typeof obj[prop] == "object") {
        xml += OBJtoXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml
  }
  

export const chartColors = [
    "rgba(231, 23, 34, .8)",
    "rgba(69, 92, 116, .8)",
    "#36A2EB",
    "#FFCE56",
    "rgba(153, 102, 255, 1)",
    '#515961',
    '#004F9E',
    'rgb(0, 255, 30)'
]

export const definitions = {
    ac: {
        type: {
            monobloc: "Monobloc",
            split: "Split"
        }
    },
    shelter: {
        1: "Indoor",
        2: "Local technique",
        3: "Outdoor",
        other: "Autre"
    },
    typology: {
        other: "Autre",
        rooftop: "Rooftop",
        greenfield: "Green Field"
    },
    bss: {
        other: "Autre",
        alcatel: "Alcatel",
        nsn: "NSN",
        huawei2g: "Huawei 2G",
        huawei3g: "Huawei 3G",
        huwaei3g: "Huawei 3G",
        zte2g: "ZTE 2G",
        zte3g: "ZTE 3G"

    },
    
    power: {
        types: {
            "0" : "SAFT MP54" ,
            "1" : "SAFT MP75" ,
            "2" : "SAFT MTP 69" ,
            "3" : "SAFT MTP 100" ,
            "4" : "Huawei" ,
            "5" : "Siemens 2008" ,
            "6" : "Siemens 4102" ,
            "7" : "ASCOM 1200" ,
            "8" : "ASCOM 2000" ,
            "9" : "AEG" ,
            "10": "Benning 12(15)A"  ,
            "11": "Benning 34A"  ,
            "12": "Benning 50A"  ,
            "13": "Emerson Electric "  ,
            "14": "Autres ( Indiquer)" 
        }
    }


}


export const buildUriParams = (filters) => {
  let result = "";
  for(let i in filters){
    console.log(i);
  }
  Object.keys(filters).forEach(key => {
      if(filters[key]){
        if(typeof(filters[key]) == "object"){
          Object.keys(filters[key]).forEach(cle => {
            result = `${result}&${key}=${filters[key][cle]}`
          })
        }else{
          result = `${result}&${key}=${filters[key]}`
        }
        
      }
          
  })

  return result;

}
//chartColors;

// export default {
//     validateEmail: validateEmail,
//     chartColors: chartColors
// }