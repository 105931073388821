import React from "react"

import { connect } from 'react-redux';

import api from '../../Utils/Api';
import { Pie,  Line,  } from 'react-chartjs-2';
import { chartColors, definitions } from "./../../Utils/Helpers"
import {  startLoading, doneLoading } from './../../Redux/actions/index';
import Select from "react-select";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


//const moment = require("moment");
const $ = require("jquery")


const mapStateToProps = state => {
	return {
		user: state.user
	}
} 

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}

class AdminDashboard extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
            pm: {
                done_ontime: 0,
                done_notontime: 0,
                total: 0,
                not_done: 0,
                chart: []
            },
            power: {
                types: {},
                groupes: {},
                equipement: {}
            },
            ac: {
                type: {},
                marque: {},
                modele: {},
                capacity: {}
            },
            ge: {
                marque: {},
                type: {},
                puissance: {}
            },
            batteries: {
                marques: {},
                stringsCount: {},
                volt: {},
                total: 0
            },
            anomalies: [],
            sabotage: [],
            typology: {},
            shelter: {},
            bss: {},
			sites: 0,
            agents: 0,
            managers: 0,
            filters: {
                agents: [],
                managers: [],
                cluster_managers: [],
                wilayas: [],
                manager: "",
                cluster_manager: "",
                wilaya: "",
                start_date: "",
                end_date: ""
            },
			tasks: {}
		}

		props.isLoading();
	}

	componentDidMount() {
		
        const that = this;
        
        api.get('/api/clustermanager/dashboard/form')
		.then(function(response) {

			if(response.data.success)
                that.setState({ filters: { ...response.data.data,  
                                            manager: "",
                                            cluster_manager: "",
                                            wilaya: "",
                                            start_date: "",
                                            end_date: ""
                                        } 
                                });
                
        })
        



		api.get('/api/clustermanager/dashboard/data')
		.then(function(response) {
            console.log(response.data)
			if(response.data.success)
				that.setState(response.data.data)
        }).then(() => that.props.doneLoading());
        



    }

    updateFilter = (name, e) => {

        const value = e.target ? e.target.value : e.value;
        
        const { filters } = this.state;

        filters[name] = value;

        this.setState({ filters: filters })




    }

    filterDashboard = () => {

        const that = this;

        const filters = "manager="+this.state.filters.manager+
                        "&cluster_manager="+this.state.filters.cluster_manager+
                        "&wilaya="+this.state.filters.wilaya+
                        "&start_date="+this.state.filters.start_date+
                        "&end_date="+this.state.filters.end_date
        
        this.props.isLoading({
            message: "Mise à jour du dashboard",
            animated: true
        });


        api.get('/api/clustermanager/dashboard/data?'+filters)
		.then(function(response) {
			if(response.data.success)
				that.setState(response.data.data)
        }).then(() => that.props.doneLoading());
    }
    

    exportAll = () => {

        var el = document.querySelectorAll(".exportable");
        
        var doc = new jsPDF('p', 'px',  "a4", false, true);
        var top = 10;

        
        
        this.props.isLoading({
            message: "Génération du fichier PDF en cours",
            animated: true
        });

        const that = this;

        setTimeout(function() {

            var promises = Array(...el).map(element => {

                return new Promise(function(resolve, reject) {
                    html2canvas(element).then(canvas => {
    
                        
        
                        var imgData = canvas.toDataURL(
                            'image/png');
                        
                        const imgProps= doc.getImageProperties(imgData);
                        const pdfWidth = doc.internal.pageSize.getWidth() - 10;
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
                        
                        resolve({
                            data: imgData,
                            width: pdfWidth,
                            height: pdfHeight
                        });
                        
                        
                        
                    });
    
    
                });
    
                
            })


            Promise.all(promises).then(data => {
    
                for (var ind in data) {
    
                    
                    if( top+data[ind].height > doc.internal.pageSize.getHeight()) {
                        doc.addPage();
                        top = 10;
                    } 

                    
                    doc.addImage(data[ind].data, 'PNG', 5, top, data[ind].width, data[ind].height, undefined, "MEDIUM");
                    top = top+data[ind].height+10;
                }
                doc.save('dashboard.pdf');
                that.props.doneLoading();
            })
            
        }, 50)

    
    }

    singleExport = (e) => {

        var el = $(e.target).parent().parent();//e.target;//document.querySelectorAll(".exportable");
        
        var doc = new jsPDF('p', 'px',  "a4", false, true);
        var top = 10;

        
        this.props.isLoading({
            message: "Génération du fichier PDF en cours",
            animated: true
        });

        const that = this;

        setTimeout(function() {

            var promises = Array(...el).map(element => {

                return new Promise(function(resolve, reject) {
                    html2canvas(element).then(canvas => {
    
                        var imgData = canvas.toDataURL(
                            'image/png');
                        
                        const imgProps= doc.getImageProperties(imgData);
                        const pdfWidth = doc.internal.pageSize.getWidth() - 10;
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
                        
                        resolve({
                            data: imgData,
                            width: pdfWidth,
                            height: pdfHeight
                        });
                        
                        
                    });
    
                });
    
                
            })


            Promise.all(promises).then(data => {
                for (var ind in data) {
                    if( top+data[ind].height > doc.internal.pageSize.getHeight()) {
                        doc.addPage();
                        top = 10;
                    } 

                    doc.addImage(data[ind].data, 'PNG', 5, top, data[ind].width, data[ind].height, undefined, "MEDIUM");
                    top = top+data[ind].height+10;
                }

                doc.save('dashboard.pdf');
                that.props.doneLoading();
            })
            
        }, 50)


    }

    

    render() {

		return (<div className={"dashboard-page"}>
		<div className={"container-header nmb"}>
			Dashboard

            <button onClick={e => this.exportAll()} className={"cbtn float-right"} >Exporter le dashboard</button>
		</div>
        

        <div className={"row"}>
				<div className={"filters-container"}>


                    <div className={"col-md-2"}>
						<div className={"form-group"}>
							<label>Début</label>
							<input onChange={e => this.updateFilter('start_date', e)} value={this.state.plan_start} type="date" className={"form-control"} />
						</div>
					</div>

					<div className={"col-md-2"}>
						<div className={"form-group"}>
							<label>Fin</label>
							<input onChange={e => this.updateFilter('end_date', e)} value={this.state.plan_end} type="date" className={"form-control"} />
						</div>
					</div>

                    <div className={"col-md-2"}>
						<div className={"form-group"}>
							<label>Wilaya</label>
							<Select
                            onChange={e => this.updateFilter('wilaya', e)}
							options={this.state.filters.wilayas}
							/>
						</div>
					</div>

                    <div className={"col-md-2"}>
						<div className={"form-group"}>
							<label>Cluster</label>
							<Select
                             onChange={e => this.updateFilter('cluster_manager', e)}
							options={this.state.filters.cluster_managers}
							/>
						</div>
					</div>
				
					<div className={"col-md-2"}>
						<div className={"form-group"}>
							<label>Manager</label>
							<Select
                                classNamePrefix={"custom-select"}
                             onChange={e => this.updateFilter('manager', e)}
							options={this.state.filters.managers}
							/>
						</div>
					</div>

                    <div className={"col-md-2 filter-btn"}>
						<button onClick={this.filterDashboard} className={"cbtn"}>Filtrer</button>
					</div>


                </div>
        </div>

        

        <div className={"row dashboard-row exportable"}>

			<div className={"col-md-4"}>

                <div className={"single-chart"}>
                    <h3>Sites : <span className={"single-value"}>{this.state.filtered_sites}</span></h3>
                </div>

            </div>

            <div className={"col-md-4"}>

                <div className={"single-chart"}>
                    <h3>Managers : <span className={"single-value"}>{(this.state.filters.managers.length-1).toString()}</span></h3>
                </div>

            </div>

            <div className={"col-md-4"}>

                <div className={"single-chart"}>
                    <h3>Agents : <span className={"single-value"}>{this.state.filters.agents.length.toString()}</span></h3>
                </div>

            </div>

            

            

        </div>
        

        


        <div className={"exportable"}>
            <h2>PM <button onClick={this.singleExport} className={"export-single"}>Export</button></h2>
            <div className={"row dashboard-row"}>

            
                <div className={"col-md-3 "}>

                    <div className={"single-chart"}>
                        <h3>PM Planifié : <span className={"single-value"}>{this.state.pm.total}</span></h3>
                    </div>

                </div>

                <div className={"col-md-3 "}>

                    <div className={"single-chart"}>
                    <h3>Réalisé : <span className={"single-value"}>{this.state.pm.done_notontime+this.state.pm.done_ontime+ " ("}
                        {!isNaN(Math.ceil( ( (this.state.pm.done_notontime+this.state.pm.done_ontime) / this.state.pm.total)*10000)/100) ? (Math.ceil( ( (this.state.pm.done_notontime+this.state.pm.done_ontime) / this.state.pm.total)*10000)/100) : 0 +
                        "%)"}</span></h3>
                    </div>

                </div>

                <div className={"col-md-3 "}>

                    <div className={"single-chart"}>
                        <h3>Dans les délais : <span className={"single-value"}>{this.state.pm.done_ontime}</span></h3>
                    </div>

                </div>

                <div className={"col-md-3 "}>

                    <div className={"single-chart"}>
                        <h3>Hors délais : <span className={"single-value"}>{this.state.pm.done_notontime}</span></h3>
                    </div>

                </div>
            </div>


        



        <div className={"row dashboard-row exportable"}>

			<div className={"col-md-12"}>
                <div className={"single-chart"} >

                    <Line 
                    
                        data={{
                            labels: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                            datasets: [
                              {
                                label: 'Planned',
                                borderColor: 'rgba(231, 23, 34, 1)',
                                borderWidth: 1,
                                hoverBackgroundColor: 'rgba(231, 23, 34, .8)',
                                hoverBorderColor: 'rgba(231, 23, 34, 1)',
                                data: Object.values(this.state.pm.chart).map(item => item.plan)
                              },
                              {
                                label: 'Réalisé',
                                borderColor: 'rgba(69, 92, 116, 1)',
                                borderWidth: 1,
                                hoverBackgroundColor: 'rgba(69, 92, 116, .8)',
                                hoverBorderColor: 'rgba(69, 92, 116, 1)',
                                data: Object.values(this.state.pm.chart).map(item => item.done)
                              }
                            ]
                          }}
                    />

                </div>
            </div>
        </div>

        </div>
        
        <div className={"exportable"}>

        
            <h2>Typology, Type & BSS <button  onClick={this.singleExport} className={"export-single"}>Export</button></h2>

            <div className={"row dashboard-row"}>

                

                <div className={"col-md-4"}>
                    
                    <div className={"single-chart"}>

                    
                    <h4>Typology de site</h4>
                    <Pie
                        data={{
                            labels: Object.keys(this.state.typology).map(item => definitions.typology[item]),
                            datasets: [{
                                data: Object.values(this.state.typology),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>

                                {Object.keys(this.state.typology).map(key => (
                                    <tr>
                                        <th>{definitions.typology[key]}</th>
                                        <th>{this.state.typology[key]}</th>
                                    </tr>
                                ))}

                                
                            </thead>
                        </table>
                    </div>

                    </div>

                </div>

                <div className={"col-md-4"}>
                    
                    <div className={"single-chart"}>

                    
                    <h4>Type de site global </h4>
                    <Pie
                        data={{
                            labels: Object.keys(this.state.shelter).map(item => definitions.shelter[item]),
                            datasets: [{
                                data: Object.values(this.state.shelter),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>

                                {Object.keys(this.state.shelter).map(key => (
                                    <tr>
                                        <th>{definitions.shelter[key]}</th>
                                        <th>{"("+Math.floor(this.state.shelter[key] / Object.values(this.state.shelter).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.shelter[key] }</th>
                                    </tr>
                                ))}
                            </thead>
                        </table>
                    </div>

                    </div>


                </div>

                <div className={"col-md-4 "}>
                    <div className={"single-chart"}>

                    
                    <h4>BSS</h4>
                    
                

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.bss).map(item => definitions.bss[item]),
                            datasets: [{
                                data: Object.values(this.state.bss),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.bss).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>

                                {Object.keys(this.state.bss).map(key => (
                                    <tr>
                                        <th>{definitions.bss[key]}</th>
                                        <th>{"("+Math.floor(this.state.bss[key] / Object.values(this.state.bss).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.bss[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>


                    </div>
                </div>

            </div>

        </div>
        
        <div className={"exportable"}>

        
        <h2>Power & GE <button  onClick={this.singleExport} className={"export-single"}>Export</button></h2>

            <div className={"row dashboard-row"}>

                <div className={"col-md-4"}>
                    <div className={"single-chart"} >
                    
                    
                    <h4>Power Supply</h4>
                    

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.power.types).map(item => definitions.power.types[item]),
                            datasets: [{
                                data: Object.values(this.state.power.types),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.power.types).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>

                                {Object.keys(this.state.power.types).map(key => (
                                    <tr>
                                        <th>{definitions.power.types[key]}</th>
                                        <th>{"("+Math.floor(this.state.power.types[key] / Object.values(this.state.power.types).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.power.types[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>


                    </div>
                </div>

                <div className={"col-md-4"}>
                    
                    <div className={"single-chart"}>

                    <h4>Equipement</h4>

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.power.equipement),
                            datasets: [{
                                data: Object.values(this.state.power.equipement),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.power.equipement).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>
                                
                                {Object.keys(this.state.power.equipement).map(key => (
                                    <tr>
                                        <th>{key}</th>
                                        <th>{ "("+Math.floor(this.state.power.equipement[key] / Object.values(this.state.power.equipement).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.power.equipement[key] }</th>
                                    </tr>
                                ))}

                                
                            </thead>
                        </table>
                    </div>

                    </div>

                </div>

                <div className={"col-md-4"}>
                    
                    <div className={"single-chart"}>

                    <h4>Groupes (marques) </h4>
                    <Pie
                        data={{
                            labels: Object.keys(this.state.power.groupes),
                            datasets: [{
                                data: Object.values(this.state.power.groupes),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.power.groupes).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>

                                {Object.keys(this.state.power.groupes).map(key => (
                                    <tr>
                                        <th>{key}</th>
                                        <th>{"("+Math.floor(this.state.power.groupes[key] / Object.values(this.state.power.groupes).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.power.groupes[key]  }</th>
                                    </tr>
                                ))}
                            </thead>
                        </table>
                    </div>

                    </div>


                </div>

            </div>
        </div>


        <div className={"exportable"}>

        
        <h2>AC <button  onClick={this.singleExport} className={"export-single"}>Export</button></h2>

            <div className={"row dashboard-row"}>

                <div className={"col-md-3"}>
                    <div className={"single-chart"} >
                    
                    
                    <h4>Type</h4>
                    

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.ac.type).map(item => definitions.ac.type[item]),
                            datasets: [{
                                data: Object.values(this.state.ac.type),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.ac.type).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>

                                {Object.keys(this.state.ac.type).map(key => (
                                    <tr>
                                        <th>{definitions.ac.type[key]}</th>
                                        <th>{"("+ Math.floor(this.state.ac.type[key] / Object.values(this.state.ac.type).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.ac.type[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>


                    </div>
                </div>

                <div className={"col-md-3"}>
                    
                    <div className={"single-chart"}>

                        <h4>Marque</h4>

                        <Pie
                        plugins={[]}
                            data={{
                                labels: Object.keys(this.state.ac.marque),
                                datasets: [{
                                    data: Object.values(this.state.ac.marque),
                                    backgroundColor: chartColors,
                                    hoverBackgroundColor: chartColors
                                }]
                            }}
                        />

                        <div className={"table-responsive"}>

                            <table className={"table"}>
                                <thead>
                                    <tr>
                                        <th>Total</th>
                                        <th>{Object.values(this.state.ac.marque).reduce((acc, curr) => acc+curr , 0)}</th>
                                    </tr>

                                    {Object.keys(this.state.ac.marque).map(key => (
                                        <tr>
                                            <th>{key.toUpperCase()}</th>
                                            <th>{"("+ Math.floor(this.state.ac.marque[key] / Object.values(this.state.ac.marque).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.ac.marque[key] }</th>
                                        </tr>
                                    ))} 
                                    
                                    
                                </thead>
                            </table>
                        </div>

                    
                    </div>

                </div>

                <div className={"col-md-3"}>
                    
                    <div className={"single-chart"}>

                        <h4>Modèle</h4>
                        <Pie
                        plugins={[]}
                            data={{
                                labels: Object.keys(this.state.ac.modele),
                                datasets: [{
                                    data: Object.values(this.state.ac.modele),
                                    backgroundColor: chartColors,
                                    hoverBackgroundColor: chartColors
                                }]
                            }}
                        />

                        <div className={"table-responsive"}>

                            <table className={"table"}>
                                <thead>
                                    <tr>
                                        <th>Total</th>
                                        <th>{Object.values(this.state.ac.modele).reduce((acc, curr) => acc+curr , 0)}</th>
                                    </tr>

                                    {Object.keys(this.state.ac.modele).map(key => (
                                        <tr>
                                            <th>{key.toUpperCase()}</th>
                                            <th>{"("+ Math.floor(this.state.ac.modele[key] / Object.values(this.state.ac.modele).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.ac.modele[key] }</th>
                                        </tr>
                                    ))} 
                                    
                                    
                                </thead>
                            </table>
                        </div>
                    

                    </div>


                </div>


                <div className={"col-md-3"}>
                    
                    <div className={"single-chart"}>

                        <h4>Capacité</h4>
                        <Pie
                        plugins={[]}
                            data={{
                                labels: Object.keys(this.state.ac.capacity),
                                datasets: [{
                                    data: Object.values(this.state.ac.capacity),
                                    backgroundColor: chartColors,
                                    hoverBackgroundColor: chartColors
                                }]
                            }}
                        />

                        <div className={"table-responsive"}>

                            <table className={"table"}>
                                <thead>
                                    <tr>
                                        <th>Total</th>
                                        <th>{Object.values(this.state.ac.capacity).reduce((acc, curr) => acc+curr , 0)}</th>
                                    </tr>

                                    {Object.keys(this.state.ac.capacity).map(key => (
                                        <tr>
                                            <th>{key.toUpperCase()}</th>
                                            <th>{"("+ Math.floor(this.state.ac.capacity[key] / Object.values(this.state.ac.capacity).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.ac.capacity[key] }</th>
                                        </tr>
                                    ))} 
                                    
                                    
                                </thead>
                            </table>
                        </div>
                    

                    </div>


                </div>

            </div>
        </div>

        <div className={"exportable"}>

        
        <h2>Groupes <button  onClick={this.singleExport} className={"export-single"}>Export</button></h2>

            <div className={"row dashboard-row"}>

                <div className={"col-md-4"}>
                    <div className={"single-chart"} >
                    
                    
                    <h4>Type</h4>
                    

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.ge.type).map(type => type.toUpperCase()),
                            datasets: [{
                                data: Object.values(this.state.ge.type),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.ge.type).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>

                                {Object.keys(this.state.ge.type).map(key => (
                                    <tr>
                                        <th>{key.toUpperCase()}</th>
                                        <th>{"("+ Math.floor(this.state.ge.type[key] / Object.values(this.state.ge.type).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.ge.type[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>
                </div>
                </div>

                <div className={"col-md-4"}>
                    <div className={"single-chart"} >
                    
                    
                    <h4>Marque</h4>
                    

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.ge.marque).map(marque => marque.toUpperCase()),
                            datasets: [{
                                data: Object.values(this.state.ge.marque),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.ge.marque).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>

                                {Object.keys(this.state.ge.marque).map(key => (
                                    <tr>
                                        <th>{key.toUpperCase()}</th>
                                        <th>{"("+ Math.floor(this.state.ge.marque[key] / Object.values(this.state.ge.marque).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.ge.marque[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>
                </div>
                </div>

                <div className={"col-md-4"}>
                    <div className={"single-chart"} >
                    
                    
                    <h4>Puissance (KVA) </h4>
                    

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.ge.puissance).map(puissance => puissance.toUpperCase()),
                            datasets: [{
                                data: Object.values(this.state.ge.puissance),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.ge.puissance).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>

                                {Object.keys(this.state.ge.puissance).map(key => (
                                    <tr>
                                        <th>{key.toUpperCase()}</th>
                                        <th>{"("+ Math.floor(this.state.ge.puissance[key] / Object.values(this.state.ge.puissance).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.ge.puissance[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>
                </div>
                </div>

            </div>


            </div>

            <div className={"exportable"}>

        
            <h2>Dernières anomalies déclarées </h2>


            {this.state.anomalies.length > 0 && (

            
            <div className={"table-responsive"}>

                <table className={"table"}>


                    {this.state.anomalies.length > 0 && (
                        
                    <thead>
                        <tr>
                            <th>Anomalie</th>
                            <th>Commentaire</th>
                            <th>Site concerné</th>
                            <th>PM Effectué par</th>
                            <th>Date & heure</th>
                        </tr>
                    </thead>

                    )}


                    <tbody>

                    {this.state.anomalies.map(item => (
                        <tr>
                            <th>{item.label}</th>
                            <th>{item.comment}</th>
                            <th>{item.site_name+" ("+item.site_code+") - " +item.site_wilaya.toUpperCase()}</th>
                            <th>{"Agent: "+item.site_last_pm.agent} <br /> {"Manager: "+item.site_last_pm.manager}</th>

                            <th>
                                <a href={"/task/"+item.site_last_pm.id}>
                                {item.site_last_pm.datetime}
                                </a>
                            </th>

                        </tr>

                    ))}

                    </tbody>
                </table>
            </div>

            )}
            
            </div>



            <div className={"exportable"}>

        
            <h2>Sabotage </h2>


            {this.state.sabotage.length > 0 && (

            
            <div className={"table-responsive"}>

                <table className={"table"}>

                    <thead>

                        <tr>
                            <th>Type</th>
                            <th>Observation</th>
                            <th>Site concerné</th>
                            <th>PM Effectué par</th>
                            <th>Date & heure</th>
                        </tr>

                    </thead>

                    <tbody>

                    {this.state.sabotage.map(sab => (
                        <>

                        {sab.items.map((item, prev) => (
                            <>
                            <tr>
                                <td>{item.type}</td>
                                <td>{item.observation}</td>
                                
                                <td>{sab.pm.site_name+" ("+sab.pm.site_code+") - " +sab.pm.site_wilaya.toUpperCase()}</td>
                                <td>{"Agent: "+sab.pm.site_last_pm.agent} <br /> {"Manager: "+sab.pm.site_last_pm.manager}</td>
                            
                                <td>{sab.pm.site_last_pm.datetime}</td>

                            </tr>
                            </>

                        ))}
            
                            

                        </>

                    ))}

                    </tbody>
                </table>
            </div>

            )}
            
            </div>

            <div className={"exportable"}>

        
            <h2>Batteries <button  onClick={this.singleExport} className={"export-single"}>Export</button></h2>

            <div className={"row dashboard-row"}>

                <div className={"col-md-4"}>
                    <div className={"single-chart"} >
                    
                    
                    <h4>Marque</h4>
                    

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.batteries.marques).map(item => item.toUpperCase()),
                            datasets: [{
                                data: Object.values(this.state.batteries.marques),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>{Object.values(this.state.batteries.marques).reduce((acc, curr) => acc+curr , 0)}</th>
                                </tr>

                                {Object.keys(this.state.batteries.marques).map(key => (
                                    <tr>
                                        <th>{key.toUpperCase()}</th>
                                        <th>{"("+ Math.floor(this.state.batteries.marques[key] / Object.values(this.state.batteries.marques).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.batteries.marques[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>


                    </div>
                </div>


                <div className={"col-md-4"}>
                    <div className={"single-chart"} >
                    
                    
                    <h4>Voltage</h4>
                    

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.batteries.volt).map(item => item.toUpperCase()),
                            datasets: [{
                                data: Object.values(this.state.batteries.volt),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>

                                {Object.keys(this.state.batteries.volt).map(key => (
                                    <tr>
                                        <th>{key.toUpperCase()}</th>
                                        <th>{"("+ Math.floor(this.state.batteries.volt[key] / Object.values(this.state.batteries.volt).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.batteries.volt[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>


                    </div>
                </div>


                <div className={"col-md-4"}>
                    <div className={"single-chart"} >
                    
                    
                    <h4>Strings</h4>
                    

                    <Pie
                    plugins={[]}
                        data={{
                            labels: Object.keys(this.state.batteries.stringsCount).map(item => item.toUpperCase()),
                            datasets: [{
                                data: Object.values(this.state.batteries.stringsCount),
                                backgroundColor: chartColors,
                                hoverBackgroundColor: chartColors
                            }]
                        }}
                    />

                    <div className={"table-responsive"}>

                        <table className={"table"}>
                            <thead>

                                {Object.keys(this.state.batteries.stringsCount).map(key => (
                                    <tr>
                                        <th>{key.toUpperCase()+" strings"}</th>
                                        <th>{"("+ Math.floor(this.state.batteries.stringsCount[key] / Object.values(this.state.batteries.stringsCount).reduce((acc, curr) => acc+curr , 0)*10000)/100 +"%) "+this.state.batteries.stringsCount[key] }</th>
                                    </tr>
                                ))} 
                                
                                
                            </thead>
                        </table>
                    </div>


                    </div>
                </div>


            </div>




            
            </div>
            



		</div>)
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)

