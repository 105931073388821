import React, { Component, useEffect } from "react";
//import { Link } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { useTranslation, Trans } from "react-i18next";
import PositionsTableRow from '../Task/PositionsTableRow';
//import api from '../Utils/Api';
//import SingleCheck from "./../Tests/SingleCheck";
//import PositionRow from './PositionRow';
//import IGeneral from "./../Tests/IGeneral";
//import Power from "./../Tests/Power";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,faAngleRight,faPlus,faHistory,faCheckSquare,faChevronCircleDown,faTimes,faCheck,faMinus,faFile,faExclamation,faUndo, faCircleNotch,faList,faEdit,faFileArchive} from "@fortawesome/free-solid-svg-icons";
import api from "../../Utils/Api";
import NewPatrolVehicule from '../../Components/Task/components/NewPatrolVehicule';
import PositionsTable from '../../Containers/PositionsTableContainer';
//import moment from "moment";
//import MoreInfoDialog, { Item } from "../../Utils/MoreInfoDialog";

//import html2canvas from "html2canvas";

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: e => dispatch(startLoading(e)),
  doneLoading: e => dispatch(doneLoading()),
  setAuth: e => dispatch(setAuthAction(e))
}); 
////saaaam
 const remoteAPI = "http://localhost/NETFER/back/public/";
//const remoteAPI = "https://app.netfer.tictechs.tech/";
// const remoteAPI = "https://app.netfertest.tictechs.tech";


class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPatrolView: false,
      agents: [],
      rows: [],
      showOverlay: false,
      taskid: "",
      agentid: "",
      selected_pm: "",
      activeTest: "igeneral",
      showDeleteOverlay: false,
      showDeleteOverlayConfirmation: false,
      loadingManholeCheck: false,
      summaryContent: {},
      showSummaryOverlay: false,
      manholeCheck: null,
      total: 0,
      selectOptions :[],
			tracker_id: "",
			carname: "",
			dataC: {},
			dataA: {},
			assets:[],
			assetsR:[],
			selected: false
      
    };

    

	   this.timerID = null;
     this.task_description="";//cerine
     this.task_remarque="";//cerine
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  //Set data for selectlist 
  componentWillReceiveProps(nextProps) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (nextProps) {  
		  //this.setState({ dataC: nextProps.data });
		  this.setState({ dataC: nextProps.data,
		      	     		dataA: nextProps.data,
						  assets: nextProps.data.assets,
						  assetsR: nextProps.data.assets,
              
				 });
		  //console.log("imene",nextProps.data)
		}
		console.log("imene C",this.state.dataC.assets);
	  }

    getOptions(){
      let that = this;
      api.get(`/api/carlist/fetch`).then(function(response) {
        if (response.data.success) {
          const options = response.data.cars.map(d => ({
            "value" : d.tracker,
            "label" : d.car_id+" : "+d.matricule,
            "label1" : d.matricule    
            })) 
          that.setState({
            selectOptions:options
            });
        }
      });
      }

      handleChange(e){
        //console.log("cars data", this.state.dataC);
        //console.log("cars selected", this.state.selected);
        //console.log(this.state.dataC.assets.indexOf());
        const newD = this.state.dataA;
        //console.log('devReact',newD[1].site);
        const dataFiltered = newD.assets.filter(obj => obj.site.includes(e.label1))
        // const devReact = newD.filter(obj => obj.site.includes(e.label));
         console.log("devReact filtered",dataFiltered);
         console.log("devReact A",this.state.dataA);
      
        this.setState(
          {
            dataC:{
              ...this.state.dataC,
              assets:dataFiltered
            }
          }
        )
        this.setState({tracker_id:e.value, 
                carname:e.label1,
                selected: true,
                    
        })
  
         }




  toggleOverlay(e) {
    this.setState({ showOverlay: !this.state.showOverlay });
  }

  selectTest = (e, test) => {
    e.preventDefault();
    this.setState({
      activeTest: test
    });
  };

  deleteTask = e => {
    const pmid = e.target.getAttribute("data-task-id");

    this.setState({
      selected_pm: pmid,
      showDeleteOverlay: true,
      deleteSteps: 1
    });
  };

  nextDeleteStep = () => {
    this.setState({
      showDeleteOverlay: false,
      showDeleteOverlayConfirmation: true,
      deleteSteps: 2
    });
  };

  confirmDelete = () => {
    this.props.isLoading();

    this.setState({
      showDeleteOverlayConfirmation: false
    });

    const data = {
      taskid: this.state.selected_pm
    };

    api.post("/api/task/delete", data).then(response => {
      this.props.handlers.loadTasks();
    });
  };

  exportPM = (pm, imageData) => {

    
    this.pmExportForm.action =

     "https://app.netfer.tictechs.tech/api/patrol/export/" + pm.taskid + "?token=" + this.props.user.token;
      //Saaaaam
       //"http://localhost/netfer/back/public/api/patrol/export/" + pm.taskid + "?token=" + this.props.user.token;

      this.imageData.value = imageData;
    // const newInput = document.createElement("input", {
    // 	name: "pm",
    // 	value: JSON.stringify(pm)
    // });
    // console.log(newInput);
    //this.pmExportForm.appendChild(newInput);
    //this.pmInput.value = JSON.stringify(pm)
    this.props.isLoading();
    this.pmExportForm.submit();

    this.props.doneLoading();

    const data = {
      pm: pm
    };

    // api.post('/api/pm/export/igeneral/'+pm.task_id, data)
    // .then(function(response) {
    // 	console.log(response.data)
    // })
  };

  selectManhole = (e, manhole) => {
    this.setState({
      activeManhole: manhole.manhole_id ? manhole.manhole_id : manhole.bridge_id,
      manholeCheck: manhole,
      loadingManholeCheck: false
    });

    return;

    // api.get('/api/manhole/check/fetch/'+check_id)
    // .then(response => {
    // 	this.setState({
    // 		manholeCheck: response.data.data,
    // 		loadingManholeCheck: false
    // 	});

    // 	console.log(response);
    // })
  };

  denyCheck = (type, id) => {
    this.setState({ showDenyOverlay: true, selectedType: type });
    console.log(id);
  };

  confirmDeny = () => {
    this.props.isLoading({
      message: "Returning Check to technician",
      animate: true
    });

    const data = {
      type: this.state.selectedType,
      id: this.state.selectedType === "bridge" ? this.state.manholeCheck.check_id : this.state.manholeCheck.check_id
    };

    this.setState({ showDenyOverlay: false });

    api.post("/api/check/return", data).then(response => {
      console.log(response.data);

      this.props.doneLoading();
    });
  };

  showSummary = summary => {
    this.setState({
      showSummaryOverlay: true,
      summaryContent: summary
    });
  };

  toggleHidden = (x, task) => {
    this.setState({
      manholeCheck: {}
    });
    console.log(task.task_result.photos[0])
    this.props.toggleHidden(x, task);
  };

  updateCheckStatus = (sectionIndex, checkIndex, newStatus, checkType = "manhole") => {
    const { manholeCheck } = this.state;

    manholeCheck.checks[sectionIndex].checks[checkIndex].test_status = newStatus;

    this.setState({
      manholeCheck: manholeCheck
    });

    const data = {
      check_id: manholeCheck.check_id,
      checks: manholeCheck.checks
    };

    api.post("/api/" + checkType + "/check/edit", data).then(res => {
      console.log("Check status updated");
    });
  };

  componentDidMount() {
    console.log("cars data", this.props.data);
		//this.timerID = setInterval(this.state.getOptions(), 6000);
    /* this.timerID = setInterval(this.props.handlers.loadTasks, 10000);
		this.getOptions() ; */
    //this.timerID = setInterval( () => this.getOptions(),300000);


   /*  api.get("/api/agent/fetch?limit=-1&status=enabled").then(response => {
      this.setState({
        agents: response.data.users.map(user => ({
          label: user.username,
          value: user.agentid
        }))
      });
    }); */
    //console.log("imene C",this.state.dataC);
    console.log("imene ",this.props.data);

  }
  togglePatrolTask = (e) => {

		this.setState({ openPatrolView: !(typeof e === "boolean") || !this.state.openPatrolView  })
	}	
   componentWillUnmount() {
    clearInterval(this.timerID);
  } 
  //

  render() {
    //const { t, i18n } = useTranslation();
    
    const data = this.props.data;
    const rows = this.props.rows;
    const agents = this.props.agents;
    const toggleHidden = this.toggleHidden;
    this.getOptions = this.getOptions.bind(this);
    return (
      <React.Fragment>
        <div className={"container-header"}>
                        Patrolling cars positions
        </div>
       
        <div className={"row"}>
       
            <br></br>
        <div className={"col-md-4"}>
                <div className={"row"}>
                
                    <div className={"ui-rect ns"}  style={{width: "98%" , overflow:"overlay"}}>
                        <div style={{height: "300px"}}>
                        Pane1  right 
                        <PositionsTable />
                        </div>
                    </div>
                </div>
                <br></br>

                <div className={"row"}>
                    <div className={"ui-rect ns"}  style={{width: "98%"}}>
                    <div style={{height: "300px"}}>
                        Pane2  right 
                        </div>
                    </div>
                </div>
        </div>

        <div className={"col-md-8"}>
            <div className={"ui-rect ns"} style={{width: "98%", overflow:"overlay"}}>
                <div style={{height: "650px"}}>
                            pane 2 left 
                </div>
           
             </div>
        </div>
    </div>
        
     {/*  {this.state.dataC.assets && this.state.dataC.assets.map((asset, i) => 		
				<PositionsTableRow 
					key={asset.key}
					exportPM={this.exportPM}
					asset={asset}
					handlers={this.props.handlers}
					deleteTask={this.deleteTask}
					returnTask={this.returnTask}
					//reloadCars={this.getOptions}
					reload={this.props.handlers.loadTasks}
				/>
			
			)}  

          {data.assets.length === 0 && <div className={"col-md-12 text-center my-3"}>Aucun vehicule trouvé</div>}
        */}
        
     
        
      </React.Fragment>
    );
  }
}

export default connect(
     mapStateToProps,
     mapDispatchToProps
   )(page);

