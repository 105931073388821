import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';

export default class NewPatrolVehicule extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			links: [],
			manholes: [],
            devices: [],
            owners: [],
			managers: [],
		};
        this.timerID = null;
		this.taskForm = null;
	}


	loadDevices = () => {
		api.get('api/devices/fetch?limit=-1')
		.then(response => {
			if(response.data.success)
				this.setState({ devices: response.data.devices.map(item => ({label: item.tracker, value: item.tracker_id})) });
                this.setState({ owners: response.data.owners.map(item => ({label: item.owner, value: item.owner})) })
				this.setState({ managers: response.data.managers.map(item => ({label: item.manager, value: item.manager})) })
		})
	}

	/* loadManholes = (link) => {

		api.get('/api/manhole/fetch/'+link)
		.then(response => {
			if(response.data.success)
				this.setState({ manholes: response.data.manholes.map(item => ({label: item.manhole_type+" "+item.manhole_name, value: item.manhole_id}) )})
		})
		

	} */

	componentDidMount() {
		this.loadDevices();
        //this.timerID = setInterval( () => this.loadDevices(),3000);
	}

	submitForm = (e) => {
		e.preventDefault();

		const data = {};

		const formData = new FormData(e.target);
		
		for(let [key, val] of formData.entries()) {
			data[key] = val;
		}

		api.post('/api/patrol/car/new', data)
		.then(response => {
			if(response.data.success) {
				this.props.toggleView();
				//this.props.loadTasks();
                this.props.loadCars();
            }
			 console.log("Successfully added");
		})

		return false;
		

	}

	render() {
        const {open, toggleView} = this.props;
        
		return (
			<div className={"ui-overlay "+(open && "open")}>
				<div className={"ui-overlay-content col-md-4 offset-md-4"}>
					<button 
						onClick={toggleView} 
						className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					<h5 className={"mb-4"}>Ajouter un nouveau vehicule</h5>


					<form ref={ref => this.taskForm = ref} onSubmit={this.submitForm}>
						<div className={"form-group"}>
							<label> Matricule</label>
							{/* <Select
								name={"link_id"}	
								classNamePrefix={"custom-select"}
								options={this.state.links}
								isLoading={!this.state.links.length}
								onChange={e => this.loadManholes(e.value)}
							/> */}
                          <input name={"matricule"} type="text" class="form-control"  placeholder="Matricule"/>
						</div>

						

						<div className={"form-group"}>
							<label>Type</label>
							{/* <Select
								name={"from_manhole"}
								classNamePrefix={"custom-select"}
								options={this.state.manholes}
							/> */}
                              <input type="text" name={"type"} class="form-control"  placeholder="Type"/>
						</div>
						
						<div className={"form-group"}>
							<label>Driver</label>
                              <input type="text" name={"driver"} class="form-control"  placeholder="Type"/>
						</div>


						<div className={"form-group"}>
							<label>Tracker</label>
							<Select
								name={"Tracker"}
								classNamePrefix={"custom-select"}
								options={this.state.devices}
							/>
						</div>

						<div className={"form-group"}>
							<label>Owner</label>
                            <Select
								name={"owner"}
								classNamePrefix={"custom-select"}
								options={this.state.owners}
							/>
						</div>

						<div className={"form-group"}>
							<label>Technicien</label>
							<Select
								name={"agent"}
								classNamePrefix={"custom-select"}
								options={this.props.agents}
							/>
						</div>

						<div className={"form-group"}>
							<label>User</label>
							<Select
								name={"manager"}
								classNamePrefix={"custom-select"}
								options={this.state.managers}
							/>
						</div>
						<button className={"cbtn success"}>Ajouter</button>
					</form>

					
				
				</div>
			</div>
		)
	}
}