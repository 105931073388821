import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setAuthAction, doneLoading, startLoading, setOverlay } from '../../Redux/actions/index';

import api from '../../Utils/Api';
import Select from 'react-select';



const mapStateToProps = state => {
	return {
		user: state.user,
		loading: state.loading
	}
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    setOverlay: (x) => dispatch(setOverlay(x)),
    isLoading: (e) => dispatch(startLoading(e))
  };
}



class ManholeNew extends Component {

	constructor(props) {
		super(props);

		//	this.props.isLoading({message: "Loading", animate:true});

		
		this.state = {
			agents: [],
			types: [],
			
		}

		
	}

	pinToggle() {
		
	}

	submitTask = (e) => {
		e.preventDefault();
		let that = this;

		this.props.isLoading({
			message:"Adding link",
			animate:true
		});
		

		const formdata = new FormData(this.taskForm);

		
		let data = {};

		for(var [key, value] of formdata.entries()) 
			data[key] = value
		
		console.log(data);

		data['user'] = this.props.user;


		api.post('/api/manhole/new', (data))
		.then(function(response) {

			if(response.data.success) {

				//window.ga('send', 'event', 'task', 'add'); // Google Analytics

				that.props.history.push('/link');

				that.props.setOverlay({
					message: 'Manhole successfully added !',
					label: "success",
					button: {
						type: "close",
						label: "Dismiss",
						link: "javascript:void(0)"
					}
				});
			} else {
				if(response.data.errcode === 101) {
					that.props.setAuth(response.data)
				}
			}

			that.props.doneLoading();


			
		}).catch(err => that.props.doneLoading());
		
	}


	
	componentDidMount() {
		
		

		let that = this;

		api.get('/api/task/form?token='+this.props.user.token)
		.then(function(response) {
			that.setState({agents: response.data.agents, types: response.data.types, status: response.data.status})
		});

	}


	render() {
		

		return(

			
			<React.Fragment>
			<div className={"container-header"}>
				New Manhole
			</div>

			
			<form ref={ref => this.taskForm = ref} onSubmit={this.submitTask}>
			
				<div className={"row"}>
					<div className={"col-md-4 taskForm"}>

						<div className={"form-group"}>
							<label>Manhole Code  </label>
							<input type="text" name="link_name" className={"form-control"} />
						</div>

						<div className={"form-group"}>
							<label>Type  </label>
							<Select
								options={[
									{label: "L3T", value:"l3t"},
									{label: "K2C", value:"k2c"},
									{label: "BMH", value:"bmh"}
								]}
							/>
						</div>

						<div className={"form-group"}>
							<button type="submit" className={"btn btn-block btn-success float-right"}>Ajouter</button>
						</div>

					</div>


				</div>

			</form>
			</React.Fragment>
			
		)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(ManholeNew)