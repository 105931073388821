import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import AddressListContainer from './../Containers/AddressListContainer';
import AddressNewContainer from './../Containers/AddressNewContainer';
import PlanningList from '../Components/Planning/PlanningList';



function mapStateToProps(state) {
  return { auth: state.auth };
};


class SiteRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
						<Route exact path='/sites' component={AddressListContainer} />
						<Route exact path='/sites/new' component={AddressNewContainer} />
						<Route exact path='/planning' component={PlanningList} />
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(SiteRouter)