import React, {Component} from 'react';
import {connect} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import {setFlash, closeFlash} from './../Redux/actions/index';

const mapDispatchToProps = dispatch => {
	return {
		setFlash: e => dispatch(setFlash(e)),
		closeFlash: e => dispatch(closeFlash(e))
	}
}

const mapStateToProps = state => {
	return {
		flashMessage: state.flashMessage
	}
}
class FlashMessage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			flashMessage: this.props.flashMessage
		}

		
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.flashMessage !== nextProps.flashMessage)
			this.setState({flashMessage: nextProps.flashMessage});
	}

	

	render() {

	
		return (
			<div className={"flash-alert"+(this.state.flashMessage.active ? ' active' : "")}>
				<div className={"alert alert-"+this.state.flashMessage.classes} style={this.state.flashMessage.style}> 
					<span>{this.state.flashMessage.message}</span>

					<span onClick={this.props.closeFlash} className={"flash-close-btn"}> 
						<FontAwesomeIcon icon={faTimes} />
					</span>

				</div>
			</div>)
		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);