import React, { Component } from 'react';
import _ from 'lodash';
import { BrowserRouter } from 'react-router-dom';
import Header from './lib/Components/Header';
import Body from './lib/Components/Body';
import Footer from './lib/Components/Footer';
import SideMenu from './lib/Components/Menus/SideMenu';
import AppRouter from './lib/Components/Router';
import openSocket from 'socket.io-client';
import {connect} from 'react-redux';
import SocketContext from './lib/Utils/socket-context';

import getNotifications from './lib/Utils/getNotifications.js';

import {updateData, updateNotifications, setAuthAction, doneLoading, startLoading, setOverlay} from './lib/Redux/actions/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import './style.scss';
import "./lib/i18n/index";
import audioLink from './lib/plucky.mp3';



var io;

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user,
		notifications: state.notifications,
		notificationsHash: state.notificationsHash,
		loading: state.loading,
		overlay: state.overlay,
		loadingMessage: state.loadingMessage
	}
}

function mapDispatchToProps(dispatch) {
  return {
    doneLoading: user => dispatch(doneLoading()),
    setOverlay: e => dispatch(setOverlay(e)),
    updateNotifications: e => dispatch(updateNotifications(e)),
	setAuth: e => dispatch(setAuthAction(e)),
	updateData: e => dispatch(updateData(e))
  };
}


class App extends Component {

	constructor(props) {
		super(props);
		


		this.state = {
			notificationsHash: this.props.notificationsHash,
			loadingAnimate:"...",
			loading: this.props.loading,
			overlay: this.props.overlay,
			loadingMessage: {
				message: this.props.loadingMessage.message,
				animate: this.props.loadingMessage.animate
			},
			inlineNotificationActive: false,
			inlineNotification: ""
		}

		this.hideOverlay = this.hideOverlay.bind(this);
		
		this.animationInterval = "";
		this.animateLoading = this.animateLoading.bind(this);;

		this.audio = new Audio(audioLink);
		this.notificationsPoll = _.debounce(this.notificationsPoll.bind(this), 200);
		this.displayNotification = this.displayNotification.bind(this);
		this.getNotifications = getNotifications.bind(this);


		

	}




	animateLoading() {
		const that = this;
		this.animationInterval = setInterval(function() {
			if(that.state.loadingAnimate.length !== 3) {
				that.setState({loadingAnimate: that.state.loadingAnimate+'.'})
			} else {
				that.setState({loadingAnimate: ''})
			}
		}, 600);
	}


	hideOverlay() {
		this.props.setOverlay(0);
	}

	componentWillReceiveProps(nextProp) {
		
		if(nextProp && (!(this.props.loading === nextProp.loading) || !(this.props.overlay.enabled === nextProp.overlay.enabled) )) {
			

			if(nextProp.loading === true && nextProp.loadingMessage.animate)
				this.animateLoading();
			else {
				clearInterval(this.animationInterval);
			}




			this.setState({
				loading: nextProp.loading, 
				loadingMessage: nextProp.loadingMessage,
				overlay: nextProp.overlay
			});

		} else {
			clearInterval(this.animationInterval);
		}


		if(nextProp && this.props.notificationsHash !== nextProp.notificationsHash) {
			
			if(this.state.notificationsHash.length === 0)
				this.notificationsPoll();
				//const that = this;
			this.setState({notificationsHash: nextProp.notificationsHash})

				
		}


		if(nextProp && this.props.notifications !== nextProp.notifications) {
				
				const diff = nextProp.notifications.filter(x => {
					
					return this.props.notifications.length > 0 
					&& this.props.notifications.filter(v => v.id === x.id).length === 0
				});

				if(diff[0]) 
					this.displayNotification(diff);

				
		}

			
			//this.notificationsPoll(nextProp.notificationsHash);

		if(nextProp && this.props.auth !== nextProp.auth && nextProp.auth) {

			if(nextProp.user.token)	
				this.getNotifications(nextProp.user.token);

		}
			

		
	}

	displayNotification(notification) {
		this.setState({'inlineNotification' : notification[0], 'inlineNotificationActive': true});

		const that = this;

		setTimeout(function() {
			that.setState({'inlineNotificationActive': false});
		}, 5000);
	}

	notificationsPoll() {


		const that = this;
		const fetchNotifications = _.debounce(this.getNotifications, 150);
		
		
		let remote = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://salim.tictechs.tech';

		if(this.props.auth) {
			
			//fetchNotifications();
			
			_.debounce(() => {
			

				var source = new EventSource(remote+'/api/notification/stream?hash='+this.state.notificationsHash+'&token='+that.props.user.token);

				const handleMessage = function(event) {


					const resp = JSON.parse(event.data);
					
					if(resp.success & resp.success === true) {

						that.getNotifications().then(() => that.audio.play());
						
					}

					if(resp.deb === 2) {
						var newSource = new EventSource(remote+'/api/notifiaction/stream?hash='+that.state.notificationsHash+'&token='+that.props.user.token);
						newSource.onmessage = handleMessage		
						event.target.close();	
					}
					

					

					


				}; 

				source.onmessage = handleMessage;

				
				
			}, 300)();

			
		}
	}

	componentDidMount() {

		//this.notificationsPoll();

		// if(this.props.auth) {
		// 	let remote = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://salim.tictechs.tech';
		// 	io = openSocket(remote+':8800', {
		// 		secure: true,
		// 		query: {
		// 			token: this.props.user.token,
		// 			userid: this.props.user.userid
		// 		}
		// 	});//, {transports: ['websocket'], upgrade: false});

		// 	this.getNotifications();
		// }
		//_.debounce(getNotifications.bind(this), 150)();
	}
  render() {
    return (
    	<BrowserRouter>



    		<div className={'appRootEl'}>
    			<div className={this.state.inlineNotificationActive ? "notification-wrapper active" : "notification-wrapper" }>
    				<div className={"notification-body"}>
    					<span className={"notification-content"}> 
    					{this.state.inlineNotification.content ? this.state.inlineNotification.content  : ""}
    					</span>
    				</div>
    			</div>

	    		<div className={"loading-overlay"+(this.state.overlay.enabled? " open": "")}> 

	    			<div className={"text"}>
	    				{this.state.overlay.label ? <FontAwesomeIcon icon={faCheck} className={"overlay-success"} /> : "" }
	    				{this.state.overlay.message}
	    					

	    				{this.state.overlay.button ? 
	    				(<div className={"overlay-button"}>
	    					<a target={this.state.overlay.button.type === 'link' ? "_blank" : ""}  href={this.state.overlay.button.link} onClick={this.hideOverlay}>
	    					<button type="button" className={"btn btn-primary btn-success mt-3"}>
	    						{this.state.overlay.button.label}
	    					</button>
	    					</a>
	    				</div>)
	    				: ""}
	    			</div>
	    		</div>

	    		<div className={"loading-overlay"+(this.state.loading? " open": "")}> 
	    			<div className={"text"}>{this.state.loadingMessage.message+this.state.loadingAnimate}</div>
	    		</div>

	    		<div className={"row no-gutters "}>
		    		{/* <div className={"offset-md-0 col-md-2 sidebar"}>
		    			<div className={"sideMenu"}>	
								<SideMenu />
									<Route path='/xyz/:userid/' component={UserSideMenu} />
									<Route path='/abc' component={AccountSideMenu} />
						      	  	<Route path='/' component={SideMenu} />
						      	  	
						      	</Switch>
						    </div>
		    		</div> */}
		    		<div className={"col-md-12 no-gutters mainContainer"}>

				    	<Header />
				    	<Body>

						<SocketContext.Provider value={io}>
							<AppRouter />
						</SocketContext.Provider>

				      	  
					    </Body>
					    <Footer />
		    		</div>

	    		</div>

		    </div>
	    </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);


