import React, { Component, useEffect } from "react";
//import { Link } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { useTranslation, Trans } from "react-i18next";
//import api from '../Utils/Api';
//import SingleCheck from "./../Tests/SingleCheck";
import PositionRow from './PositionRow';
//import IGeneral from "./../Tests/IGeneral";
//import Power from "./../Tests/Power";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,faAngleRight,faPlus,faHistory,faCheckSquare,faChevronCircleDown,faTimes,faCheck,faMinus,faFile,faExclamation,faUndo, faCircleNotch,faList,faEdit,faFileArchive} from "@fortawesome/free-solid-svg-icons";
import api from "../../Utils/Api";
import NewPatrolVehicule from '../../Components/Task/components/NewPatrolVehicule';
//import moment from "moment";
//import MoreInfoDialog, { Item } from "../../Utils/MoreInfoDialog";

//import html2canvas from "html2canvas";

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: e => dispatch(startLoading(e)),
  doneLoading: e => dispatch(doneLoading()),
  setAuth: e => dispatch(setAuthAction(e))
}); 
////saaaam
// const remoteAPI = "http://localhost/NETFER/back/public/";
const remoteAPI = "https://app.netfer.tictechs.tech/";
// const remoteAPI = "https://app.netfertest.tictechs.tech";


class PositionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPatrolView: false,
      agents: [],
      rows: [],
      showOverlay: false,
      taskid: "",
      agentid: "",
      selected_pm: "",
      activeTest: "igeneral",
      showDeleteOverlay: false,
      showDeleteOverlayConfirmation: false,
      loadingManholeCheck: false,
      summaryContent: {},
      showSummaryOverlay: false,
      manholeCheck: null,
      total: 0,
      selectOptions :[],
			tracker_id: "",
			carname: "",
			dataC: {},
			dataA: {},
			assets:[],
			assetsR:[],
			selected: false
      
    };

    

	   this.timerID = null;
     this.task_description="";//cerine
     this.task_remarque="";//cerine
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  //Set data for selectlist 
  componentWillReceiveProps(nextProps) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (nextProps) {
		  //this.setState({ dataC: nextProps.data });
		  this.setState({ dataC: nextProps.data,
			  			dataA: nextProps.data,
						  assets: nextProps.data.assets,
						  assetsR: nextProps.data.assets,
              
				 });
		  //console.log("imene",nextProps.data)
		}
		console.log("imene C",this.state.dataC.assets);
	  }

    getOptions(){
      let that = this;
      api.get(`/api/carlist/fetch`).then(function(response) {
        if (response.data.success) {
          const options = response.data.cars.map(d => ({
            "value" : d.tracker,
            "label" : d.car_id+" : "+d.matricule,
            "label1" : d.matricule    
            })) 
          that.setState({
            selectOptions:options
            });
        }
      });
      }

      handleChange(e){
        //console.log("cars data", this.state.dataC);
        //console.log("cars selected", this.state.selected);
        //console.log(this.state.dataC.assets.indexOf());
        const newD = this.state.dataA;
        //console.log('devReact',newD[1].site);
        const dataFiltered = newD.assets.filter(obj => obj.site.includes(e.label1))
        // const devReact = newD.filter(obj => obj.site.includes(e.label));
         console.log("devReact filtered",dataFiltered);
         console.log("devReact A",this.state.dataA);
      
        this.setState(
          {
            dataC:{
              ...this.state.dataC,
              assets:dataFiltered
            }
          }
        )
        this.setState({tracker_id:e.value, 
                carname:e.label1,
                selected: true,
                    
        })
  
         }




  toggleOverlay(e) {
    this.setState({ showOverlay: !this.state.showOverlay });
  }

  selectTest = (e, test) => {
    e.preventDefault();
    this.setState({
      activeTest: test
    });
  };

  deleteTask = e => {
    const pmid = e.target.getAttribute("data-task-id");

    this.setState({
      selected_pm: pmid,
      showDeleteOverlay: true,
      deleteSteps: 1
    });
  };

  nextDeleteStep = () => {
    this.setState({
      showDeleteOverlay: false,
      showDeleteOverlayConfirmation: true,
      deleteSteps: 2
    });
  };

  confirmDelete = () => {
    this.props.isLoading();

    this.setState({
      showDeleteOverlayConfirmation: false
    });

    const data = {
      taskid: this.state.selected_pm
    };

    api.post("/api/task/delete", data).then(response => {
      this.props.handlers.loadTasks();
    });
  };

  exportPM = (pm, imageData) => {

    
    this.pmExportForm.action =

      "https://app.netfer.tictechs.tech/api/patrol/export/" + pm.taskid + "?token=" + this.props.user.token;
      //Saaaaam
      // "http://localhost/NETFER/back/public/api/patrol/export/" + pm.taskid + "?token=" + this.props.user.token;

      this.imageData.value = imageData;
    // const newInput = document.createElement("input", {
    // 	name: "pm",
    // 	value: JSON.stringify(pm)
    // });
    // console.log(newInput);
    //this.pmExportForm.appendChild(newInput);
    //this.pmInput.value = JSON.stringify(pm)
    this.props.isLoading();
    this.pmExportForm.submit();

    this.props.doneLoading();

    const data = {
      pm: pm
    };

    // api.post('/api/pm/export/igeneral/'+pm.task_id, data)
    // .then(function(response) {
    // 	console.log(response.data)
    // })
  };

  selectManhole = (e, manhole) => {
    this.setState({
      activeManhole: manhole.manhole_id ? manhole.manhole_id : manhole.bridge_id,
      manholeCheck: manhole,
      loadingManholeCheck: false
    });

    return;

    // api.get('/api/manhole/check/fetch/'+check_id)
    // .then(response => {
    // 	this.setState({
    // 		manholeCheck: response.data.data,
    // 		loadingManholeCheck: false
    // 	});

    // 	console.log(response);
    // })
  };

  denyCheck = (type, id) => {
    this.setState({ showDenyOverlay: true, selectedType: type });
    console.log(id);
  };

  confirmDeny = () => {
    this.props.isLoading({
      message: "Returning Check to technician",
      animate: true
    });

    const data = {
      type: this.state.selectedType,
      id: this.state.selectedType === "bridge" ? this.state.manholeCheck.check_id : this.state.manholeCheck.check_id
    };

    this.setState({ showDenyOverlay: false });

    api.post("/api/check/return", data).then(response => {
      console.log(response.data);

      this.props.doneLoading();
    });
  };

  showSummary = summary => {
    this.setState({
      showSummaryOverlay: true,
      summaryContent: summary
    });
  };

  toggleHidden = (x, task) => {
    this.setState({
      manholeCheck: {}
    });
    console.log(task.task_result.photos[0])
    this.props.toggleHidden(x, task);
  };

  updateCheckStatus = (sectionIndex, checkIndex, newStatus, checkType = "manhole") => {
    const { manholeCheck } = this.state;

    manholeCheck.checks[sectionIndex].checks[checkIndex].test_status = newStatus;

    this.setState({
      manholeCheck: manholeCheck
    });

    const data = {
      check_id: manholeCheck.check_id,
      checks: manholeCheck.checks
    };

    api.post("/api/" + checkType + "/check/edit", data).then(res => {
      console.log("Check status updated");
    });
  };

  componentDidMount() {
    console.log("cars data", this.props.data);
		//this.timerID = setInterval(this.state.getOptions(), 6000);
    this.timerID = setInterval(this.props.handlers.loadTasks, 10000);
		this.getOptions() ;
    //this.timerID = setInterval( () => this.getOptions(),300000);


   /*  api.get("/api/agent/fetch?limit=-1&status=enabled").then(response => {
      this.setState({
        agents: response.data.users.map(user => ({
          label: user.username,
          value: user.agentid
        }))
      });
    }); */
    //console.log("imene C",this.state.dataC);
    console.log("imene ",this.props.data);

  }
  togglePatrolTask = (e) => {

		this.setState({ openPatrolView: !(typeof e === "boolean") || !this.state.openPatrolView  })
	}	
   componentWillUnmount() {
    clearInterval(this.timerID);
  } 
  //

  render() {
    //const { t, i18n } = useTranslation();
    
    const data = this.props.data;
    const rows = this.props.rows;
    const agents = this.props.agents;
    const toggleHidden = this.toggleHidden;
    this.getOptions = this.getOptions.bind(this);
    return (
      <React.Fragment>

       <div className={"ui-rect np"}>
        <div>

        <div>
				<button onClick={this.togglePatrolTask} className={"cbtn float-right"}>Nouveau vehicule</button>
		  	</div>
			 <NewPatrolVehicule
				toggleView={e => this.togglePatrolTask(false)}
				agents={this.state.agents}
				open={this.state.openPatrolView}
				loadTasks={this.props.handlers.loadTasks}
        //loadCars={this.loadCars}
        loadCars={this.getOptions}

			/>
        </div>

				<div className={"row form "}>
					<div className={"form-group col-md-3"}>
						<label>Vehicule:</label>
						<Select classNamePrefix={"custom-select"} 	placeholder="Matricule" options={this.state.selectOptions} onChange={this.handleChange.bind(this)} />
					</div>
          <div className={"form-group col-md-3"}>
						<label>Owner</label>
						<Select
						classNamePrefix={"custom-select"}
						options={[
							{label: "Tous", value: ""},
							{label: "Ooredoo", value: "ooredoo"},
							{label: "Djezzy", value: "djezzy"}
						]}
						placeholder="Link Owner"
						//onChange={e => this.updateFilter(e, 'link_owner')}
						/>
					</div>
				</div>
				
				<div className={"row form"}>
			  </div> 
      </div>



        <form method="post" ref={ref => (this.pmExportForm = ref)}>
          <input type="hidden" name={"pm"} ref={ref => (this.pmInput = ref)} />
          <input type="hidden" name={"image"} ref={ref => (this.imageData = ref)} />
        </form>
   
        <div className="col-md-12 ctable mb-3">
          <div className={"d-none d-sm-block"}>
            <div className={"row head-titles ctable-row"}>
              <div className={"col-md-2"}>Vehicule</div>

              <div className={"col-md-2"}>Type</div>

              <div className={"col-md-2"}>time</div>

              <div className={"col-md-2"}>Position</div>

              <div className={"col-md-2"}>Owner</div>

              
              

    

              <div className={"col-md-2"}>
                {/* <Trans i18nKey="label_details" /> */}
                {data.selectMode && (
                  <button type="button" onClick={this.props.handlers.selectAll} className={"cbtn small ml-5"}>
                    Selectionner tous
                  </button>
                )}
              </div>
            </div>
          </div>

      {this.state.dataC.assets && this.state.dataC.assets.map((asset, i) => 		
				<PositionRow 
					key={asset.key}
					exportPM={this.exportPM}
					asset={asset}
					handlers={this.props.handlers}
					deleteTask={this.deleteTask}
					returnTask={this.returnTask}
					//reloadCars={this.getOptions}
					reload={this.props.handlers.loadTasks}
				/>
			
			)}  

          {data.assets.length === 0 && <div className={"col-md-12 text-center my-3"}>Aucun vehicule trouvé</div>}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
     mapStateToProps,
     mapDispatchToProps
   )(PositionsList);

// const CheckOrTime = ({ task, ...props }) =>
//   task && task.done ? (
//     <FontAwesomeIcon
//       color={task.status === "returned" ? "rgb(255,140,0)" : "rgb(48, 209, 88)"}
//       icon={task.status === "returned" ? faUndo : faCheck}
//       size={22}
//     />
//   ) : (
//     <FontAwesomeIcon color="#FF3B30" icon={faTimes} size={22} />
//   );

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(PositionsList);

// class TaskRow extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       editing: false,
//       fieldEdit: '',
// 			task: this.props.task,
//       editedValue: '',
//     };

//   }
  
//   enableEditing = (e) => {
//     console.log(e.target.name)
//     this.setState(
//       {
//         editing: true,
//         fieldEdit: e.target.name
//       },
//       () => {
//         if (this.dsRef) this.dsRef.focus();
//       }
//     );
//   };

//   //updateDs = (val) => this.setState({ task: { ...this.state.task, task_description: val.target.value } });//cerine
//   updateDs = (e) => this.setState(
//     { 
//       editedValue: e.target.value, 
//       fieldEdit: (e.target.name ? e.target.name : e.target.defaultValue),
//       task: { ...this.state.task, 
//         task_description: (e.target.name == "Description" ? e.target.value : this.state.task.task_description),
//         task_remarque: (e.target.name == "Remarque" ? e.target.value : this.state.task.task_remarque),
//         task_result: { ...this.state.task.task_result, 
//           work_type: (e.target.name == "Type of Work" ? e.target.value : this.state.task.task_result.work_type),
//           //risk: (e.target.name == "Risk Exists" ? e.target.value : this.state.task.task_result.risk),
//           //mitigation: (e.target.name == "Mitigation Possible" ? e.target.value : this.state.task.task_result.mitigation),
//         }
//        }
//     });

//   updateSelect = (value, name) => this.setState(
//     { 
//       editedValue: value.value,
//       //fieldEdit: (e.target.name ? e.target.name : e.target.defaultValue) 
//       fieldEdit: name,
//       task: { ...this.state.task, 
//         task_result: { ...this.state.task.task_result, 
//           risk: (name == "Risk Exists" ? value.value : this.state.task.task_result.risk),
//           mitigation: (name == "Mitigation Possible" ? value.value : this.state.task.task_result.mitigation),
//         }
//        }
//     });

//   saveEditing = (e) => {
//     this.props.isLoading();
    
//     /*const data = {
//       description: this.state.task.task_description,//cerine
//     };*/
//     const data = {
//       field: this.state.fieldEdit,//sam
//       editedValue: this.state.editedValue,//sam
//     };
//     //console.log(this.state.task.taskid, data)
//     console.log(this.state.task.agentid)
//     api
//       .post('/api/patrol/editds/'+this.state.task.taskid, data)//cerine
//       .then(res => {
//         console.log("edited ds"+data.field+data.editedValue);
//       })
//       .catch(() => this.props.doneLoading())
//       .then(() => {
//         this.props.doneLoading();
//       });

//     this.setState({
//       editing: false,
//       fieldEdit: '',
//       editedValue: ''
//     });
//   };

//   componentDidMount() {
//     document.addEventListener("keyup", this.handleEscape);
//   }

//   // handleEscape = e => {
//   //   if (e.keyCode === 200) {
//   //     this.setState({ editing: false });
//   //   } else if (e.keyCode === 200) {
//   //     if (this.state.editing) this.saveEditing();
//   //   }
//   // };
  
//   updatePlan = e => {
//     const val = e.target.value;
//     this.setState({ task: { ...this.state.task, task_plan: val } });
//   };

//   // updateDescription = e => {
//   //   this.setState({
//   //     task: { ...this.state.task, task_description: e.label, task_description: e.value }
//   //   });
//   // };

//   archiveTask = e => {
//     const data = {
//       taskid: this.state.task.task_agent_id
//     };

//     this.props.isLoading({
//       message: "Archivage en cours",
//       animated: true
//     });

//     api
//       .post("/api/task/" + this.state.task.taskid + "/archive", data)
//       .then(res => {
//         this.props.handlers.loadTasks();
//         console.log("Archived");
//       })
//       .catch(err => this.props.doneLoading())
//       .then(res => {
//         this.props.doneLoading();
//       });
//   };

//   exportMap = () => {
//     this.mapComp.exportMap().then(res => {

//       console.log(this.props);
//       this.props.exportPM(this.props.task, res);
//     });
//   }

//   render() {
//     const task = this.state.assets;
//     const i = this.props.i;
//     return (
//       <>
//         <div className={"row ctable-row"}>
//           <div className={"col-md-2"}>{task.task_agent}</div>

//           <div className={"col-md-2 link-name-col"}>
//             <span>{task.task_link}</span>

//             <span className={"link-count"}>
//               <b>
//                 {task.task_from} - {task.task_to}
//               </b>
//             </span>
//           </div>

//           <div className={"col-md-2"}>
//               <span className={"task-plan-col"}>
//                 <span className={"line task-" + task.task_delay}>
//                   <span className={"line-label"}>Done</span>
//                   <span className={"line-value"}>{task.task_date}</span>
//                 </span>
//                 <span className={"line"}>
//                   <span className={"line-label"}>Plan</span>
//                   <span className={"line-value"}>{task.task_plan}</span>
//                 </span>
//               </span>
//           </div>
        
//           <div className={"col-md-2 task-tests-col"}>
//             <span>{!!task.task_status && task.task_status.toUpperCase()}</span>
//           </div>
//           <div className={"col-md-2"}><b>VEHICULE</b></div>
         

//           <div className={"col-md-2 task-details-col"}>
//             {this.props.selectMode ? (
//               <button
//                 className={"cbtn small success"}
//                 onClick={e =>
//                   this.props.selected
//                     ? this.props.handlers.unselectTask(task.taskid)
//                     : this.props.handlers.selectTask(task.taskid)
//                 }
//                 type="button"
//               >
//                 {this.props.selected ? "Désectionner" : "Selectionner"}
//               </button>
//             ) : (
//               <>
//                 {task.geo && task.geo.lat && (
//                   <a
//                     href={
//                       "https://www.google.com/maps/place/" +
//                       task.geo.lat +
//                       "," +
//                       task.geo.lng +
//                       "/@" +
//                       task.geo.lat +
//                       "," +
//                       task.geo.lng +
//                       ",15z"
//                     }
//                     target="_blank"
//                     className="mr-2 btn btn-sm btn-info"
//                   >
//                     Localisation
//                   </a>
//                 )}

//                 <button onClick={e => this.props.toggleHidden(e, task)} data-row-index={i} className="task-action-btn">
//                   <FontAwesomeIcon icon={faPlus} /> Info
//                 </button>
//                 {/* <a
//                   href={"http://localhost/netfer-backend/public/api/patrol/export/" + task.taskid}
//                   onClick={e => this.props.exportPM(task)}
//                   data-row-index={i}
//                   className="task-action-btn"
//                 >
//                   <FontAwesomeIcon icon={faFile} /> Export
//                 </a> */}

//                 <MoreInfoDialog title={"Plus"}>
//                   {this.props.user.role !== "agent" && (
//                     <Item onClick={this.props.deleteTask} data-task-id={task.taskid} className="danger">
//                       <FontAwesomeIcon className={"dialog-icon danger"} icon={faMinus} /> Supprimer
//                     </Item>
//                   )}

//                   <Item onClick={e => this.archiveTask(task)}>
//                     <FontAwesomeIcon className={"dialog-icon danger"} icon={faFileArchive} />
//                     {task.task_status === "archived" ? "Désarchiver" : "Archiver"}
//                   </Item>
//                 </MoreInfoDialog>
//               </>
//             )}
//           </div>
//         </div>

//         <div className={"animateRow row" + (parseInt(this.props.data.activeRow) !== parseInt(i) ? " hideRow" : "")}>
//           <div className={"pm-wrapper col-md-12 row no-gutters"}>
//             {this.props.data.manholeListLoading ? (
//               <span>Loading details</span>
//             ) : (
//               <>
//                 <div className={"col-md-12 pm-content"}>
//                   <div>
//                     <button className={"btn mr-3 btn-primary float-right"} type="button" onClick={this.exportMap}>Export</button>
//                     <div className={"clearfix mb-3"}></div>
//                   </div>
//                   {/*  les informations onclick +info  */}
//                   <table className={"table"}>
//                     <tbody>
//                     <tr>
//                         <td>
//                           <b>Technicien No1</b>
//                         </td>
                       
//                         <td>
//                          {this.props.data.linkTaskData.task_agent} 
//                         </td>
                        
//                       </tr>
//                       <tr>
//                         <td>
//                           <b>Partrol start  time</b>
//                         </td>
//                         <td>
//                           {moment(this.props.data.linkTaskData.task_result.started_at).format("HH:mm")}
//                         </td>
//                       </tr>

//                       <tr>
//                         <td>
//                           <b>Partrol end time</b>
//                         </td>
//                         <td>{moment(this.props.data.linkTaskData.task_finished_at).format("HH:mm")}</td>
//                       </tr>

//                       <tr>
//                         <td>
//                           <div className="row">
//                             <div className="col-md-3"><b>Risk exists</b></div>
//                             <div className="col-md-2">
//                               {(this.state.editing && this.state.fieldEdit == "Risk Exists") 
// 									              ? (<button onClick={e => this.saveEditing()} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faCheckSquare}  /> Sauvegarder</button>)
// 									              : (<button name="Risk Exists" onClick={e => this.enableEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faEdit}  /> Modifier</button>)
// 									            }
//                             </div>
//                           </div>
                          
//                         </td>
//                         <td>
//                           {(this.state.editing && this.state.fieldEdit === "Risk Exists")
//                             ? <>
//                                 <Select
//                                   defaultValue={{ label: (this.state.task.task_result.risk ? "OUI" : "NON"), value: this.state.task.task_result.risk }}
//                                   options={[
//                                     { label: (task.task_result.risk ? "OUI" : "NON"), value: (task.task_result.risk ? task.task_result.risk : false) },
//                                     { label: (!task.task_result.risk ? "OUI" : "NON"), value: (!task.task_result.risk ? true : false) }
//                                   ]}
//                                   onChange={e => this.updateSelect(e, "Risk Exists")}
//                                   cacheOptions
//                                   defaultOptions 
//                                   classNamePrefix={"custom-select"}
//                                   className={"d-block mt-1"}
//                                   name="Risk Exists"
//                                 />
//                               </>
//                             : (task.task_result.risk ? "OUI" : "NON")}
//                         </td>
//                       </tr>

//                       <tr>
//                         <td>
//                           <div className="row">
//                             <div className="col-md-3"><b>Mitigation Possible</b></div>
//                             <div className="col-md-2">
//                               {(this.state.editing && this.state.fieldEdit == "Mitigation Possible") 
// 									              ? (<button onClick={e => this.saveEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faCheckSquare}  /> Sauvegarder</button>)
// 									              : (<button name="Mitigation Possible" onClick={e => this.enableEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faEdit}  /> Modifier</button>)
// 									            }
//                             </div>
//                           </div>
//                         </td>
//                         <td>
//                           {(this.state.editing && this.state.fieldEdit == "Mitigation Possible")
//                             ? <>
//                                 <Select
//                                   defaultValue={{ label: (task.task_result.mitigation ? "OUI" : "NON"), value: task.task_result.mitigation }}
//                                   options={[
//                                     { label: (task.task_result.mitigation ? "OUI" : "NON"), value: (task.task_result.mitigation ? task.task_result.mitigation : false) },
//                                     { label: (!task.task_result.mitigation ? "OUI" : "NON"), value: (!task.task_result.mitigation ? true : !task.task_result.mitigation) }
//                                   ]}
//                                   onChange={e => this.updateSelect(e, "Mitigation Possible")}
//                                   cacheOptions
//                                   defaultOptions 
//                                   classNamePrefix={"custom-select"}
//                                   className={"d-block mt-1"}
//                                   name="Mitigation Possible"
//                                 />
//                               </>
//                             : (task.task_result.mitigation ? "OUI" : "NON")}
//                         </td>
//                       </tr>

//                       <tr>
//                         <td>
//                           <div className="row">
//                             <div className="col-md-3"><b>Type of Work</b></div>
//                             <div className="col-md-2">
//                               {(this.state.editing && this.state.fieldEdit == "Type of Work")
// 									              ? (<button onClick={e => this.saveEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faCheckSquare}  /> Sauvegarder</button>)
// 									              : (<button name="Type of Work" onClick={e => this.enableEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faEdit}  /> Modifier</button>)
// 									            }
//                             </div>
//                           </div>
//                         </td>
//                         <td>
//                           {(this.state.editing && this.state.fieldEdit == "Type of Work")
//                             ? <>
//                                 <input type="text" name="Type of Work" onChange={e => this.updateDs(e)} ref={ref => this.dsRef = ref} type="text" defaultValue={task.task_result.work_type} className={"form-control"} />
//                               </>
//                             : task.task_result.work_type
//                           }
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                      {/* cerine */}
//                   {/*<b>Description</b> 
//                   <div className={"col-md-18 "}>
//                      {this.state.editing 
// 									? (<button onClick={e => this.saveEditing()} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faCheckSquare}  /> Sauvegarder</button>)
// 									: (<button onClick={e => this.enableEditing()} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faEdit}  /> Modifier</button>)
// 									} 
//                 </div>*/}
                
//                 <div className="row" style={{padding: "3px 15px"}}>
//                   <div className="col-md-1"><b>Description</b></div> 
//                   <div className={"col-md-11 "}>
//                      {(this.state.editing && this.state.fieldEdit == "Description") 
// 									? (<button onClick={e => this.saveEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faCheckSquare}  /> Sauvegarder</button>)
// 									: (<button name="Description" onClick={e => this.enableEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faEdit}  /> Modifier</button>)
// 									} 
//                   </div>
//                 </div>


                 
//                   <div style={{padding: "3px 15px"}}>  {(this.state.editing && this.state.fieldEdit == "Description") ? (
//                     <>
//                      	<input type="text"  name="Description" onChange={e => this.updateDs(e)} ref={ref => this.dsRef = ref} type="text" defaultValue={task.task_description} className={"form-control"} />
// 								    </>
// 							      ) : task.task_description}
           
//                     {/* {   this.props.data.linkTaskData.task_description} */}
//                   </div>
//                      {/* //cerine */}
                 
//                   <div className="row" style={{padding: "3px 15px"}}>
//                     <div className="col-md-1"><b>Remarque</b></div>
//                     <div className={"col-md-11 "}>
//                      {(this.state.editing && this.state.fieldEdit == "Remarque") 
// 									      ? (<button onClick={e => this.saveEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faCheckSquare}  /> Sauvegarder</button>)
// 									      : (<button name="Remarque" onClick={e => this.enableEditing(e)} data-row-index={i} className="task-action-btn" ><FontAwesomeIcon icon={faEdit}  /> Modifier</button>)
// 									    } 
//                     </div>
//                   </div>
                  
//                   <div style={{padding: "3px 15px"}}>
//                     {(this.state.editing && this.state.fieldEdit == "Remarque") ? (
//                     <>
//                      	<input type="text"  name="Remarque" onChange={e => this.updateDs(e)} ref={ref => this.dsRef = ref} type="text" defaultValue={task.task_remarque} className={"form-control"} />
// 								    </>
// 							      ) : task.task_remarque}
//                   </div>
//                   <br />
//                   <br />

//                   <div style={{padding: "3px 15px"}}><b>Photos</b></div>
//                   <br />
//                   <div style={{padding: "3px 15px"}}>{this.props.data.linkTaskData.task_photos &&
//                     this.props.data.linkTaskData.task_photos.map(photo => <img src={remoteAPI + photo.url} />)}
//                   </div>
                  
//                   <div style={{padding: "3px 15px"}}>
//                     <PatrolTaskMap ref={ref => this.mapComp = ref} taskid={task.taskid} points={this.props.data.linkTaskData.task_photos} />
//                   </div>
//                 </div>
//               </>
//             )}
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// const LocationLink = ({ location, ...props }) => {
//   return (
//     <a
//       href={
//         "https://www.google.com/maps/place/" +
//         location.latitude +
//         "," +
//         location.longitude +
//         "/@" +
//         location.latitude +
//         "," +
//         location.longitude +
//         ",15z"
//       }
//       target="_blank"
//       className="btn btn-sm btn-link"
//     >
//       {location.latitude + ", " + location.longitude}
//     </a>
//   );
// };

// const PhotoInfo = ({ data, ...props }) => {
//   return (
//     <div className={"photo-comment"}>
//       <p>
//         {moment(typeof data.datetime === "number" ? data.datetime * 1000 : data.datetime).format("YYYY-MM-DD HH:mm")}
//         {data.location.latitude && (
//           <>
//             <br />
//             <LocationLink location={data.location} />
//           </>
//         )}
//       </p>
//     </div>
//   );
// };

// /* class PatrolTaskMap extends React.Component {
//   constructor(props) {
//     super(props);

//     this.config = {
//       center: {
//         lat: 35,
//         lng: 3
//       },
//       zoom: 18,
//       useHTTPS: true,
//       apikey: "Y7QL_y5ejjSDg9cTTvy1ihG3M32xircNPuZkIUMZqCI"
//     };

//     this.selector = "patrol-map-" + this.props.taskid;
//   }

//   componentDidMount() {
//     const platform = new window.H.service.Platform(this.config);
//     const layer = platform.createDefaultLayers();
//     const container = document.querySelector("#"+this.selector);

//     this.map = new window.H.Map(container, layer.raster.satellite.map, {
//       center: this.config.center,
//       zoom: this.config.zoom
//     });

//     const events = new window.H.mapevents.MapEvents(this.map);
//     const behavior = new window.H.mapevents.Behavior(events);
//     this.ui = new window.H.ui.UI.createDefault(this.map, layer);



//     const createMarker = (location, text) => {

//       const outerElement = document.createElement('div'),
//       innerElement = document.createElement('div');
//       outerElement.style.userSelect = 'none';
//       outerElement.style.webkitUserSelect = 'none';
//       outerElement.style.msUserSelect = 'none';
//       outerElement.style.mozUserSelect = 'none';
//       outerElement.style.cursor = 'default';

//       innerElement.style.color = 'red';
//       innerElement.style.backgroundColor = 'blue';
//       innerElement.style.border = '1px solid white';
//       innerElement.style.font = 'normal 12px arial';
//       innerElement.style.lineHeight = '12px'

//       innerElement.style.width = '15px';
//       innerElement.style.height = '15px';

//       innerElement.style.marginTop = '-10px';
//       innerElement.style.marginLeft = '-10px';

      
//       const textEl = document.createElement("p");
//       textEl.style.textShadow = "1px 1px 1px #000";
//       textEl.style.color = "#fff";
//       textEl.style.fontWeight = "bold";
//       textEl.innerText = text;
//       outerElement.appendChild(innerElement);
//       outerElement.appendChild(textEl);
      

//       const domIcon = new window.H.map.DomIcon(outerElement);
    
//       // const marker = new window.H.map.DomMarker(location, {
//       //   icon: domIcon
//       // });


//       var svgMarkup = `<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><rect stroke="white" fill="blue" x="1" y="1" width="22"  height="22" /><text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">1</text></svg>`;

//       // Create an icon, an object holding the latitude and longitude, and a marker:
//       var icon = new window.H.map.Icon(svgMarkup);

//       const marker = new window.H.map.Marker(location);

//       this.map.addObject(marker);

//     }

//     if(this.props.points)
//       this.props.points.forEach(point => {

//         if(point.location && point.location.latitude) {
//           createMarker({
//             lat: point.location.latitude,
//             lng: point.location.longitude
//           }, point.comment);
    
          
//           this.map.setCenter({
//             lat: point.location.latitude,
//             lng: point.location.longitude
//           });
//         }

//       });


//   }

//   exportMap = () => new Promise((resolve, reject) => {


//     this.map.capture((canvas) => {
//       if (canvas) {
//         const img = document.querySelector("#img-"+this.selector);
//         // resultContainer.innerHTML = '';
//         img.appendChild(canvas);

//         html2canvas(img).then(c => {
//           var imgData = c.toDataURL("image/png");
//           img.innerHTML = "";
//           resolve(imgData);
//         });

//       } else {
//         // For example when map is in Panorama mode
//         // resultContainer.innerHTML = 'Capturing is not supported';
//       }
//     }, [this.ui], 0, 0, 1300, 700);


 
//   })

//   render() {
//     return (
//       <>
//         <div id={"img-"+this.selector} />
//         <div className={"m-3"} id={this.selector} style={{ height: 450 }} />
//       </>
//     );
//   }
// }
//  */