import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import MoreInfoDialog, { Item } from '../../Utils/MoreInfoDialog';


const SingleCheck = ({test_content, test_status, updateTest, sectionIndex, checkIndex, ...props}) => {


    return (
        <div className={"singleCheck"}>
            <div className={"singleCheck-status"}>
                {test_status === 'ok' && (<FontAwesomeIcon color={"rgb(48, 209, 88)"} icon={faCheck} />)}
                {test_status === 'nok' && (<FontAwesomeIcon color={"#e71722"} icon={faTimes} />)}
                {test_status === 'na' && (<p>NA</p>)}
            </div>

            <div className={"singleCheck-content"}>
                <p>{test_content}</p>
                
                <MoreInfoDialog title={"more"}> 
                    <Item onClick={e => updateTest(sectionIndex, checkIndex, "ok", props.checkType)} ><FontAwesomeIcon className={"dialog-icon danger"} icon={faCheck}  />OK</Item>
                    <Item onClick={e => updateTest(sectionIndex, checkIndex, "nok", props.checkType)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faTimes}  />NOK</Item>
                    <Item onClick={e => updateTest(sectionIndex, checkIndex, "na", props.checkType)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faExclamation}  />N/A</Item>
                </MoreInfoDialog>

                {props.test_comment && (
                    <p><i>{props.test_comment}</i></p>
                )}
            </div>

                
        </div>
    )

}

export default SingleCheck;