import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Select from 'react-select';

import * as actions from '../../Redux/actions/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import SocketContext from '../../Utils/socket-context';

import {  Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(actions.setAuthAction(user)),
		isLoading: e => dispatch(actions.startLoading(e)),
		doneLoading: () => dispatch(actions.doneLoading()),
		setFlash: (f) => dispatch(actions.setFlash(f)),
    	closeFlash: (f) => dispatch(actions.closeFlash(f))
	}
}

const moment = require("moment");


class ManagerList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			filters: {
				status: '',
				name: ''
			},
			tableIsLoading: true,
			limit: 10,
			pages: 1,
			current: 1,
			showOverlay: false,
			selectedUser: 0,
			lastUpdate: 0,
			showOverlayRenew: false,
			renewDuration: 1

		}

		this.props.isLoading();

		this.loadAgents = this.loadAgents.bind(this);
		this.loadAgents =_.debounce(this.loadAgents, 300)
		this.selectPage = this.selectPage.bind(this);
		this.userDeleteBtn = this.userDeleteBtn.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.toggleOverlayRenew = this.toggleOverlayRenew.bind(this);
		this.deleteUser = this.deleteUser.bind(this);

		this.agentInterval = null;
		this.updateFilter = this.updateFilter.bind(this);
		
		this.changeLimit = this.changeLimit.bind(this);

		this.submitRenewal = this.submitRenewal.bind(this);
	}

	changeDuration(e) {
		this.setState({
			renewDuration: e.value
		})
	}
	submitRenewal(e) {
		e.preventDefault();
		const that = this;

		const data = {
			userid: this.state.selectedUser,
			duration: this.state.renewDuration
		}
		
		if(!data.duration || parseInt(data.duration) === 0)
			return;

		api.post('/api/user/'+this.state.selectedUser+'/renew', data)
		.then(function(response) {
			if(response.data)
				console.log(response.data)
		})
		
	}
	changeLimit(e) {

		this.props.isLoading();

		
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadAgents())

		

	}

	selectPage(p) {
		this.props.isLoading();
		this.setState({current: p});
		this.loadAgents(p);
	}

	getTimestamp() {
		var now = new Date();
		return parseInt(now.getTime()/1000)
	}

	componentDidMount() {

		const that = this;

		this.loadAgents();

		this.agentInterval = setInterval(function() {
			
			if(that.getTimestamp() - that.state.lastUpdate > 15) {
				
				that.loadAgents();
			}
		}, 1500)

	}
	componentWillUnmount() {
		clearInterval(this.agentInterval)

		if(this.props.socket) {
			this.props.socket.off('test')
		}
	}

	toggleOverlay(e) {
		this.setState({showOverlay: !this.state.showOverlay});
	}
	toggleOverlayRenew(e, user) {

		this.setState({
			showOverlayRenew: !this.state.showOverlayRenew,
			selectedUser: user ? user : this.state.selectedUser
		});
	}


	loadAgents(p = this.state.current) {
		const that = this;
		

		this.setState({
			lastUpdate: this.getTimestamp()
		}, function() {

			const crits = "name="+that.state.filters.name
						  +"&status="+that.state.filters.status
						  +"&limit="+that.state.limit

			api.get('/api/manager/fetch?token='+that.props.user.token+'&page='+p+'&'+crits)
			.then(function(response) {
				if(response.data.success) {
					
					that.setState(response.data);
				} else {
					//that.props.setAuth(response.data);
				}

				that.props.doneLoading();
			});
		});

		
	}
 
	userDeleteBtn(id) {
		
		this.setState({
			selectedUser: id
		})
		

		this.toggleOverlay();

	}

	deleteUser(e) {

		let that = this;
		const data = { 
			user: this.props.user
		}

		that.toggleOverlay();

		that.props.isLoading();

		
		api.post('/api/user/delete/'+this.state.selectedUser, data)
		.then(function(response) {
			
			

			if(response.data.success) {
				//that.loadAgents();
				that.props.setFlash({
					active: true,
					message: "User has been succesfully deleted.",
					classes: "success",
					style: {}
				})
			} else {
				that.props.doneLoading();
				that.props.setFlash({
					active: true,
					message: "Failed to delete user : "+response.data.error,
					classes: "warning",
					style: {}
				})
			}
		});
		
	}

	updateFilter(e, name) {

		let that = this;

		const value = e.target ? e.target.value : e.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			that.props.isLoading();
			that.loadAgents(1);
		});
	}

	render() {
		const toggleOverlay = this.toggleOverlay;
		const toggleOverlayRenew = this.toggleOverlayRenew;
		const showOverlay = this.state.showOverlay;
		const showOverlayRenew = this.state.showOverlayRenew
		const data = this.state;
		return(
			<div>


			</div>
		)
	}
}



const AgentListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <ManagerList {...props} socket={socket} />}
	</SocketContext.Consumer>
  )
	
//export default YourDeeplyNestedComponentWithSocket

export default connect(mapStateToProps, mapDispatchToProps)(AgentListConsumer)